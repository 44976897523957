import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { setUpdatedAllCheck, setUpdatedFAQData } from "redux/actions/Table.action";
import Axios from "services/config";
import { io } from "socket.io-client";
import { BASE_WS_URL } from "utils/globals";
import QAChatSection from "./QAChatSection";
// const defaultMentionStyle = {
//     fontWeight: "600",
//     backgroundColor: "#cee4e5",
//     margin: "5px",
// };
const QASection = ({ TitleInfo, projectId }) => {
    // console.log("TitleInfo::", TitleInfo);
    const [FAQData, setFAQData] = React.useState([]);
    const [totalChat, setTotalChat] = React.useState(null);
    const [dataFetched, setDataFetched] = React.useState(false);
    const [fetching, setFetching] = useState(false);
    const [doneOnce, setDoneOnce] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const { lastFAQMessage } = useSelector(({ table }) => table);
    const mountedRef = useRef();

    // console.log("conversation--", conversation);

    const dispatch = useDispatch();
    useEffect(() => {
        if (dataFetched && !doneOnce) {
            setDataFetched(false)
            setDoneOnce(true);
            var objDiv = document.getElementById("scrollableDiv");
            objDiv.scrollTo({ top: objDiv?.scrollHeight || 0, behavior: 'smooth' });
        }
    }, [FAQData])
    const getThreadMessages = (pageNo) => {
        console.log("getThreadMessages fetching", fetching);
        if (fetching) {
            return;
        }

        setFetching(true);
        if (page <= totalPage) {
            try {
                Axios.get(
                    `thread_message/messages/${TitleInfo._id}?page=${pageNo}&limit=${limit}&message_type=faq`
                )
                    .then((data) => {
                        if (data.status === 200) {
                            setFAQData([
                                ...data?.data?.threadMessages?.reverse(),
                                ...FAQData,
                            ]);
                            setTotalChat(data?.data?.totalItems);
                            setTotalPage(data?.data?.totalPages);
                            setPage(pageNo);
                            setFetching(false);
                            setDataFetched(true)
                            // setTimeout(() => {
                            //     var objDiv = document.getElementById("scrollableDiv");
                            //     objDiv.scrollTop = objDiv?.scrollHeight || 0;
                            // }, 200);
                        }
                    })
                    .catch(() => null);
            } catch (error) { }
        }
    };
    const loader = (
        <div key="loader" className="loader">
            Loading ...
        </div>
    );
    useEffect(() => {
        if (lastFAQMessage?.data) {
            //on asnwer or question delete
            if (lastFAQMessage?.data?.threadMessageId) {
                let updatedData = [];
                if (lastFAQMessage?.data?.type === "question") {
                    updatedData = FAQData.filter((item) => {
                        return item.id ? item.id !== lastFAQMessage?.data.threadMessageId : item._id !== lastFAQMessage?.data.threadMessageId;
                      }).map((item) => {
                    // updatedData = FAQData.filter((item) => item?.id !== lastFAQMessage?.data.threadMessageId).map((item) => {
                        return item
                    })
                }
                else {
                    updatedData = FAQData.map((item) => {
                        if (item.id === lastFAQMessage?.data.threadMessageId)
                            return {
                                answer: '',
                                // createdAt: item?.createdAt,
                                createdBy: item?.createdBy,
                                createdAt: item?.createdAt,
                                id: item?.id,
                                message: item?.message,
                                message_type: item?.message_type,
                                tagged_users: item?.tagged_users,
                                thread: item?.thread,
                                updatedAt: item?.updatedAt,
                            }
                        else {
                            return item;
                        }
                    })
                }
                setFAQData(updatedData);
                //on asnwer or question update
            } else {
                //on Create message update question object
                if (lastFAQMessage.data.editMode) {
                    let updatedData = [];
                    updatedData = FAQData.map((item) => {
                        if (lastFAQMessage.data.id === item?.id) {
                            return lastFAQMessage.data
                        }
                        else return item;
                    })
                    setFAQData(updatedData);
                }
                //on question Create.
                else
                    setFAQData([...FAQData, lastFAQMessage?.data]);
            }
            setTimeout(() => {
                var objDiv = document.getElementById("scrollableDiv");
                objDiv.scrollTop = objDiv.scrollHeight;
            }, 200);
        }
    }, [lastFAQMessage]);
    useEffect(() => {

        getThreadMessages(1);
        mountedRef.current = true;
        const userToeken = localStorage.getItem('trello_user_token');
        const socket = io(BASE_WS_URL, {
            query: { "token": userToeken }
        });

        if (socket) {
            socket.emit("threadCreation", TitleInfo?._id);
            socket.on("threadCreationSuccess", (res) => {
                if (res) {
                    const newMsg = JSON.parse(res);
                    // console.log("threadCreationSuccess-FAQ-", newMsg);
                    if (newMsg.message_type === 'faq')
                        dispatch(setUpdatedFAQData({ data: newMsg }));
                    dispatch(setUpdatedAllCheck({ data: newMsg }));
                }
            });
            socket.on("onThreadMessageDeleted", (res) => {
                if (res) {
                    dispatch(setUpdatedFAQData({ data: res }));
                    // dispatch(UpdateThreadReadNotification(UpdatedData))
                }
            })
        }
        return function clean() {
            // dispatch(clearConversationData());
            setFAQData([]);
            setPage(1);
            socket.close()
        }
    }, []);
    return (
        <div
            id="scrollableDiv"
            style={{
                // display: 'flex',
                flexDirection: "column-reverse",
                height: "450px !important",
            }}
            className="comments-section"
        >
            <InfiniteScroll
                loadMore={() => getThreadMessages(page + 1)}
                hasMore={FAQData?.length < totalChat}
                loader={loader}
                isReverse={true}
                useWindow={false}
                threshold={10}
                scrollabletarget="scrollableDiv"
                style={{ flexDirection: "column-reverse", height: "450px !important" }}
            >
                {FAQData?.map((item, i) => {
                    return (
                        <div key={i}>
                            <QAChatSection TitleInfo={TitleInfo} projectId={projectId} chat={item} index={i} />
                        </div>
                    );
                })}
            </InfiniteScroll>
        </div>
    );
};

export default QASection;
