import React, { useEffect, useState } from 'react';
import CmtAvatar from '@coremat/CmtAvatar';
import { Attachment, ChatBubbleOutline, DashboardOutlined, Subject, Title } from '@material-ui/icons';
import Description from './description';
import CustomFields from './customField';
import TableServices from 'services/table';
import { useDispatch } from 'react-redux';
const CustomFeildPopup = ({ trelloCardId, allComments, setDescription, getAttachment, editData }) => {
    const [comments, setComments] = useState([]);
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [updatedTitle, setUpdatedTitle] = useState(editData);
    const dispatch = useDispatch();
    const handleDescriptionUpdate = (newDescription) => {
        const updateCardDescription = {
            cardId: trelloCardId,
            Desc: newDescription,
        };
        dispatch(TableServices.updateDescription(updateCardDescription));
    };

    const handleTitleClick = () => {
        setIsEditingTitle(true);
    };

    const handleTitleChange = (e) => {
        setUpdatedTitle(e.target.value);
        const updateCardTitle = {
            cardId: trelloCardId,
            title: e.target.value,
        };

        dispatch(TableServices.updateTitle(updateCardTitle));
    };
    useEffect(() => {
        if (Array.isArray(allComments.data)) {
            const commentData = allComments.data.map(comment => ({
                text: comment.data.text,
                initials: comment.memberCreator.initials,
                fullName: comment.memberCreator.fullName,
            }));
            setComments(commentData);
        }
    }, [allComments.data]);
    return (
        <div className='zuGYG9Hh07LAu2'>
            <div className='container'>
                <div className='row'>
                    <h5 className='mb-2'><span><Title></Title></span>Title</h5>
                    <div className='col-md-12' style={{ marginLeft: '40px', position: 'relative', width: '95%' }}>
                        {isEditingTitle ? (
                            <input
                                className='form-control'
                                type='text'
                                value={updatedTitle}
                                onChange={handleTitleChange}
                                autoFocus
                            />
                        ) : (
                            <h3 onClick={handleTitleClick}>{editData}</h3>
                        )}
                    </div>
                </div>
            </div>
            <hr />
            <div className='container mt-4'>
                <div className='row'>
                    <div className='col-md-12 d-flex'>
                        <h5 className='mb-5'><span><Subject></Subject></span>Description</h5>
                    </div>
                    <div className='col-md-12' style={{ marginLeft: '40px', position: 'relative', width: '95%' }}>
                        <Description setDescription={setDescription?.data?.desc} onUpdateDescription={handleDescriptionUpdate} />
                    </div>
                </div>
            </div>
            <hr />
            {/* Custom field start */}
            <div className='container mt-4' >
                <h5><DashboardOutlined /><span className='mb-5'>Custom Fields</span></h5>
                <CustomFields trelloCardId={trelloCardId} />
            </div>
            {/* Custom field end */}
            <hr />
            <div className='container'>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h5 className='mb-5'><span><ChatBubbleOutline /></span>Comments</h5>
                            <ul>
                                {comments.map((comment, index) => (
                                    <div key={index} className='comment-item'>
                                        <CmtAvatar size={34} alt={comment.initials} title={comment.fullName} className='avatar'>{comment.initials}</CmtAvatar>
                                        <div className='comment-content'>
                                            <p className='full-name'>{comment.fullName}</p>
                                            <p className='comment-text'>{comment.text}</p>
                                        </div>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <hr />
            <div className='container'>
                <div className='container mt-3'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <h5 className='mb-5'><span><Attachment /></span>Attachments</h5>
                            <p style={{ marginLeft: '40px' }}>
                                {getAttachment?.data ? (
                                    getAttachment.data.map((attachment) => (
                                        <p key={attachment.id}>
                                            <a href={attachment.url} target="_blank" className='' rel="noopener noreferrer">
                                                {attachment.name}
                                            </a>
                                        </p>
                                    ))
                                ) : (
                                    <p></p>
                                )}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomFeildPopup;