import { Checkbox } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { setUpdatedAllCheck, setUpdatedChatConversation } from "redux/actions/Table.action";
import Axios from "services/config";
import { io } from "socket.io-client";
import { AppTheme } from "theme/customeTheme";
import { BASE_WS_URL } from "utils/globals";
import { clearConversationData } from "../../../../redux/actions/Table.action"
import ConversationChatSection from "./ConversationChatSection";

const colors = [
  "#D7D7D7",
  "#FFC0C0",
  "#C0D9FF",
  "#FFC0F9",
  "#D7D7D7",
  "#D7D7D7",
];
const Conversation = ({ TitleInfo }) => {
  // console.log("TitleInfo::", TitleInfo);
  const [conversation, setConversation] = React.useState([]);
  const [totalChat, setTotalChat] = React.useState(null);
  const [dataFetched, setDataFetched] = React.useState(false);
  const [fetching, setFetching] = useState(false);
  const [doneOnce, setDoneOnce] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const { lastSentMessage } = useSelector(({ table }) => table);
  const mountedRef = useRef();
  // console.log("res---lastSentMessage--", lastSentMessage);
  // console.log("conversation--", conversation);

  const dispatch = useDispatch();
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  useEffect(() => {
    if (dataFetched && !doneOnce) {
      setDataFetched(false)
      setDoneOnce(true);
      var objDiv = document.getElementById("scrollableDiv");
      objDiv.scrollTo({ top: objDiv?.scrollHeight || 0, behavior: 'smooth' });
    }
  }, [conversation])
  const getThreadMessages = (pageNo) => {
    console.log("getThreadMessages fetching", fetching);
    if (fetching) {
      return;
    }

    setFetching(true);
    if (page <= totalPage) {
      try {
        Axios.get(
          `thread_message/messages/${TitleInfo._id}?page=${pageNo}&limit=${limit}&message_type=chat`
        )
          .then((data) => {
            // console.log("conversationcon",data?.data);
            if (data.status == 200) {
              setConversation([
                ...data?.data?.threadMessages?.reverse(),
                ...conversation,
              ]);
              setTotalChat(data?.data?.totalItems);
              setTotalPage(data?.data?.totalPages);
              setPage(pageNo);
              setFetching(false);
              setDataFetched(true)

              // setTimeout(() => {
              //   var objDiv = document.getElementById("scrollableDiv");
              //   objDiv.scrollTop = objDiv?.scrollHeight || 0;
              // }, 200);
            }
          })
          .catch(() => null);
      } catch (error) { }
    }
  };
  const loader = (
    <div key="loader" className="loader">
      Loading ...
    </div>
  );
  useEffect(() => {
    // console.log('this is useeffesct----->',lastSentMessage);
    if (lastSentMessage?.data) {
      // console.log("this----lastSentMessage");
      if (lastSentMessage?.data?.threadMessageId) {
        let updatedData = [];
        updatedData = conversation.filter((item) => {
          return item.id ? item.id !== lastSentMessage?.data.threadMessageId : item._id !== lastSentMessage?.data.threadMessageId;
        }).map((item) => {
          return item;
        });
        
        console.log("updatedData---", updatedData);
        setConversation(updatedData);
        //on asnwer or question update
      } else {
        //on Chat update question object
        // console.log("this lastSentMessage");
        if (lastSentMessage.data.editMode) {
          let updatedData = [];
          updatedData = conversation.map((item) => {
            
            if (lastSentMessage.data._id === item?._id) {
              // console.log("lastSentMessage-----------------");
              return lastSentMessage.data
            }
            else return item;
          })
          setConversation(updatedData);
        }
        //on Chat Create.
        else
          setConversation([...conversation, lastSentMessage?.data]);
      }
      // setTimeout(() => {
      //   var objDiv = document.getElementById("scrollableDiv");
      //   objDiv.scrollTop = objDiv.scrollHeight;
      // }, 200);
    }
  }, [lastSentMessage]);
  useEffect(() => {

    getThreadMessages(1);
    mountedRef.current = true;
    const userToeken = localStorage.getItem('trello_user_token');
    const socket = io(BASE_WS_URL, {
      query: { "token": userToeken }
    });

    if (socket) {
      socket.emit("threadCreation", TitleInfo?._id);
      socket.on("threadCreationSuccess", (res) => {
        // console.log("res-----",JSON.parse(res));
        if (res) {
          const newMsg = JSON.parse(res);
          if (newMsg.message_type === 'chat') {
            // console.log("res---- chat it is");
            dispatch(setUpdatedChatConversation({ data: newMsg }));
            dispatch(setUpdatedAllCheck({ data: newMsg }));
          }
        }
      });
      socket.on("onThreadMessageDeleted", (res) => {
        // console.log("onThreadMessageDeleted-chat-", res);
        if (res) {
          dispatch(setUpdatedChatConversation({ data: res }));
          // dispatch(UpdateThreadReadNotification(UpdatedData))
        }
      })
    }
    return function clean() {
      console.log('clean');
      // dispatch(clearConversationData());
      setConversation([]);
      setPage(1);
      socket.close()
    }
  }, []);
  return (
    <div
      id="scrollableDiv"
      style={{
        // display: 'flex',
        flexDirection: "column-reverse",
        height: "450px !important",
      }}
      className="comments-section"
    >
      <InfiniteScroll
        loadMore={() => getThreadMessages(page + 1)}
        hasMore={conversation?.length < totalChat}
        loader={loader}
        isReverse={true}
        useWindow={false}
        threshold={10}
        scrollabletarget="scrollableDiv"
        style={{ flexDirection: "column-reverse", height: "450px !important" }}
      >
        {/* {messages?.map((item, i) => { */}
        {/* {console.log("conversation",conversation)} */}
        {conversation?.map((item, i) => {
          {/* {console.log("conversationitem",item)} */}
          return (
            <div key={"div-" + item._id}>
              <ConversationChatSection TitleInfo={TitleInfo} chat={item} />
            </div>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default Conversation;
