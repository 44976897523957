import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import TableServices from 'services/table';
import { useDispatch } from 'react-redux';

const DeliveryChecklistnew = ({ cardId, childCardId, typeOfCard, data }) => {
  const [newChecklistItem, setNewChecklistItem] = useState('');
  const dispatch = useDispatch();
  const handleSendButtonClick = async () => {
    try {
      if (!newChecklistItem.trim()) {
        console.error('New checklist item is empty');
        return;
      }

      const checkListId = data?.[0]?.id;
      const pos = data?.[0]?.pos;

      if (!checkListId) {
        console.error('Error adding checklist item: Checklist ID is undefined');
        return;
      }

      const selectedCardId = typeOfCard === 'maincard' ? cardId : childCardId;

      const addpayload = {
        checkListId: checkListId,
        Name: newChecklistItem,
        pos: pos,
      };

      await dispatch(TableServices.addChecklist(addpayload, selectedCardId));
      // dispatch(TableServices.getNeedInfo(cardId));

      const newItem = {
        Name: newChecklistItem,
        cardId: cardId,
        name: newChecklistItem,
        isChecked: false,
      };

      const updatedData = [...data];
      updatedData[0] = {
        ...updatedData[0],
        checkItems: [...updatedData[0].checkItems, newItem],
      };

      setNewChecklistItem('');

      if (typeOfCard === 'maincard') {
        dispatch(TableServices.getNeedInfo(cardId));
      } else if (typeOfCard === 'childcard') {
        dispatch(TableServices.getNeedInfo(childCardId));
      }
    } catch (error) {
      console.error('Error adding checklist item:', error);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
      <input
        type="text"
        value={newChecklistItem}
        onChange={(e) => setNewChecklistItem(e.target.value)}
        placeholder="Add New"
        style={{
          margin: '10px',
          padding: '5px 10px',
          fontFamily: '"Mulish", sans-serif',
          width: '-webkit-fill-available',
          borderRadius: '10px',
        }}
      />
      <Button
        className="btn btn-primary"
        style={{ backgroundColor: '#607d8b', color: 'white' }}
        onClick={handleSendButtonClick}
      >
        Send
      </Button>
    </div>
  );
};

export default DeliveryChecklistnew;
