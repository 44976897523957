/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import CommentPopover from '@jumbo/components/TableComponents/CommentPopover'
import CustomBadge from '@jumbo/components/TableComponents/CustomBadge'
// import CustomInfo from '@jumbo/components/TableComponents/CustomInfo'
import CustomLabel from '@jumbo/components/TableComponents/CustomLabel'
// import HoursSpentPopover from '@jumbo/components/TableComponents/HoursSpentPopover'
import LabelHighlighters from '@jumbo/components/TableComponents/LabelHighlighters'
// import ReactTableHideShowColumns from '@jumbo/components/TableComponents/react-table-hide-show'
import { doFilter, FoldersFilter, getSrNo, ManagersFilter, NumberRangeFilter, ProjectTypeFilter, StaticOptionsKeyValueFilter, TableDateRangeFilter, usePrevious } from '@jumbo/components/TableComponents/table-utils'
import TableActions from '@jumbo/components/TableComponents/TableActions'
import TableEditPopup from '@jumbo/components/TableComponents/TableEditPopup/TableEditPopup'
import { Box } from '@material-ui/core'
// import { makeStyles } from '@material-ui/styles'
import _ from 'lodash'
// import moment from 'moment'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { UpdateFAQNewNotification, UpdateFAQReadNotification, UpdateTableRow, UpdateThreadNewNotification, UpdateThreadReadNotification } from 'redux/actions/Table.action'
import TableServices from 'services/table'
import { io } from 'socket.io-client'
import { BASE_WS_URL } from 'utils/globals'
import ReactMainTable from './MainTable'
// import CardComponent from './MobileView'
import CustomStatus from '@jumbo/components/TableComponents/CustomStatus'
import DeliveryDateComponent from '@jumbo/components/TableComponents/DeliveryDateComponent'
import { LabelFilter } from '@jumbo/components/TableComponents/table-utils'
import WorkCardLabel from '@jumbo/components/TableComponents/WorkCardLabel'
import { Toc } from "@material-ui/icons"
import LegendIconList from './LegendIconList'
// import qs from 'query-string';
// import { useHistory } from 'react-router';
// import { useQueryState } from '../../../utils/useQueryState.js';
import WorkerRatingByTrade from '@jumbo/components/TableComponents/Ratings/WorkerRatingByTrade'
import { BrowserView, MobileView } from 'react-device-detect'
import BasicAuth from 'services/auth/Basic'
// import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader'
// import { JumboCard } from '@jumbo/components/Common'
import MobileActions from '@jumbo/components/TableComponents/MobileActions'
import MobileCards from './MobileCards'
// import CmtAvatar from '@coremat/CmtAvatar'
import CustomFeilds from '@jumbo/components/TableComponents/CustomFeildsPopup/CustomPopup'
const filterMap = {
    "name": "name",
    // "PrelimDue": ["PrelimStartDue","PrelimEndDue"],
    // "FinalDue": ["FinalStartDue","FinalEndDue"],
    "DeliveryDate": ["DeliveryStartDate", "DeliveryEndDate"],
    "PromisedDate": "PromisedDate",
    "projectWorker": "projectWorker",
    "projectManager": "projectManager",
    "Promise": "Promise",
    "LabelNames": "LabelNames",
    "folder": "folder",
    "Quote": "Quote",
    "projectType": "projectType",
    "Mech_Status": "Mech_Status",
    "Estimated_Mech_Hours": ["minMech", "maxMech"],
    "MechWorkCardLabels": "MechWorkCardLabels",
    "Mechanical_Last_Member": "Mechanical_Last_Member",
    "Manager_Innitial_Mechanical": "Manager_Innitial_Mechanical",
    "Elec_Status": "Elec_Status",
    "Estimated_Elec_Hours": ["minElec", "maxElec"],
    "ElectWorkCardLabels": "ElectWorkCardLabels",
    "Electrical_Last_Member": "Electrical_Last_Member",
    "Manager_Innitial_Electrical": "Manager_Innitial_Electrical",
    "Plum_Status": "Plum_Status",
    "Estimated_Plum_Hours": ["minPlum", "maxPlum"],
    "PlumWorkCardLabels": "PlumWorkCardLabels",
    "Plumbing_Last_Member": "Plumbing_Last_Member",
    "Manager_Innitial_Plumbing": "Manager_Innitial_Plumbing",
    "Total_Spent_Hours": ["minTimeSpent", "maxTimeSpent"],
}
// const useStyles = makeStyles((theme) => ({
//     root: {
//         backgroundColor: "#F2FAFD",
//         padding: "16px 16px 0 16px",
//         borderRadius: 0,
//         borderTopRightRadius: 10,
//         marginTop: "70px"
//     },
// }));
function ReactTable() {
    // const classes = useStyles();
    const mountedRef = useRef()
    const tableRef = useRef(null);
    const ITEMS_PER_PAGE = 15;
    // const history = useHistory();
    const [lastLockedIndex, setLastLockedIndex] = useState(2);
    const [rightLockedIndex, setRightLockedIndex] = useState(23);
    const [filtersToApply, setFiltersToApply] = React.useState({})
    // const searchString = qs.parse(history.location.search);
    // const [queryString, setQueryString] = useQueryState();
    // const [tableColumns, setTableColumns] = useState([]);
    const [tableView, setTableView] = useState('Latest');
    // const [isMobileView, setIsMobileView] = useState(false);
    const prevFiltersToApply = usePrevious(filtersToApply)
    const dispatch = useDispatch()
    const { tableData } = useSelector(({ table }) => table);
    const [paginationOptions, setPaginationOptions] = useState({
        limit: ITEMS_PER_PAGE,
        offset: 0,
        currentPage: 0,
        orderBy: '',
        orderType: null,
    });

    const columns = React.useMemo(
        () => [
            {
                "Header": "Sr.",
                "accessor": "Sr_NO",
                sticky: 'left',
                Cell: (props) => {
                    return (
                        // className={tableView === 'CustomOrder' || tableView === 'CustomOrder2' || tableView === 'DeliveredReadyProject' ? "table_Custom_srNo" : "table_index"}
                        <div className="table_srNo">
                            <CustomFeilds editData={props?.cell?.row?.original?.card_title?.name} trelloCardId={props?.cell?.row?.original?.trello_id} />
                            <TableEditPopup editData={props} trelloCardId={props?.cell?.row?.original?.trello_id} />
                            {
                                (tableView === 'CustomOrder' || tableView === 'DeliveredReadyProject') &&
                                // <span className="table_index">custom:{props?.cell?.row?.original?.projectMetaData?.order}</span>
                                <>
                                    <Toc style={{
                                        cursor: 'pointer',
                                    }} />
                                    <span className="table_index">custom:{props?.cell?.row?.original?.projectMetaData && Array.isArray(props?.cell?.row?.original?.projectMetaData) ? (props?.cell?.row?.original?.projectMetaData?.[0]?.order) : props?.cell?.row?.original?.projectMetaData?.order}
                                    </span>
                                </>
                            }
                            {
                                (tableView === 'CustomOrder2') &&
                                <>
                                    <Toc />
                                    <span className="table_index">custom:{props?.cell?.row?.original?.projectMetaData && Array.isArray(props?.cell?.row?.original?.projectMetaData) ? (props?.cell?.row?.original?.projectMetaData?.[0]?.order2) : props?.cell?.row?.original?.projectMetaData?.order2}</span>
                                </>
                            }
                            {
                                tableView !== 'CustomOrder' && tableView !== 'CustomOrder2' && tableView !== 'DeliveredReadyProject' &&
                                <span className="table_index">{getSrNo(props)}</span>
                            }
                        </div>
                    )
                },
                disableFilters: true,
            },
            {
                id: 'name',
                "Header": "Project ",
                sticky: 'left',
                accessor: (originalRow) => {
                    return <CustomBadge
                        cardId={originalRow?.trello_id}
                        name={originalRow?.card_title?.name}
                        labelStatus={originalRow?.labelNames}
                        PrelimDate={originalRow?.PrelimDue}
                        FinalDate={originalRow?.FinalDue}
                        promise={originalRow?.Promise} />
                },

            },
            {
                id: 'DeliveryDate',
                "Header": "Delivery Date(PST)",
                sticky: 'left',
                accessor: (originalRow) => {
                    // return originalRow?.PromisedDate
                    return <DeliveryDateComponent deliveryDates={originalRow} />
                },
                Filter: TableDateRangeFilter
            },
            {
                id: 'projectWorker',
                "Header": "Worker",
                // sticky: 'left',
                accessor: (originalRow) => {
                    return <WorkerRatingByTrade
                        // threadWorker={originalRow?.worker}
                        threadNewWorker={originalRow?.new_worker}
                        projectInfo={originalRow}
                        cardType={"All"} />
                    // return originalRow?.worker && Array.isArray(originalRow?.worker) ? originalRow?.worker?.[0]?.fullName : originalRow?.worker?.fullName
                },
                Filter: ManagersFilter,
            },
            {
                id: 'projectManager',
                "Header": "Manager",
                Filter: ManagersFilter,
                accessor: (originalRow) => {
                    // console.log("originalRow.manager>>>>", originalRow.manager);
                    // console.log("originalRow::::", originalRow);
                    return <>{originalRow?.new_manager && Array.isArray(originalRow?.new_manager) ? originalRow?.new_manager?.[0]?.fullName : originalRow?.new_manager?.fullName}</>
                },
            },
            // {
            //     id: 'PromisedDate',
            //     "Header": "Quote Signed Date",
            //     accessor: (originalRow, rowIndex) => {
            //         return originalRow?.PromisedDate
            //     },

            // },
            {
                id: 'Promise',
                "Header": "Promise",
                Filter: (props) => StaticOptionsKeyValueFilter(props, [{ label: 'Customer', value: 1 }, { label: 'Personal', value: 2 }]),
                accessor: (originalRow) => {
                    return originalRow?.Promise || "No promise"
                },

            },

            {
                id: 'LabelNames',
                "Header": "Highlighters",
                accessor: (originalRow) => {
                    return <LabelHighlighters
                        labelNames={originalRow?.labelNames} cardId={originalRow?.trello_id} name={originalRow?.card_title?.name} typeOfCard={"maincard"} />
                },
                Filter: LabelFilter
            },
            {
                id: 'projectType',
                "Header": "Type",
                accessor: (originalRow) => {
                    return originalRow?.Project_Type
                },
                Filter: ProjectTypeFilter
            },

            {
                id: 'folder',
                "Header": "Folder",
                Filter: FoldersFilter,
                accessor: (originalRow) => {
                    return originalRow?.Folder
                },
            },
            {
                id: 'Quote #',
                "Header": "Quote",
                accessor: (originalRow, rowIndex) => {
                    return originalRow?.Quote
                },
            },
            {
                id: 'MechWorkCardLabels',
                "Header": "Mech Labels",
                accessor: (originalRow) => {
                    return <WorkCardLabel child_cards={originalRow?.child_cards} cardType={"Mechanical"} typeOfCard={"childcard"} />
                },
                Filter: LabelFilter
            },
            {
                id: 'Mech_Status',
                "Header": "Mech. Status",
                // disableFilters: true,
                accessor: (originalRow) => {
                    return <CustomStatus hours={originalRow?.Estimated_Mech_Hours?.hours} child_cards={originalRow?.child_cards} cardType={"Mechanical"} />
                },
            },
            {
                id: 'Estimated_Mech_Hours',
                "Header": "Mechanical",
                Filter: NumberRangeFilter,
                accessor: (originalRow) => {
                    return <CustomLabel Estimated_Hours={originalRow?.Estimated_Mech_Hours} child_cards={originalRow?.child_cards} cardType={"Mechanical"} />
                },
            },
            {
                id: 'Mechanical_Last_Member',
                "Header": "Worker",
                Filter: ManagersFilter,
                accessor: (originalRow) => {
                    // return originalRow.Mechanical_Last_Member ? originalRow.Mechanical_Last_Member.map((val) => val?.fullName).join(', ') : "-"
                    return <WorkerRatingByTrade
                        // threadWorker={originalRow.Mechanical_Last_Member}
                        threadNewWorker={originalRow.new_Mec_Worker}
                        projectInfo={originalRow}
                        cardType={"Mechanical"}
                    />
                },
            },
            {
                id: 'Manager_Innitial_Mechanical',
                "Header": "Manager",
                Filter: ManagersFilter,
                accessor: (originalRow) => {
                    return <>{originalRow.new_Mec_manager
                        ? originalRow.new_Mec_manager
                            .map((val) => val?.fullName) : ("-")}</>
                },
            },
            {
                id: 'ElectWorkCardLabels',
                "Header": "Elect Labels",
                accessor: (originalRow) => {
                    return <WorkCardLabel child_cards={originalRow?.child_cards} cardType={"Electrical"} typeOfCard={"childcard"} />
                },
                Filter: LabelFilter
            },
            {
                id: 'Elec_Status',
                "Header": "Elec. Status",
                // disableFilters: true,
                accessor: (originalRow) => {
                    return <CustomStatus hours={originalRow?.Estimated_Mech_Hours?.hours} child_cards={originalRow?.child_cards} cardType={"Electrical"} />
                },
            },
            {
                id: 'Estimated_Elec_Hours',
                "Header": "Electrical",
                Filter: NumberRangeFilter,
                accessor: (originalRow) => {
                    return <CustomLabel Estimated_Hours={originalRow?.Estimated_Elec_Hours} child_cards={originalRow?.child_cards} cardType={"Electrical"} />
                },
            },
            {
                id: 'Electrical_Last_Member',
                "Header": "Worker",
                Filter: ManagersFilter,
                accessor: (originalRow) => {
                    // return originalRow.Electrical_Last_Member ? originalRow.Electrical_Last_Member.map((val) => val?.fullName).join(', ') : "-"
                    return <WorkerRatingByTrade
                        // threadWorker={originalRow.Electrical_Last_Member}
                        threadNewWorker={originalRow?.new_Ele_Worker}
                        projectInfo={originalRow}
                        cardType={"Electrical"}
                    />
                },
            },
            {
                id: 'Manager_Innitial_Electrical',
                "Header": "Manager",
                Filter: ManagersFilter,
                accessor: (originalRow) => {
                    return <>{originalRow.new_Ele_manager ? originalRow.new_Ele_manager.map((val) => val?.fullName).join(', ') : "-"}</>
                },
            },
            {
                id: 'PlumWorkCardLabels',
                "Header": "Plum Labels",
                accessor: (originalRow) => {
                    return (
                        <WorkCardLabel
                            child_cards={originalRow?.child_cards}
                            cardType={"Plumbing"}
                            typeOfCard={"childcard"} />
                    );
                },
                Filter: LabelFilter
            },
            {
                id: 'Plum_Status',
                "Header": "Plum. Status",
                // disableFilters: true,
                accessor: (originalRow) => {
                    return <CustomStatus hours={originalRow?.Estimated_Mech_Hours?.hours} child_cards={originalRow?.child_cards} cardType={"Plumbing"} />
                },
            },
            {
                id: 'Estimated_Plum_Hours',
                "Header": "Plumbing",
                Filter: NumberRangeFilter,
                accessor: (originalRow) => {
                    return <CustomLabel Estimated_Hours={originalRow?.Estimated_Plum_Hours} child_cards={originalRow?.child_cards} cardType={"Plumbing"} />
                },
            },
            {
                id: 'Plumbing_Last_Member',
                "Header": "Worker",
                Filter: ManagersFilter,
                accessor: (originalRow) => {
                    // return originalRow.Plumbing_Last_Member ? originalRow.Plumbing_Last_Member.map((val) => val?.fullName).join(', ') : "-"
                    return <WorkerRatingByTrade
                        // threadWorker={originalRow.Plumbing_Last_Member}
                        threadNewWorker={originalRow.new_Plu_Worker}
                        projectInfo={originalRow}
                        cardType={"Plumbing"}
                    />
                },
            },
            {
                id: 'Manager_Innitial_Plumbing',
                "Header": "Manager",
                Filter: ManagersFilter,
                accessor: (originalRow) => {
                    return <>{originalRow.new_Plu_manager ? originalRow.new_Plu_manager.map((val) => val?.fullName).join(', ') : "-"}</>
                },
            },
            {
                "Header": "Comment",
                id: "Comment",
                disableFilters: true,
                accessor: (originalRow) => {
                    return <CommentPopover projectInfo={originalRow} />
                },
                sticky: 'right',
            },
        ],
        ([tableView])
    )

    const applyFilters = (filters) => {
        setFiltersToApply(doFilter(filters, filterMap));
        // console.log("filters::", filters);
        // const filtersObj = doFilter(filters, filterMap);
        // setFiltersToApply(filtersObj);
        // setQueryString(filtersObj);

    }
    const getViewValue = (tableView) => {
        // console.log("tableView::", tableView);
        setTableView(tableView)
    }
    const getTableData = async () => {
        // console.log("getPageData 3 ", paginationOptions);
        // const queryString = new URLSearchParams(filtersToApply).toString();
        // const queryData = window.location.search;
        // const searchString = qs.parse(queryData);
        // console.log("queryString  1--", queryString);

        dispatch(TableServices.getTableData({
            limit: paginationOptions?.limit,
            currentPage: paginationOptions?.currentPage + 1,
            currentView: tableView || 'Latest',
            orderByColumn: paginationOptions.orderBy || null,
            columnOrderType: paginationOptions.orderType || null,
            ...filtersToApply
            // ...queryString
        }));
        let userToken = localStorage.getItem("trello_user_token");
        dispatch(BasicAuth.getTrelloUserDetails(userToken));
    }
    const getPageData = (pageNo, itemsPerPage) => {
        setPaginationOptions(old => {
            return {
                limit: itemsPerPage,
                offset: (pageNo) * itemsPerPage,
                currentPage: pageNo,
                orderBy: old.orderBy,
                orderType: old.orderType
            }
        });
    }

    const setOrderByColumns = (column) => {
        // console.log("column", column);
        if (paginationOptions.orderBy === column) {
            if (paginationOptions.orderType === "asc") {
                setPaginationOptions(old => {
                    return {
                        limit: old.limit,
                        offset: (0) * old.limit,
                        currentPage: 0,
                        orderType: "desc",
                        orderBy: column,
                    }
                });
            }
            if (paginationOptions.orderType === "desc") {
                setPaginationOptions(old => {
                    return {
                        limit: old.limit,
                        offset: (0) * old.limit,
                        currentPage: 0,
                        orderType: "asc",
                        orderBy: column,
                    }
                });
            }
        }
        else {
            setPaginationOptions(old => {
                return {
                    limit: old.limit,
                    offset: (0) * old.limit,
                    currentPage: 0,
                    orderType: "asc",
                    orderBy: column,
                }
            });
        }
    }
    useEffect(() => {

        if (filtersToApply && prevFiltersToApply &&
            !_.isEqual(
                prevFiltersToApply,
                filtersToApply,
            )
        ) {
            // if (queryString &&
            //     !_.isEqual(
            //         queryString
            //     )
            // ) {
            setPaginationOptions(old => {
                return {
                    limit: old.limit,
                    offset: (0) * old.limit,
                    currentPage: 0,
                    orderBy: old.orderBy,
                    orderType: old.orderType
                }
            });
            if (paginationOptions.currentPage === 0 && paginationOptions.offset === 0) {
                getTableData();
            }
        }
    }, [filtersToApply, prevFiltersToApply])
    // }, [JSON.stringify(queryString), tableColumns])

    useEffect(() => {
        if (paginationOptions.limit) {
            getTableData();
            dispatch(TableServices.getManagersData());
            dispatch(TableServices.getWorkerManager());
            dispatch(TableServices.getAllStatus());
            dispatch(TableServices.getAllProjectLabel());

            // dispatch(TableServices.getTotalHoursOfSum(
            //     { currentView: tableView || 'Latest', }
            //   ));
            // }
        }
    }, [paginationOptions.currentPage, paginationOptions.limit, paginationOptions.offset, paginationOptions.orderBy, paginationOptions.orderType, tableView, dispatch]);
    useEffect(() => {
        setPaginationOptions(old => {
            return {
                limit: old.limit,
                offset: (0) * old.limit,
                currentPage: 0,
                orderBy: '',
                orderType: null,
            }
        });
        if (paginationOptions.currentPage === 0 && paginationOptions.offset === 0) {
            getTableData();
        }
    }, [tableView]);
    useEffect(() => {
        //     const handleResize = () => {
        //         setIsMobileView(window.innerWidth <= 767); // Update the condition based on your mobile breakpoint
        //     };

        //     // Add event listener for window resize
        //     window.addEventListener('resize', handleResize);

        //     // Initial check on component mount
        //     handleResize();

        //     // Clean up the event listener on component unmount
        //     return () => {
        //         window.removeEventListener('resize', handleResize);
        //     };
        // }, []);
        // useEffect(() => {
        mountedRef.current = true
        const userToeken = localStorage.getItem('trello_user_token');
        const socket = io(BASE_WS_URL, {
            query: { "token": userToeken }
        });
        // console.log("IN Socket");

        if (socket) {
            socket.on("onTrelloCardUpdate", (res) => {
                const UpdatedData = JSON.parse(res)
                if (typeof UpdatedData?.child_cards?.[0] !== 'string')//fix for ignoring non populated children
                    dispatch(UpdateTableRow(UpdatedData))
                // console.log("onTrelloCardUpdate socket", UpdatedData);
            })
            socket.on("onTrelloCardAdded", (res) => {
                console.log("IN onTrelloCardAdded");
                console.log("IN onTrelloCardAdded", res);
                // getTableData();
                // getTableData(tableView, paginationOptions);
                // const AddUpdatedData = JSON.parse(res)
                // if (typeof AddUpdatedData?.child_cards?.[0] !== 'string')//fix for ignoring non populated children
                //     dispatch(UpdateTableRow(AddUpdatedData))
                // console.log("socket", AddUpdatedData);
            })
            socket.on("onThreadNewMessagePosted", (res) => {
                const UpdatedData = res
                if (UpdatedData.message_type === 'faq')
                    dispatch(UpdateFAQNewNotification(UpdatedData))
                else
                    dispatch(UpdateThreadNewNotification(UpdatedData))
            })
            socket.on("onThreadNewMessageRead", (res) => {
                const UpdatedData = res
                if (UpdatedData.message_type === 'faq')
                    dispatch(UpdateFAQReadNotification(UpdatedData))
                else
                    dispatch(UpdateThreadReadNotification(UpdatedData))
            })
        };
        return () => socket.close();
    }, [tableView]);
    return (
        <React.Fragment>
            <BrowserView>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                    <title> Priority Sheets | Report</title>
                </Helmet>
                <>
                    <Box display="flex" alignItems="center" className="table-content">
                        <TableActions getViewName={(tableView) => getViewValue(tableView)} tableViewName={tableView} />
                        <LegendIconList /></Box>
                    <div className="table-container">
                        <ReactMainTable tableRef={tableRef}
                            // cardId={originalRow?.trello_id}
                            applyFilters={applyFilters}
                            columns={columns}
                            lastLockedIndex={lastLockedIndex}
                            rightLockedIndex={rightLockedIndex}
                            itemsPerPage={paginationOptions.limit}
                            TotalLength={tableData?.totalItems || 0}
                            getPageData={getPageData}
                            orderByColumns={setOrderByColumns}
                            currentPage={paginationOptions.currentPage}
                            orderBy={paginationOptions.orderBy}
                            offset={paginationOptions.offset}
                            tableView={tableView}
                            updateCallBack={() => getTableData()}
                            data={tableData?.trelloCards || []} />
                    </div>
                </>
            </BrowserView>
            <MobileView>
                <div className='container'><MobileActions getViewName={(tableView) => getViewValue(tableView)} tableViewName={tableView} /></div>
                <MobileCards tableView={tableView} paginationOptions={paginationOptions} />
            </MobileView>
        </React.Fragment>

    )
}

export default ReactTable
