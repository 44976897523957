import React, { forwardRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardContent, Typography, Collapse, IconButton, makeStyles, Grid, Paper, Box, Drawer, TextField, Button, MenuItem, Select, Tooltip, Tab, Tabs, Popover } from "@material-ui/core";
import { AssignmentInd, Cancel, Check, CheckCircle, ExpandMore as ExpandMoreIcon, FilterList, FirstPage, LastPage, NavigateBefore, NavigateNext, NotificationsNone, PersonOutline, } from "@material-ui/icons";
import LabelHighlighters from "@jumbo/components/TableComponents/LabelHighlighters";
import MobileCustomBadge from "@jumbo/components/TableComponents/MobileCustomBadge";
import WorkCardLabel from "@jumbo/components/TableComponents/WorkCardLabel";
import WorkerRatingByTrade from "@jumbo/components/TableComponents/Ratings/WorkerRatingByTrade";
import CustomLabel from "@jumbo/components/TableComponents/CustomLabel";
import MobileCommentPopover from "@jumbo/components/TableComponents/MobileComment-Popover";
import MobileTableEditPopup from "@jumbo/components/TableComponents/TableEditPopup/MobileTableEditPopup";
import TableServices from "services/table";
import { clearNotificationData } from "redux/actions/Dashboard.action";
import clsx from "clsx";
import MobileDeliveryDateComponent from "@jumbo/components/TableComponents/mobileDeliveryDate";
import CmtAvatar from "@coremat/CmtAvatar";
import { Badge } from "reactour";
import DashboardServices from "services/dashboard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import CmtCard from "@coremat/CmtCard";
import InfiniteScroll from "react-infinite-scroller";
import CmtList from "@coremat/CmtList";
import NotificationItem from "@jumbo/components/AppLayout/partials/Header/HeaderNotifications/NotificationItem";
import CommentsDialogBox from "@jumbo/components/TableComponents/Comments/CommentsDialogBox";
import ColoredBadge from "@jumbo/components/TableComponents/HoursBg";
import { Skeleton } from "@material-ui/lab";
import CustomFeilds from "@jumbo/components/TableComponents/CustomFeildsPopup/CustomPopup";
const useStyles = makeStyles((theme) => ({
    card: {
        border: "1px solid #e0e0e0",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        margin: "11px"
    },
    header: {
        // display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        cursor: "pointer",
    },
    expandedContent: {
        paddingTop: theme.spacing(2),
    },
    smallText: {
        fontSize: "12px",
        fontFamily: "Poppins, sans-serif"
    },
    childcardText: {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "15px",
        textAlign: "left",
        padding: "4px 0px",
        color: "#606060"
    },
    childcardData: {
        fontFamily: "Poppins",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "13.27px",
        textAlign: "left",
        padding: "4px 0px",
        color: "#000000"
    },
    stickyButton: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'space-between',
        // width: '%',
        zIndex: '1000',
        backgroundColor: '#006d8f',
        padding: '16px',
    },
    resultsInfo: {
        position: "sticky",
        top: 0,
        background: "#ffffff",
        zIndex: 1,
        textAlign: "right",
    },
    horizontalScroll: {
        overflowX: "auto",
        whiteSpace: "nowrap",
        "& > div": {
            display: "flex",
        },
    },
    scrollableCard: {
        minWidth: "322px !important",
        marginRight: theme.spacing(2),
    },
    mechanicalCard: {
        width: "322px",
        height: "218px",
        padding: "15px",
        borderRadius: "10px",
        border: "1px solid #CECECE",
        gap: "22px",
    },
    electricalCard: {
        width: "322px",
        height: "218px",
        padding: "15px",
        borderRadius: "10px",
        border: "1px solid #CECECE",
        gap: "22px",
    },
    plumbingCard: {
        width: "322px",
        height: "218px",
        padding: "15px",
        borderRadius: "10px",
        border: "1px solid #CECECE",
        gap: "22px",
    },
    mechanicalCardTitle: {
        width: '292px',
        height: '45px',
        padding: '12px 10px',
        borderRadius: '5px',
        border: '0.5px solid #D7D7D7',
        gap: '10px',
        backgroundColor: '#7CE3BE',
        textAlign: 'center',
        marginLeft: '-17px',
        marginTop: '-17px',
    },
    electricalCardTitle: {
        width: '292px',
        height: '45px',
        padding: '12px 10px',
        borderRadius: '5px',
        border: '0.5px solid #D7D7D7',
        gap: '10px',
        backgroundColor: '#FFDBC0',
        textAlign: 'center',
        marginLeft: '-17px',
        marginTop: '-17px',
    },
    plumbCardTitle: {
        width: '292px',
        height: '45px',
        padding: '12px 10px',
        borderRadius: '5px',
        border: '0.5px solid #D7D7D7',
        gap: '10px',
        backgroundColor: '#D5EBF2',
        textAlign: 'center',
        marginLeft: '-17px',
        marginTop: '-17px',
    },
    cardTitle: {
        fontFamily: "Varta",
        fontSize: "14px",
        fontWeight: "400",
        lineHeight: "4px",
        letterSpacing: "0.15000000596046448px",
        textAlign: "left",
        height: "17px",
    },
    truncateText: {
        maxWidth: '82%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        '&:hover': {
            whiteSpace: 'normal',
            overflow: 'visible',
            textOverflow: 'inherit',
        },
    },
    truncateDate: {
        maxWidth: '88%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        '&:hover': {
            whiteSpace: 'normal',
            overflow: 'visible',
            textOverflow: 'inherit',
        },
    },
    cardText: {
        fontFamily: "Varta",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "16px",
        letter: "0.4px",
        height: "17px",
        color: "#666666"
    },
    filterContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
        borderBottom: '1px solid #e0e0e0',
    },

    filterButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        position: 'sticky',
        top: '0',
        zIndex: '1',
    },
    filterLabel: {
        fontSize: '20px',
        fontWeight: 'bold',
        margin: '0',
        color: 'white'
    },

}));
const MobileCards = forwardRef(({ paginationOptions, tableView }, ref) => {
    const { authUser } = useSelector(({ auth }) => auth);
    const { tableData } = useSelector(({ table }) => table);
    const ITEMS_PER_PAGE = 15;
    const dispatch = useDispatch();
    const classes = useStyles();
    const [expandedCard, setExpandedCard] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = tableData?.totalPages;
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = Math.min(startIndex + ITEMS_PER_PAGE || 0);
    const totalResults = tableData?.totalItems || 0;
    const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const [nameFilter, setNameFilter] = useState('');
    const [selectedType, setSelectedType] = useState(null);
    const [promiseFilter, setPromiseFilter] = useState(null);
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [selectedWorker, setSelectedWorker] = useState('');
    const [selectedManager, setSelectedManager] = useState('');
    const [mechanicalRange, setMechanicalRange] = useState({ min: '', max: '' });
    const [electricalRange, setElectricalRange] = useState({ min: '', max: '' });
    const [plumbingRange, setPlumbingRange] = useState({ min: '', max: '' });
    const [startDate, setStartDate] = useState('');
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [fetching, setFetching] = useState(true);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [endDate, setEndDate] = useState('');
    const [notificationOpen, setNotificationOpen] = useState(false);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [mention, setMention] = React.useState(false);
    const [notificationType, setNotificationType] = React.useState('NEW_PROJECT');
    const { totalHours } = useSelector(({ table }) => table);
    const { notificationData, notificationList } = useSelector(({ dashboard }) => dashboard);
    React.useEffect(() => {
        dispatch(DashboardServices.getNotificationData(page, limit, notificationType));
    }, [notificationType])
    const onOpenPopOver = event => {
        setAnchorEl(event.currentTarget);
    };
    const onClosePopOver = () => {
        setAnchorEl(null);
    };
    const notificationHandleChange = (event, value) => {
        setNotificationType(value);
        dispatch(clearNotificationData());
    };
    const handleDialogBox = (openDialog, item) => {
        setOpenDialog(openDialog);
        setMention(item);
    };
    useEffect(() => {
        setNotificationOpen(true)
        return function clean() {
            dispatch(clearNotificationData());
            setPage(1);
        };
    }, []);
    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const [mechanicalFilters, setMechanicalFilters] = useState({
        labelFilter: null,
        statusFilter: null,
        timeFilter: null,
        workerFilter: null,
        managerFilter: null,
    });

    const [electricalFilters, setElectricalFilters] = useState({
        labelFilter: null,
        statusFilter: null,
        timeFilter: null,
        workerFilter: null,
        managerFilter: null,
    });

    const [plumbingFilters, setPlumbingFilters] = useState({
        labelFilter: null,
        statusFilter: null,
        timeFilter: null,
        workerFilter: null,
        managerFilter: null,
    });


    const { projectTypeData } = useSelector(({ table }) => table);
    // console.log("projectTypeData", projectTypeData);
    React.useEffect(() => {
        dispatch(TableServices.getProjectTypeData());
    }, []);
    const { folderData } = useSelector(({ table }) => table);
    React.useEffect(() => {
        dispatch(TableServices.getFoldersData());
    }, []);
    const { managerData } = useSelector(({ table }) => table);
    React.useEffect(() => {
        dispatch(TableServices.getManagersData());
    }, []);
    const { allProjectLabels } = useSelector(({ table }) => table);
    React.useEffect(() => {
        dispatch(TableServices.getAllProjectLabel())
    }, [])
    // console.log("allProjectLabels", allProjectLabels);
    const handleCardClick = (card) => {
        if (expandedCard === card) {
            setExpandedCard(null);
        } else {
            setExpandedCard(card);
        }
    };
    const goToFirstPage = () => {
        if (currentPage !== 1) {
            setCurrentPage(1);
            setIsLoadingPage(true);
        }
    };
    const goToLastPage = () => {
        if (currentPage !== totalPages) {
            setCurrentPage(totalPages);
            setIsLoadingPage(true);
        }
    };
    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            setIsLoadingPage(true);
        }
    };
    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setIsLoadingPage(true);
        }
    };
    useEffect(() => {
        fetchData();
        setIsLoadingPage(false);
    }, [currentPage]);
    const handleClearFilters = () => {
        setNameFilter('');
        setSelectedType('');
        setPromiseFilter('');
        setSelectedFolder('');
        setStartDate('');
        setEndDate('');
        setSelectedManager('');
        setSelectedWorker('');
        setMechanicalFilters({
            labelFilter: '',
            statusFilter: '',
            managerFilter: '',
            workerFilter: '',
        });
        setMechanicalRange({ min: '', max: '' });
        setElectricalFilters({
            labelFilter: '',
            statusFilter: '',
            managerFilter: '',
            workerFilter: '',
        });
        setElectricalRange({ min: '', max: '' });
        setPlumbingFilters({
            labelFilter: '',
            statusFilter: '',
            managerFilter: '',
            workerFilter: '',
        });
        setPlumbingRange({ min: '', max: '' });
    };
    const fetchData = async () => {
        const apiRequestParams = {
            limit: paginationOptions?.limit,
            currentPage: currentPage,
            currentView: tableView || 'Latest',
            orderByColumn: paginationOptions.orderBy || null,
            columnOrderType: paginationOptions.orderType || null,
            ...(startDate && { DeliveryStartDate: startDate }),
            ...(endDate && { DeliveryEndDate: endDate }),
            ...(mechanicalFilters.labelFilter && {
                MechWorkCardLabels: mechanicalFilters.labelFilter,
            }),
            ...(mechanicalFilters.statusFilter && {
                Mech_Status: mechanicalFilters.statusFilter,
            }),
            ...(mechanicalFilters.workerFilter && {
                Mechanical_Last_Member: mechanicalFilters.workerFilter,
            }),
            ...(mechanicalFilters.managerFilter && {
                Manager_Innitial_Mechanical: mechanicalFilters.managerFilter,
            }),
            ...(electricalFilters.labelFilter && {
                ElectWorkCardLabels: electricalFilters.labelFilter,
            }),
            ...(electricalFilters.statusFilter && {
                Elec_Status: electricalFilters.statusFilter,
            }),
            ...(electricalFilters.workerFilter && {
                Electrical_Last_Member: electricalFilters.workerFilter,
            }),
            ...(electricalFilters.managerFilter && {
                Manager_Innitial_Electrical: electricalFilters.managerFilter,
            }),
            ...(plumbingFilters.labelFilter && {
                PlumWorkCardLabels: plumbingFilters.labelFilter,
            }),
            ...(plumbingFilters.statusFilter && {
                Plum_Status: plumbingFilters.statusFilter,
            }),
            ...(plumbingFilters.workerFilter && {
                Plumbing_Last_Member: plumbingFilters.workerFilter,
            }),
            ...(plumbingFilters.managerFilter && {
                Manager_Innitial_Plumbing: plumbingFilters.managerFilter,
            }),
            ...(mechanicalRange.min !== '' && { minMech: mechanicalRange.min }),
            ...(mechanicalRange.max !== '' && { maxMech: mechanicalRange.max }),
            ...(electricalRange.min !== '' && { minElec: electricalRange.min }),
            ...(electricalRange.max !== '' && { maxElec: electricalRange.max }),
            ...(plumbingRange.min !== '' && { minPlum: plumbingRange.min }),
            ...(plumbingRange.max !== '' && { maxPlum: plumbingRange.max }),

        };
        if (selectedType) {
            apiRequestParams.projectType = selectedType;
        }

        if (selectedFolder) {
            apiRequestParams.folder = selectedFolder;
        }

        if (promiseFilter) {
            apiRequestParams.Promise = promiseFilter;
        }
        if (nameFilter) {
            apiRequestParams.name = nameFilter;
        }

        if (selectedWorker) {
            apiRequestParams.projectWorker = selectedWorker;
        }

        if (selectedManager) {
            apiRequestParams.projectManager = selectedManager;
        }

        dispatch(TableServices.getTableData(apiRequestParams));
    };

    const handleToggleFilterDrawer = () => {
        setFilterDrawerOpen(!filterDrawerOpen);
    };
    const handleApplyFilters = () => {
        fetchData(nameFilter, selectedType, promiseFilter, selectedFolder, selectedWorker, selectedManager);
        setFilterDrawerOpen(false);
    };
    const getThreadMessages = () => {
        setFetching(true);
        if (notificationData?.totalItems !== notificationList.length && fetching == false) {
            setPage(page + 1);
            dispatch(DashboardServices.getNotificationData(page + 1, limit, notificationType));
        }
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    return (
        <div>
            <Button onClick={handleToggleFilterDrawer} style={{ marginTop: "-52px", left: "114px", width: '88px', height: '30px', gap: '15px', border: '1px solid #006D8F', borderRadius: '2px', fontSize: '12px', textTransform: 'initial', fontWeight: '400', fontFamily: 'poppins' }}><p style={{ width: '47px', lineHeight: '12.8px', padding: '5px', position: 'relative', top: '8px', fontWeight: '400' }}>Filter</p> <FilterList style={{ width: '15px', height: '15px' }} /></Button>
            <CmtAvatar size={25} style={{ backgroundColor: "#006D8F", fontSize: "13px", marginTop: '-48px', left: '327px' }}>{authUser?.innitials}</CmtAvatar>
            <Tooltip title="Notifications" >
                <IconButton
                    onClick={onOpenPopOver}
                    className={clsx(classes.iconRoot, "Cmt-appIcon", {
                        active: notificationData?.totalItems > 0
                    })}
                    style={{ width: '20px', height: '24.38px', color: '#006D8F', backgroundColor: 'none', boxShadow: 'none' }}
                >
                    <Badge badgeContent={notificationData?.totalItems} style={{ width: '20px', height: '24.38px', color: '#006D8F', backgroundColor: 'none', boxShadow: 'none' }}>
                        {/* <NotificationsIcon /> */}
                        <NotificationsNone style={{ width: '28px', height: '24.38px', color: '#006D8F', backgroundColor: 'none', boxShadow: 'none', marginTop: '-38px', marginLeft: '291px' }} />
                        {/* <img src="/images/notification.png" className={classes.loginSideImg2} /> */}
                    </Badge>
                </IconButton>
            </Tooltip>
            <Grid container style={{ textAlign: 'center', marginBottom: '12px' }}>
                <Grid xs={4}>
                    <p>Mechanical</p>
                    <ColoredBadge totalHours={totalHours?.TotalMechHours} />
                </Grid>
                <Grid xs={4}>
                    <p>Electrical</p>
                    <ColoredBadge ColoredBadge totalHours={totalHours?.TotalElecHours} />
                </Grid>
                <Grid xs={4}>
                    <p>Plumbing</p>
                    <ColoredBadge ColoredBadge totalHours={totalHours?.TotalPlumHours} />
                </Grid>
            </Grid>
            <Popover
                className={classes.popoverRoot}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={onClosePopOver}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}>
                <CmtCard className={classes.cardRoot}>
                    <CmtCardHeader
                        title="Notifications"
                        actionsPos="top-corner"
                        // actions={actions}
                        separator={{
                            // color: theme.palette.borderColor.dark,
                            borderWidth: 1,
                            borderStyle: "solid"
                        }}
                    />
                    <CmtCardContent>
                        {/* <div>Working Notifications</div> */}
                        <Tabs
                            value={notificationType}
                            onChange={notificationHandleChange}
                            textColor="secondary"
                            indicatorColor="secondary"
                            aria-label="secondary tabs example"
                        >
                            <Tab value="NEW_PROJECT" label="New Project" />
                            <Tab value="TAGGED" label="Tagged" />
                        </Tabs>
                        {
                            notificationOpen == true &&
                            <div
                                id="notificationDiv"
                                style={{
                                    // display: 'flex',
                                    height: "450px !important",
                                }}
                                className="comments-section"
                            >
                                <InfiniteScroll
                                    loadMore={() => getThreadMessages()}
                                    hasMore={notificationData?.totalItems ? notificationData?.totalItems !== notificationList.length ? true : false : false}
                                    // loader={loader}
                                    useWindow={false}
                                    threshold={0.8}
                                    scrollabletarget="notificationDiv"
                                    style={{ height: "450px !important" }}
                                >
                                    {notificationList?.length > 0 ? (
                                        // <PerfectScrollbar className={classes.scrollbarRoot}>
                                        <CmtList
                                            data={notificationList}
                                            renderRow={(item, index) => (
                                                <NotificationItem onClose={onClosePopOver} notificationType={notificationType} page={page} key={index} item={item} handleDialogBox={handleDialogBox} />
                                            )}
                                        />
                                        // </PerfectScrollbar>
                                    ) : (
                                        <Box p={6}>
                                            <Typography variant="body2">No notifications found</Typography>
                                        </Box>
                                    )}
                                </InfiniteScroll>
                            </div>
                        }

                    </CmtCardContent>
                </CmtCard>
            </Popover>
            <CommentsDialogBox openDialog={openDialog} projectInfo={mention?.projectCardId} commentSection={mention?.messageId?.thread} handleCloseDialog={handleCloseDialog} />
            <Drawer
                anchor="bottom"
                open={filterDrawerOpen}
                onClose={handleToggleFilterDrawer}
                height="55"
                style={{ borderRadius: '32px 32px 0px 0px', padding: '17px' }}>
                <div className={classes.filterDrawer} style={{ height: '421px' }}>
                    <Grid container className={classes.stickyButton} >
                        <Grid xs={8} className={classes.filterLabel}>Filters</Grid>
                        <Grid xs={2} className='text-center'><CheckCircle variant="contained" onClick={handleApplyFilters} style={{ color: 'white' }} /></Grid>
                        <Grid xs={2} className='text-center'><Cancel variant="contained" style={{ color: 'white' }} onClick={handleClearFilters} /></Grid>
                    </Grid>
                    <div style={{ padding: '21px', marginTop: '45px' }}>
                        <Grid container className="mt-3">
                            <Grid xs={6}>
                                <label>Name:</label>
                                <input
                                    label="Filter by Name"
                                    value={nameFilter}
                                    onChange={(e) => setNameFilter(e.target.value)}
                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}
                                /></Grid>
                            <Grid xs={6}>
                                <label>Type</label>
                                <br />
                                <select
                                    label="Type"
                                    // value={selectedType}
                                    onChange={(e) => setSelectedType(e.target.value)}

                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}
                                >
                                    <option>Select</option>
                                    {projectTypeData.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                        </Grid>
                        <Grid container className="mt-3">
                            <Grid xs={6}>
                                <label>Promise</label>
                                <select
                                    label="Promise"
                                    value={promiseFilter}
                                    onChange={(e) => setPromiseFilter(e.target.value)} style={{ border: '1px solid #266e90', width: '133px', borderRadius: '4px', padding: '6px', }}>
                                    <option value="">Select</option>
                                    <option value="1">Customer</option>
                                    <option value="2">Personal</option>
                                </select>
                            </Grid>
                            <Grid xs={6}>
                                <label>Folder</label>
                                <select
                                    label="Folder"
                                    value={selectedFolder}
                                    onChange={(e) => setSelectedFolder(e.target.value)}
                                    style={{ border: '1px solid #266e90', borderRadius: '4px', padding: '6px', width: '133px', }}>
                                    {folderData.map((option) => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                        </Grid>
                        <Grid container className="mt-3">
                            <Grid xs={6}>
                                <label>Start Date</label>
                                <input
                                    label="Start Date"
                                    type="date"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <label>End Date</label>
                                <input
                                    label="End Date"
                                    type="date"
                                    value={endDate}
                                    onChange={(e) => setEndDate(e.target.value)}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className="mt-3">
                            <Grid xs={6}>
                                <label>Manager</label>
                                <select
                                    select
                                    label="Manager"
                                    value={selectedManager}
                                    onChange={(e) => setSelectedManager(e.target.value)}

                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}
                                >
                                    <option>Select</option>
                                    <option value={"--"}>--</option>
                                    {managerData.map((manager) => (
                                        <option
                                            key={manager.value} value={manager.value}>
                                            {manager.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid xs={6}>
                                <label>Worker</label>
                                <select
                                    label="Worker"
                                    value={selectedWorker}
                                    onChange={(e) => setSelectedWorker(e.target.value)}

                                    style={{ border: '1px solid #266e90', width: '133px', borderRadius: '4px', padding: '6px', }}
                                >
                                    <option>Select</option>
                                    <option value={"--"}>--</option>
                                    {managerData.map((worker) => (
                                        <option key={worker.value} value={worker.value}>
                                            {worker.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                        </Grid>

                        <h3 className="mt-3 mb-2" style={{ padding: '5px', border: '2px solid #d6f3d9', borderRadius: '4px', background: '#d6f3d9', color: 'black', fontSize: '20px' }}>Mechanical</h3>

                        <Grid container className="mt-3">
                            <Grid xs={6}>
                                <label>Label</label>
                                <br />
                                <select
                                    label="Label"
                                    value={mechanicalFilters.labelFilter}
                                    onChange={(e) =>
                                        setMechanicalFilters({
                                            ...mechanicalFilters,
                                            labelFilter: e.target.value,
                                        })}

                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}
                                >
                                    <option>Select</option>
                                    {allProjectLabels.map((label) => (
                                        <option key={label.value} value={label.value}>
                                            {label.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid xs={6}>
                                <label>Status</label>
                                <input
                                    label="Status"
                                    value={mechanicalFilters.statusFilter}
                                    onChange={(e) =>
                                        setMechanicalFilters({
                                            ...mechanicalFilters,
                                            statusFilter: e.target.value,
                                        })
                                    }
                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}

                                />
                            </Grid>
                        </Grid>

                        <Grid container className="mt-3">
                            <Grid xs={6}>
                                <label>Min Value</label>
                                <input
                                    label="Min Value"
                                    type="number"
                                    value={mechanicalRange.min}
                                    onChange={(e) => setMechanicalRange({ ...mechanicalRange, min: e.target.value })}

                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <label>Max Value</label>
                                <input
                                    label="Max Value"
                                    type="number"
                                    value={mechanicalRange.max}
                                    onChange={(e) => setMechanicalRange({ ...mechanicalRange, max: e.target.value })}
                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className="mt-3">
                            <Grid xs={6}>
                                <label>Manager</label>
                                
                                <select
                                    label="Manager"
                                    value={mechanicalFilters.managerFilter}
                                    onChange={(e) => setMechanicalFilters({
                                        ...mechanicalFilters,
                                        workerFilter: e.target.value,
                                    })}
                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}

                                >
                                    <option>Select</option>
                                    <option value={"--"}>--</option>
                                    {managerData.map((manager) => (
                                        <option key={manager.value} value={manager.value}>
                                            {manager.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid xs={6}>
                                <label>worker</label>
                                <select
                                    label="worker"
                                    value={mechanicalFilters.workerFilter}
                                    onChange={(e) => setMechanicalFilters({
                                        ...mechanicalFilters,
                                        workerFilter: e.target.value,
                                    })}
                                    style={{ border: '1px solid #266e90', width: '133px', borderRadius: '4px', padding: '6px', }}

                                >
                                    <option>Select</option>
                                    <option value={"--"}>--</option>
                                    {managerData.map((worker) => (
                                        <option key={worker.value} value={worker.value}>
                                            {worker.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                        </Grid>


                        <h3 className="mt-3 mb-2" style={{ padding: '5px', border: '2px solid #f8f0da', borderRadius: '4px', background: '#f8f0da', color: 'black', fontSize: '20px' }}>Electrical</h3>

                        <Grid container className="mt-3">
                            <Grid xs={6}>
                                <label>Label</label>
                                <br />
                                <select
                                    value={electricalFilters.labelFilter}
                                    onChange={(e) =>
                                        setElectricalFilters({
                                            ...electricalFilters,
                                            labelFilter: e.target.value,
                                        })}
                                    style={{ border: '1px solid #266e90', width: '133px', borderRadius: '4px', padding: '6px', }}

                                >
                                    <option>Select</option>
                                    {allProjectLabels.map((label) => (
                                        <option key={label.value} value={label.value}>
                                            {label.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid xs={6}>
                                <label>Status</label>
                                <input
                                    label="Status"
                                    value={electricalFilters.statusFilter}
                                    onChange={(e) =>
                                        setElectricalFilters({
                                            ...electricalFilters,
                                            statusFilter: e.target.value,
                                        })
                                    }
                                    style={{ border: '1px solid #266e90', width: '133px', borderRadius: '4px', padding: '6px', }}

                                />
                            </Grid>
                        </Grid>
                        <Grid container className="mt-3">
                            <Grid xs={6}>
                                <label>Min Value</label>
                                <input
                                    label="Min Value"
                                    type="number"
                                    value={electricalRange.min}
                                    onChange={(e) => setElectricalRange({ ...electricalRange, min: e.target.value })}

                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}
                                />
                            </Grid>
                            <Grid xs={6}>
                                <label>Max Value</label>
                                <input
                                    label="Max Value"
                                    type="number"
                                    value={electricalRange.max}
                                    onChange={(e) => setElectricalRange({ ...electricalRange, max: e.target.value })}

                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container className="mt-3">
                            <Grid xs={6}>
                                <label>Manager</label>
                                <select

                                    label="Manager"
                                    value={electricalFilters.managerFilter}
                                    onChange={(e) => setElectricalFilters({
                                        ...electricalFilters,
                                        workerFilter: e.target.value,
                                    })}
                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}

                                >
                                    <option>Select</option>
                                    <option value={"--"}>--</option>
                                    {managerData.map((manager) => (
                                        <option key={manager.value} value={manager.value}>
                                            {manager.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid xs={6}>
                                <label>Worker</label>
                                <select

                                    label="Worker"
                                    value={electricalFilters.workerFilter}
                                    onChange={(e) => setElectricalFilters({
                                        ...electricalFilters,
                                        workerFilter: e.target.value,
                                    })}
                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}

                                >
                                    <option>Select</option>
                                    <option value={"--"}>--</option>
                                    {managerData.map((worker) => (
                                        <option key={worker.value} value={worker.value}>
                                            {worker.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                        </Grid>

                        <h3 className="mt-3 mb-2" style={{ padding: '5px', border: '2px solid #d5ebf2', borderRadius: '4px', background: '#d5ebf2', color: 'black', fontSize: '20px' }}>Plumbing</h3>
                        <Grid container className="mt-3">
                            <Grid xs={6}>
                                <label>Label</label>
                                <br />
                                <select
                                    value={plumbingFilters.labelFilter}
                                    onChange={(e) =>
                                        setPlumbingFilters({
                                            ...plumbingFilters,
                                            labelFilter: e.target.value,
                                        })}

                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}
                                ><option>Select</option>
                                    {allProjectLabels.map((label) => (
                                        <option key={label.value} value={label.value}>
                                            {label.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid xs={6}>
                                <label>Status</label>
                                <input
                                    label="Status"
                                    value={plumbingFilters.statusFilter}
                                    onChange={(e) =>
                                        setPlumbingFilters({
                                            ...plumbingFilters,
                                            statusFilter: e.target.value,
                                        })
                                    }
                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}

                                />
                            </Grid>
                        </Grid>

                        <Grid container className="mt-3">
                            <Grid xs={6}>
                                <label>Min Value</label>
                                <input
                                    label="Min Value"
                                    type="number"
                                    value={plumbingRange.min}
                                    onChange={(e) => setPlumbingRange({ ...plumbingRange, min: e.target.value })}
                                    style={{ border: '1px solid #266e90', borderRadius: '4px', padding: '6px', width: '133px' }}

                                />
                            </Grid>
                            <Grid xs={6}>
                                <label>Max Value</label>
                                <input
                                    label="Max Value"
                                    type="number"
                                    value={plumbingRange.max}
                                    onChange={(e) => setPlumbingRange({ ...plumbingRange, max: e.target.value })}
                                    style={{ border: '1px solid #266e90', borderRadius: '4px', padding: '6px', width: '133px' }}

                                />
                            </Grid>
                        </Grid>

                        <Grid container className="mt-3">
                            <Grid xs={6}>
                                <label>Manager</label>
                                <select
                                    label="Manager"
                                    value={plumbingFilters.managerFilter}
                                    onChange={(e) => setPlumbingFilters({
                                        ...plumbingFilters,
                                        managerFilter: e.target.value,
                                    })}
                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}

                                >
                                    <option>Select</option>
                                    <option value={"--"}>--</option>
                                    {managerData.map((manager) => (
                                        <option key={manager.value} value={manager.value}>
                                            {manager.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                            <Grid xs={6}>
                                <label>Worker</label>
                                <select
                                    label="Worker"
                                    value={plumbingFilters.workerFilter}
                                    onChange={(e) => setPlumbingFilters({
                                        ...plumbingFilters,
                                        workerFilter: e.target.value,
                                    })}
                                    style={{ border: '1px solid #266e90', borderRadius: '4px', width: '133px', padding: '6px', }}

                                >
                                    <option>Select</option>
                                    <option value={"--"}>--</option>
                                    {managerData.map((worker) => (
                                        <option key={worker.value} value={worker.value}>
                                            {worker.label}
                                        </option>
                                    ))}
                                </select>
                            </Grid>
                        </Grid>



                    </div>
                </div>
            </Drawer>

            <div className="container">
                <Grid container spacing={2}>
                    <Grid item xs={1} style={{ fontFamily: 'Nobile', fontSize: '12px', lineHeight: '12.02px', fontWeight: '400', verticalTrim: 'Cap Height', color: 'grey' }}> <FirstPage onClick={goToFirstPage} disabled={currentPage === 1} /></Grid>
                    <Grid item xs={1} style={{ fontFamily: 'Nobile', fontSize: '12px', lineHeight: '12.02px', fontWeight: '400', verticalTrim: 'Cap Height', color: 'grey' }}><NavigateBefore onClick={goToPreviousPage} disabled={currentPage === 1} variant="contained" /></Grid>
                    <Grid item xs={8} className="text-center" style={{ fontFamily: 'Nobile', fontSize: '12px', lineHeight: '12.02px', fontWeight: '400', verticalTrim: 'Cap Height', color: 'grey' }}> Displaying Results {startIndex + 1} - {endIndex} of {totalResults}</Grid>
                    <Grid item xs={1} style={{ fontFamily: 'Nobile', fontSize: '12px', lineHeight: '12.02px', fontWeight: '400', verticalTrim: 'Cap Height', color: 'grey' }}> <NavigateNext onClick={goToNextPage} disabled={currentPage === totalPages} variant="contained" /></Grid>
                    <Grid item xs={1} style={{ fontFamily: 'Nobile', fontSize: '12px', lineHeight: '12.02px', fontWeight: '400', verticalTrim: 'Cap Height', color: 'grey' }}><LastPage onClick={goToLastPage} disabled={currentPage === totalPages} /></Grid>
                </Grid>
            </div>
            {isLoadingPage ? (
                <>
                    <div className='p-2 m-2' style={{ border: '1px solid lightgray', borderRadius: '12px', width: '95%' }}>
                        <Skeleton width={700} />
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton width={150} /></div>
                    </div>
                    <div className='p-2 m-2' style={{ border: '1px solid lightgray', borderRadius: '12px', width: '95%' }}>
                        <Skeleton width={700} />
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton width={150} /></div>
                    </div>
                    <div className='p-2 m-2' style={{ border: '1px solid lightgray', borderRadius: '12px', width: '95%' }}>
                        <Skeleton width={700} />
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton width={150} /></div>
                    </div>
                    <div className='p-2 m-2' style={{ border: '1px solid lightgray', borderRadius: '12px', width: '95%' }}>
                        <Skeleton width={700} />
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton width={150} /></div>
                    </div>
                    <div className='p-2 m-2' style={{ border: '1px solid lightgray', borderRadius: '12px', width: '95%' }}>
                        <Skeleton width={700} />
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton width={150} /></div>
                    </div>
                    <div className='p-2 m-2' style={{ border: '1px solid lightgray', borderRadius: '12px', width: '95%' }}>
                        <Skeleton width={700} />
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                        <div className="d-flex ps-2"><Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton width={150} /></div>
                    </div>
                </>

            ) : (
                tableData && tableData.trelloCards && Array.isArray(tableData.trelloCards) ? (
                    tableData.trelloCards.map((card, index) => (
                        <Card key={index} className={classes.card} style={{ height: "" }}>
                            <div className={classes.header} onClick={() => handleCardClick(card)}>
                                <CardContent>
                                    <MobileCustomBadge cardId={card?.trello_id} name={card?.card_title?.name} labelStatus={card?.labelNames} PrelimDate={card?.PrelimDue} FinalDate={card?.FinalDue} promise={card?.Promise} child_cards={card?.child_cards} />
                                    <Grid container spacing={2}>
                                        <Grid item xs={1}>
                                            <MobileTableEditPopup editData={card} trelloCardId={card?.trello_id} />
                                        </Grid>
                                        <Grid item xs={11} className="p-0" style={{ marginTop: "" }}>
                                            <div className={classes.truncateText}>
                                                <Typography variant="p" className={classes.cardTitle} style={{ padding: "0px !important", maxWidth: '4px', whiteSpace: 'nowrap', overflow: "hidden", textOverflow: 'ellipsis' }}>{card?.card_title?.name}</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                        <Grid item xs={5} className="p-0" style={{ marginTop: '' }}>
                                            <Typography className={classes.cardText}>
                                                Type: <span style={{ color: "#000" }}>{card?.Project_Type}</span>
                                            </Typography>
                                            <Typography className={classes.cardText}>
                                                Promise: <span style={{ color: "#000" }}> {card?.Promise}</span>
                                            </Typography>
                                            <Typography className={classes.cardText}>
                                                <PersonOutline className="WorkerManager" ></PersonOutline>{" "}
                                                <span style={{ color: "#000", fontWeight: "400", fontSize: "12px", lineHeight: "16px" }}>{card?.new_manager?.[0]?.fullName}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={6} className="p-0" style={{ marginTop: '' }}>
                                            <Typography className={classes.cardText}>
                                                Folder:<span style={{ color: "#000" }}>{card?.Folder}</span>
                                            </Typography>
                                            <Typography className={classes.cardText}>
                                                <div className={classes.truncateDate}>
                                                    Delivery Date: <span style={{ color: "#000", }}><MobileDeliveryDateComponent deliveryDates={card} /></span>
                                                </div>
                                            </Typography>
                                            <Typography className={classes.cardText}>
                                                <AssignmentInd className="WorkerManager" ></AssignmentInd>{" "}
                                                <span style={{ color: "#000", fontWeight: "400", fontSize: "12px", lineHeight: "16px" }}>{card?.new_worker?.[0]?.fullName}</span>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <CustomFeilds editData={card?.card_title?.name} trelloCardId={card?.trello_id}/>
                                        </Grid>
                                        <Grid item xs={9} style={{ marginTop: '-2px' }} className="p-0">
                                            <LabelHighlighters labelNames={card?.labelNames} cardId={card?.trello_id} name={card?.card_title?.name} typeOfCard={"maincard"} />
                                        </Grid>
                                        <Grid item xs={2} style={{ textAlign: "center", marginTop: '-3px' }}>
                                            <MobileCommentPopover projectInfo={card} />
                                        </Grid>
                                    </Grid>
                                    <IconButton onClick={() => handleCardClick(card)} aria-expanded={expandedCard === card} aria-label="expand" style={{ display: "none" }}></IconButton>
                                </CardContent>
                            </div>
                            <Collapse in={expandedCard === card} timeout="auto">
                                <CardContent className={classes.expandedContent}>
                                    {card?.child_cards && Array.isArray(card?.child_cards) ? (
                                        <Paper className={classes.horizontalScroll}>
                                            <Box display="flex">
                                                {card?.child_cards.map((childCard, childIndex) => {
                                                    if (childCard?.typeOfCard === "Mechanical") {
                                                        return (
                                                            <Card key={childIndex} className={`${classes.scrollableCard} ${classes.mechanicalCard}`}>
                                                                <CardContent>
                                                                    <Typography variant="h1" className={classes.mechanicalCardTitle} style={{
                                                                        fontFamily: 'Poppins',
                                                                        fontSize: '18px',
                                                                        fontWeight: 400,
                                                                        lineHeight: '21px',
                                                                        letterSpacing: '0.03em',
                                                                        textAlign: 'center',
                                                                    }}>
                                                                        Mechanical
                                                                    </Typography>
                                                                    <Grid container spacing={2} style={{ marginTop: '12px' }}>
                                                                        <Grid item xs={6}>
                                                                            <Typography className={classes.childcardText}>
                                                                                Label:
                                                                            </Typography>
                                                                            <Typography className={classes.childcardText}>
                                                                                Status:
                                                                            </Typography>
                                                                            <Typography className={classes.childcardText}>
                                                                                TIME:
                                                                            </Typography>
                                                                            <Typography className={classes.childcardText}>
                                                                                Worker:
                                                                            </Typography>
                                                                            <Typography className={classes.childcardText}>
                                                                                Manager:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                <WorkCardLabel
                                                                                    child_cards={card?.child_cards}
                                                                                    cardType={"Mechanical"}
                                                                                    typeOfCard={"childcard"} />  &nbsp;
                                                                            </Typography>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                {childCard?.listName}  &nbsp;
                                                                            </Typography>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                <CustomLabel Estimated_Hours={card?.Estimated_Mech_Hours} child_cards={card?.child_cards} cardType={"Mechanical"} cardId={card?.trello_id} name={card?.card_title?.name} />
                                                                            </Typography>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                <WorkerRatingByTrade
                                                                                    threadNewWorker={card.new_Mec_Worker}
                                                                                    projectInfo={card}
                                                                                    cardType={"Mechanical"} />   &nbsp;
                                                                            </Typography>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                {card.new_Mec_manager ? card.new_Mec_manager.map((val) => val?.fullName).join(', ') : "-"}  &nbsp;
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                        );
                                                    }
                                                    if (childCard?.typeOfCard === "Electrical") {
                                                        return (
                                                            <Card key={childIndex} className={`${classes.scrollableCard} ${classes.electricalCard}`}>
                                                                <CardContent>
                                                                    <Typography variant="h1" className={classes.electricalCardTitle} style={{
                                                                        fontFamily: 'Poppins',
                                                                        fontSize: '18px',
                                                                        fontWeight: 400,
                                                                        lineHeight: '21px',
                                                                        letterSpacing: '0.03em',
                                                                        textAlign: 'center',
                                                                    }}>Electrical</Typography>
                                                                    <Grid container spacing={2} style={{ marginTop: '12px' }}>
                                                                        <Grid item xs={6}>
                                                                            <Typography className={classes.childcardText}>
                                                                                Label:
                                                                            </Typography>
                                                                            <Typography className={classes.childcardText}>
                                                                                Status:
                                                                            </Typography>
                                                                            <Typography className={classes.childcardText}>
                                                                                TIME:
                                                                            </Typography>
                                                                            <Typography className={classes.childcardText}>
                                                                                Worker:
                                                                            </Typography>
                                                                            <Typography className={classes.childcardText}>
                                                                                Manager:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                <WorkCardLabel
                                                                                    child_cards={card?.child_cards}
                                                                                    cardType={"Electrical"}
                                                                                    typeOfCard={"childcard"} /> &nbsp;
                                                                            </Typography>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                {childCard?.listName}  &nbsp;
                                                                            </Typography>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                <CustomLabel Estimated_Hours={card?.Estimated_Elec_Hours} child_cards={card?.child_cards} cardType={"Electrical"} cardId={card?.trello_id} name={card?.card_title?.name} />
                                                                            </Typography>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                <WorkerRatingByTrade
                                                                                    // threadWorker={originalRow.Plumbing_Last_Member}
                                                                                    threadNewWorker={card.new_Ele_Worker}
                                                                                    projectInfo={card}
                                                                                    cardType={"Electrical"} />  &nbsp;
                                                                            </Typography>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                {card.new_Ele_manager ? card.new_Ele_manager.map((val) => val?.fullName).join(', ') : "-"}  &nbsp;
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                        );
                                                    }
                                                    if (childCard?.typeOfCard === "Plumbing") {
                                                        return (
                                                            <Card key={childIndex} className={`${classes.scrollableCard} ${classes.plumbingCard}`}>
                                                                <CardContent>
                                                                    <Typography variant="h1" className={classes.plumbCardTitle} style={{
                                                                        fontFamily: 'Poppins',
                                                                        fontSize: '18px',
                                                                        fontWeight: 400,
                                                                        lineHeight: '21px',
                                                                        letterSpacing: '0.03em',
                                                                        textAlign: 'center',
                                                                    }}>Plumbing</Typography>
                                                                    <Grid container spacing={2} style={{ marginTop: '12px' }}>
                                                                        <Grid item xs={6}>
                                                                            <Typography className={classes.childcardText}>
                                                                                Label:
                                                                            </Typography>
                                                                            <Typography className={classes.childcardText}>
                                                                                Status:
                                                                            </Typography>
                                                                            <Typography className={classes.childcardText}>
                                                                                TIME:
                                                                            </Typography>
                                                                            <Typography className={classes.childcardText}>
                                                                                Worker:
                                                                            </Typography>
                                                                            <Typography className={classes.childcardText}>
                                                                                Manager:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={6}>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                <WorkCardLabel
                                                                                    child_cards={card?.child_cards}
                                                                                    cardType={"Plumbing"}
                                                                                    typeOfCard={"childcard"} />  &nbsp;
                                                                            </Typography>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                {childCard?.listName}  &nbsp;
                                                                            </Typography>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                <CustomLabel Estimated_Hours={card?.Estimated_Plum_Hours} child_cards={card?.child_cards} cardType={"Plumbing"} cardId={card?.trello_id} name={card?.card_title?.name} />
                                                                            </Typography>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                <WorkerRatingByTrade
                                                                                    className="WorkerManager"
                                                                                    // threadWorker={originalRow.Plumbing_Last_Member}
                                                                                    threadNewWorker={card.new_Plu_Worker}
                                                                                    projectInfo={card}
                                                                                    cardType={"Plumbing"} />  &nbsp;
                                                                            </Typography>
                                                                            <Typography className={classes.childcardData} style={{ display: 'flex' }}>
                                                                                {card.new_Plu_manager ? card.new_Plu_manager.map((val) => val?.fullName).join(', ') : "-"}  &nbsp;
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </CardContent>
                                                            </Card>
                                                        );
                                                    }
                                                    else {
                                                        return ("");
                                                    }
                                                    {/* return "No Childs card avilable"; */ }
                                                })}
                                            </Box>
                                        </Paper>
                                    ) : (
                                        <Typography>&nbsp;</Typography>
                                    )}
                                </CardContent>
                            </Collapse>
                        </Card>

                    ))
                ) : (
                    <>
                        <div className='p-2 m-2' style={{ border: '1px solid lightgray', borderRadius: '12px', width: '95%' }}>
                            <Skeleton width={700} />
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton width={150} /></div>
                        </div>
                        <div className='p-2 m-2' style={{ border: '1px solid lightgray', borderRadius: '12px', width: '95%' }}>
                            <Skeleton width={700} />
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton width={150} /></div>
                        </div>
                        <div className='p-2 m-2' style={{ border: '1px solid lightgray', borderRadius: '12px', width: '95%' }}>
                            <Skeleton width={700} />
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton width={150} /></div>
                        </div>
                        <div className='p-2 m-2' style={{ border: '1px solid lightgray', borderRadius: '12px', width: '95%' }}>
                            <Skeleton width={700} />
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton width={150} /></div>
                        </div>
                        <div className='p-2 m-2' style={{ border: '1px solid lightgray', borderRadius: '12px', width: '95%' }}>
                            <Skeleton width={700} />
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton width={450} />&nbsp;&nbsp;<Skeleton width={450} /></div>
                            <div className="d-flex ps-2"><Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton style={{ width: '3%', borderRadius: '50%' }} />&nbsp;<Skeleton width={150} /></div>
                        </div>
                    </>
                )
            )}

        </div>
    );
});
export default MobileCards;