import React from 'react';

const ColoredBadge = ({ totalHours }) => {
  const getColorByRange = (value) => {
    if (value >= 0 && value <= 15) {
      return 'green';
    } else if (value >= 16 && value <= 25) {
      return '#cd9f21';
    } else if (value > 25) {
      return '#ff5252';
    } else {
      return 'white';
    }
  };

  const color = getColorByRange(totalHours);

  const getStylesByColor = (color) => {
    if (color === 'green' || color === '#ff5252') {
      return {
        color: 'white',
        fontWeight: 800,
        width: '60%'
      };
    } else if (color === '#cd9f21') {
      return {
        color: 'black',
        fontWeight: 800,
        width: '60%'
      };
    } else {
      return {
        color: 'black',
        width: '60%'
      };
    }
  };

  const styles = getStylesByColor(color);

  return (
    <p
      className={`text-truncate custom-status mt-2`}
      title={`Total Hours: ${totalHours}`}
      style={{
        backgroundColor: color,
        ...styles, 
      }}
    >
      {totalHours || 0}
    </p>
  );
};

export default ColoredBadge;
