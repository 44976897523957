import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import TableServices from "services/table";
import { DateTimePicker } from "@material-ui/pickers";

const CustomFields = ({ trelloCardId }) => {
    const { allCustomFeilds } = useSelector(({ table }) => table);
    const [customFieldData, setCustomFieldData] = useState({});
    const dispatch = useDispatch();
    const customFieldConstants = {
        PrelimDue: "5ae90620fed4949f8d12f2bc",
        FinalDue: "5ae9063d319565763e1d317c",
        Promise: "62469021218f266c2509b5d1",
        Project: "5ed925f0b84c9c17935946b7",
        ARCHITECT: "5b478ac2ef63c157e475fc5f",
        Folder: "5b906d35f8ca7235d59ba523",
        Voltage: "5c09696e7df63d8046f7bf9a",
        DuctSmoke: "5db0bf998698f25928f2b473",
        FireSmoke: "62e9a15c64e7446d26bf2334",
        GasAvailabilty: "62e9a1c0c1839930a24e99ac",
        SiteVisit: "5e5d83205c86dd5c74cd5576",
        PromisedDate: "6364251fa398e700673fea40",
        SiteVisitDone: "5e5d8335f53e9e1a28bb692f",
        Architect: "6407bed858694856e146855a",
        Quote: "626b3166a66e0c3789153623",
        InitialNeedInfo: "6298eef0ffb0d25fb6cbc6fc",
        QuoteDate: "62bf187b1427720ed6fc898c",
        TimeEstimate: "6246946e2112682a5ac40586",
        NotApproved: "62c485d8ab824d21e0d166c1",
        ProjectType: "62df88b14ce0ee4511240cfe",
        IPRDue: "64d9c75c04067e56d00e435d",
        Personal: "62469021218f266c2509b5d2",
        Customer: "62469021218f266c2509b5d3",
        Yes: "62e9a1c0c1839930a24e99ad",
        No: "62e9a1c0c1839930a24e99ae",
        Full: "62df88b28e9f9763e0da209d",
        Prelim: "62df88b222b4c4132c976881",
        T24: "62df88b2f5416a5dc7123709",
        Revision: "62df88b3cd63c914723cc749",
        PlanCheck: "62df88b3783e341f78fa11e1",
        PlanCheckRevision: "62df88b42172f648af8e77d4",
        FullPlanCheck: "62df88b46df66513b8bfbec3",
        FullRevision: "62e94a1dcfa26c8c0e91c52c",
        Rescheck: "62e30fbb5c90cd17ce72d1b1",
        ComCheck: "62e30fbf6b50565f227dfc52",
        WEC: "62e30fc236d48c5fe40cbaf3",
        IPR: "64d339264df95fe36a98339e",
        empty: "",
    };
    const setCustomFieldValues = () => {
        const newData = {};
        if (Array.isArray(allCustomFeilds.data)) {
            allCustomFeilds.data.forEach(field => {
                const { idCustomField, value, idValue } = field;
                for (const key in customFieldConstants) {
                    if (idCustomField === customFieldConstants[key]) {
                        if (value !== null && value !== undefined) {
                            if (value.number) {
                                newData[key] = value.number;
                            } else if (value.text) {
                                newData[key] = value.text;
                            } else if (value.date) {
                                newData[key] = value.date;
                            } else if (value.checked !== undefined) {
                                newData[key] = value.checked === "true";
                            }
                        }
                        break;
                    }
                    if (idCustomField === customFieldConstants.Promise) {
                        if (idValue === customFieldConstants.Personal) {
                            newData.Promise = "Personal";
                        } else if (idValue === customFieldConstants.Customer) {
                            newData.Promise = "Customer";
                        }
                        else if (idValue === customFieldConstants.empty) {
                            newData.Promise = "--";
                        }
                    }
                    if (idCustomField === customFieldConstants.GasAvailabilty) {
                        if (idValue === customFieldConstants.Yes) {
                            newData.GasAvailabilty = "Yes";
                        } else if (idValue === customFieldConstants.No) {
                            newData.GasAvailabilty = "No";
                        }  else if (idValue === customFieldConstants.empty) {
                            newData.GasAvailabilty = "--";
                        }
                    }
                    if (idCustomField === customFieldConstants.ProjectType) {
                        if (idValue === customFieldConstants.Full) {
                            newData.ProjectType = "Full";
                        } else if (idValue === customFieldConstants.Prelim) {
                            newData.ProjectType = "Prelim";
                        } else if (idValue === customFieldConstants.T24) {
                            newData.ProjectType = "T-24 ";
                        } else if (idValue === customFieldConstants.Revision) {
                            newData.ProjectType = "Revision";
                        } else if (idValue === customFieldConstants.PlanCheck) {
                            newData.ProjectType = "Plan Check ";
                        } else if (idValue === customFieldConstants.PlanCheckRevision) {
                            newData.ProjectType = "Plan Check/Revision";
                        } else if (idValue === customFieldConstants.FullPlanCheck) {
                            newData.ProjectType = "Full/Plan Check";
                        } else if (idValue === customFieldConstants.FullRevision) {
                            newData.ProjectType = "Full/Revision";
                        } else if (idValue === customFieldConstants.Rescheck) {
                            newData.ProjectType = "Rescheck";
                        } else if (idValue === customFieldConstants.ComCheck) {
                            newData.ProjectType = "ComCheck";
                        } else if (idValue === customFieldConstants.WEC) {
                            newData.ProjectType = "WEC";
                        } else if (idValue === customFieldConstants.IPR) {
                            newData.ProjectType = "IPR";
                        }
                        else if (idValue === customFieldConstants.empty) {
                            newData.ProjectType = "--";
                        }
                    }
                }
            });
        }
        setCustomFieldData(newData);
    };
    useEffect(() => {
        setCustomFieldValues();
    }, [allCustomFeilds.data]);
    const handleFieldChange = (fieldName, editedValue) => {
        setCustomFieldData((prevData) => ({
            ...prevData,
            [fieldName]: editedValue,
        }));
        const updateCustomFields = {
            cardID: trelloCardId,
            customFieldID: customFieldConstants[fieldName],
            value: {
                text: editedValue,
            },
        };

        dispatch(TableServices.updateCustomFields(updateCustomFields));
    };
    const handleNumberChange = (fieldName, editedValue) => {
        setCustomFieldData((prevData) => ({
            ...prevData,
            [fieldName]: editedValue,
        }));
        const updateCustomFields = {
            cardID: trelloCardId,
            customFieldID: customFieldConstants[fieldName],
            value: {
                number: editedValue,
            },
        };
        dispatch(TableServices.updateCustomFields(updateCustomFields));
    };
    const handleCheckChange = (fieldName, checked) => {
        setCustomFieldData((prevData) => ({
            ...prevData,
            [fieldName]: checked,
        }));
        const updateCustomFields = {
            cardID: trelloCardId,
            customFieldID: customFieldConstants[fieldName],
            value: {
                checked: checked.toString(),
            },
        };
        dispatch(TableServices.updateCustomFields(updateCustomFields));
    };
    const handleDropdownChange = (fieldName, selectedValue) => {
        const idValue = customFieldConstants[selectedValue];

        setCustomFieldData((prevData) => ({
            ...prevData,
            [fieldName]: selectedValue,
        }));

        const updateDropdown = {
            cardID: trelloCardId,
            customFieldID: customFieldConstants[fieldName],
            value: idValue,
        };

        dispatch(TableServices.updateDropdown(updateDropdown));
    };


    const handlePrelimDueChange = (newDate) => {
        if (newDate) {
            const updateCustomFields = {
                cardID: trelloCardId,
                customFieldID: customFieldConstants.PrelimDue,
                value: {
                    date: newDate.toISOString(),
                }
            }
            dispatch(TableServices.updateCustomFields(updateCustomFields));
        } else {
            const updateCustomFields = {
                cardID: trelloCardId,
                customFieldID: customFieldConstants.PrelimDue,
                value: '',
            }
            dispatch(TableServices.updateCustomFields(updateCustomFields));
        }
        // const updateCustomFields = {
        //     cardID: trelloCardId,
        //     customFieldID: customFieldConstants.PrelimDue,
        //     value: {
        //         date: newDate.toISOString(),
        //     },
        // };
        // dispatch(TableServices.updateCustomFields(updateCustomFields));
    };
    const handleFinalDueChange = (newDate) => {
        if (newDate) {
            const updateCustomFields = {
                cardID: trelloCardId,
                customFieldID: customFieldConstants.FinalDue,
                value: {
                    date: newDate.toISOString(),
                }
            }
            dispatch(TableServices.updateCustomFields(updateCustomFields));
        } else {
            const updateCustomFields = {
                cardID: trelloCardId,
                customFieldID: customFieldConstants.FinalDue,
                value: '',
            }
            dispatch(TableServices.updateCustomFields(updateCustomFields));
        }
        // const updateCustomFields = {
        //     cardID: trelloCardId,
        //     customFieldID: customFieldConstants.FinalDue,
        //     value: {
        //         date: newDate.toISOString(),
        //     },
        // };
        // dispatch(TableServices.updateCustomFields(updateCustomFields));
    };
    const handleQuoteDateChange = (newDate) => {
        if (newDate) {
            const updateCustomFields = {
                cardID: trelloCardId,
                customFieldID: customFieldConstants.QuoteDate,
                value: {
                    date: newDate.toISOString(),
                }
            }
            dispatch(TableServices.updateCustomFields(updateCustomFields));
        } else {
            const updateCustomFields = {
                cardID: trelloCardId,
                customFieldID: customFieldConstants.QuoteDate,
                value: '',
            }
            dispatch(TableServices.updateCustomFields(updateCustomFields));
        }
        // const updateCustomFields = {
        //     cardID: trelloCardId,
        //     customFieldID: customFieldConstants.QuoteDate,
        //     value: {
        //         date: newDate.toISOString(),
        //     },
        // };
        // dispatch(TableServices.updateCustomFields(updateCustomFields));
    }
    const handleIPRDueChange = (newDate) => {
        if (newDate) {
            const updateCustomFields = {
                cardID: trelloCardId,
                customFieldID: customFieldConstants.IPRDue,
                value: {
                    date: newDate.toISOString(),
                }
            }
            dispatch(TableServices.updateCustomFields(updateCustomFields));
        } else {
            const updateCustomFields = {
                cardID: trelloCardId,
                customFieldID: customFieldConstants.IPRDue,
                value: '',
            }
            dispatch(TableServices.updateCustomFields(updateCustomFields));
        }
    }
    return (

        <>
            <div className='container mt-3' style={{ marginLeft: '40px', width: '95%' }}>
                <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                        <label>Prelim Due</label><br />
                        <DateTimePicker
                            value={customFieldData?.PrelimDue ? new Date(customFieldData?.PrelimDue) : null}
                            dateFormat="dd MMM yyyy hh:mm:ss"
                            className="form-control"
                            wrapperClassName="custom-wrapper pr-0"
                            isClearable
                            onChange={handlePrelimDueChange}
                            style={{border:"1px solid #d8d8d8", borderRadius:"5px", padding:'2px'}}
                        />
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Final Due</label>
                        <DateTimePicker
                            value={customFieldData?.FinalDue ? new Date(customFieldData?.FinalDue) : null}
                            dateFormat="dd MMM yyyy"
                            className="form-control"
                            wrapperClassName="custom-wrapper pr-0"
                            isClearable
                            onChange={handleFinalDueChange}
                            style={{border:"1px solid #d8d8d8", borderRadius:"5px", padding:'2px'}}
                        />
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Promise</label>
                        <select
                            className="form-control"
                            value={customFieldData?.Promise || ''}
                            onChange={(e) => handleDropdownChange("Promise", e.target.value)}
                        >
                            <option value="empty">--</option>
                            <option value="Personal">Personal</option>
                            <option value="Customer">Customer</option>
                        </select>
                        {/* <input className="form-control" type="text" value={customFieldData?.Promise || ''} disabled readOnly /> */}
                    </div>
                </div>
            </div>
            <div className='container mt-3' style={{ marginLeft: '40px', width: '95%' }}>
                <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                        <label>Project #</label>
                        <input className="form-control" type="text" value={customFieldData?.Project || ''} onChange={(e) => handleFieldChange("Project", e.target.value)} />
                        {/* <input className="form-control" type="text" value={customFieldData?.Project || ''} disabled readOnly /> */}
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>ARCHITECT</label>
                        <input className="form-control" type="text" value={customFieldData?.ARCHITECT || ''} onChange={(e) => handleFieldChange("ARCHITECT", e.target.value)} />
                        {/* <input className="form-control" type="text" value={customFieldData?.ARCHITECT || ''} disabled readOnly /> */}
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Folder #</label>
                        <input className="form-control" type="number" value={customFieldData?.Folder || ''} onChange={(e) => handleNumberChange("Folder", e.target.value)} />
                        {/* <input className="form-control" type="number" value={customFieldData?.Folder || ''} disabled readOnly /> */}
                    </div>
                </div>
            </div>
            <div className='container mt-3' style={{ marginLeft: '40px', width: '95%' }}>
                <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                        <label>Voltage</label>
                        <input className="form-control" type="number" value={customFieldData?.Voltage || ''} onChange={(e) => handleFieldChange("Voltage", e.target.value)} />
                        {/* <input className="form-control" type="text" value={customFieldData?.Voltage || ''} disabled readOnly /> */}
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Duct Smoke Detector Qty</label>
                        <input className="form-control" type="number" value={customFieldData?.DuctSmoke || ''} onChange={(e) => handleNumberChange("DuctSmoke", e.target.value)} />
                        {/* <input className="form-control" type="text" value={customFieldData?.DuctSmoke || ''} disabled readOnly /> */}
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Fire/Smoke Dampers</label>
                        <input className="form-control" type="number" value={customFieldData?.FireSmoke || ''} onChange={(e) => handleNumberChange("FireSmoke", e.target.value)} />
                        {/* <input className="form-control" type="text" value={customFieldData?.FireSmoke || ''} disabled readOnly /> */}
                    </div>
                </div>
            </div>
            <div className='container mt-3' style={{ marginLeft: '40px', width: '95%' }}>
                <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                        <label>Gas Availabilty</label>
                        <select
                            className="form-control"
                            value={customFieldData?.GasAvailabilty || ''}
                            onChange={(e) => handleDropdownChange("GasAvailabilty", e.target.value)}
                        >
                            <option value="empty">--</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                        </select>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Site Visit Included</label><br />
                        <input className="form-check-input" type="checkbox" style={{ color: '#006D8F' }} checked={customFieldData.SiteVisit || false} onChange={(e) => handleCheckChange("SiteVisit", e.target.checked)} />
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Promised Date</label>
                        <input className="form-control" type="text" value={customFieldData?.PromisedDate || ''} onChange={(e) => handleFieldChange("PromisedDate", e.target.value)} />
                        {/* <input className="form-control" type="text" value={customFieldData?.PromisedDate || ''} disabled readOnly /> */}
                    </div>
                </div>
            </div>
            <div className='container mt-3' style={{ marginLeft: '40px', width: '95%' }}>
                <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                        <label>Site Visit Completed</label><br />
                        <input className="form-check-input" type="checkbox" style={{ color: '#006D8F' }} checked={customFieldData.SiteVisitDone || false} onChange={(e) => handleCheckChange("SiteVisitDone", e.target.checked)} />
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Architect</label>
                        <input className="form-control" type="text" value={customFieldData?.Architect || ''} onChange={(e) => handleFieldChange("Architect", e.target.value)} />
                        {/* <input className="form-control" type="text" value={customFieldData?.Architect || ''} disabled readOnly /> */}
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Quote #</label>
                        <input className="form-control" type="number" value={customFieldData?.Quote || ''} onChange={(e) => handleNumberChange("Quote", e.target.value)} />
                        {/* <input className="form-control" type="number" value={customFieldData?.Quote || ''} disabled readOnly /> */}
                    </div>
                </div>
            </div>
            <div className='container mt-3' style={{ marginLeft: '40px', width: '95%' }}>
                <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                        <label>Initial Need Info Request</label><br />
                        <input className="form-check-input" type="checkbox" style={{ color: '#006D8F' }} checked={customFieldData?.InitialNeedInfo || false} onChange={(e) => handleCheckChange("InitialNeedInfo", e.target.checked)} />
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Quote Signed Date</label>
                        <DateTimePicker
                            value={customFieldData?.QuoteDate ? new Date(customFieldData?.QuoteDate) : null}
                            dateFormat="dd MMM yyyy"
                            className="form-control"
                            wrapperClassName="custom-wrapper pr-0"
                            isClearable
                            onChange={handleQuoteDateChange}
                            style={{border:"1px solid #d8d8d8", borderRadius:"5px", padding:'2px'}}
                        />
                        {/* <input className="form-control" type="text" value={customFieldData?.QuoteDate || ''} disabled readOnly /> */}
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Time Estimate</label>
                        <input className="form-control" type="number" value={customFieldData?.TimeEstimate || ''} onChange={(e) => handleNumberChange("TimeEstimate", e.target.value)} />
                        {/* <input className="form-control" type="text" value={customFieldData?.TimeEstimate || ''} disabled readOnly /> */}
                    </div>
                </div>
            </div>
            <div className='container mt-3' style={{ marginLeft: '40px', width: '95%' }}>
                <div className='row'>
                    <div className='col-md-4 col-sm-12'>
                        <label>Not Approved</label><br />
                        <input className="form-check-input" type="checkbox" style={{ color: '#006D8F' }} checked={customFieldData?.NotApproved || false} onChange={(e) => handleCheckChange("NotApproved", e.target.checked)} />
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>Project Type</label>
                        <select
                            className="form-control"
                            value={customFieldData.ProjectType || ""}
                            onChange={(e) => handleDropdownChange("ProjectType", e.target.value)}
                        >
                            <option value="empty">--</option>
                            <option value="Full">Full</option>
                            <option value="Prelim">Prelim</option>
                            <option value="T24">T-24</option>
                            <option value="IPR">IPR</option>
                            <option value="Revision">Revision</option>
                            <option value="PlanCheck">Plan Check</option>
                            <option value="FullPlanCheck">Full/Plan Check</option>
                            <option value="FullRevision">Full/Revision</option>
                            <option value="Rescheck">Rescheck</option>
                            <option value="ComCheck">Comcheck</option>
                            <option value="WEC">WEC</option>
                            <option value="IPR">IPR</option>
                        </select>
                    </div>
                    <div className='col-md-4 col-sm-12'>
                        <label>IPR Due Date</label>
                        <DateTimePicker
                            value={customFieldData?.IPRDue ? new Date(customFieldData?.IPRDue) : null}
                            dateFormat="dd MMM yyyy"
                            className="form-control"
                            wrapperClassName="custom-wrapper pr-0"
                            isClearable
                            onChange={handleIPRDueChange}
                            style={{border:"1px solid #d8d8d8", borderRadius:"5px", padding:'2px'}}
                        />
                        {/* <input className="form-control" type="text" value={customFieldData?.IPRDue || ''} disabled readOnly /> */}
                    </div>
                </div>
            </div>
        </>
    )
}
export default CustomFields;