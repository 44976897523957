import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core/styles";
import InfoIcon from "@material-ui/icons/Info";
import StopIcon from "@material-ui/icons/Stop";
import * as React from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px 10px",
    borderRadius: 5,
    border: "1px solid #3F7786;",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);",
  },
}));

export default function ProjectCardColor() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        aria-label="delete"
        title="Project Card Colors"
        className="icon-class"
        onClick={handleClick}
        style={{ padding: 8 }}
      >
        <InfoIcon style={{ fill: "#006d8f", height:'17px' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={9}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        <FormControl component="fieldset" className="settings-menu">
          <FormGroup aria-label="position" row={false}>
            <p className="label mb-0">PrelimDate & FinalDate</p>
            <div
              style={{ justifyContent: "space-between", alignItems: "center" }}
              className="mt-1"
            >
              <div style={{ display: "flex",justifyContent: "space-between"}}>
                <div className="notification-desc">
                  PrelimDate and finalDate not present.
                </div>
                <StopIcon style={{ fill: "#DCC9B8" }} className="label-img" />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="mt-2"
            >
              <div className="notification-desc">
                PrelimDate or finalDate is present
              </div>
              <StopIcon style={{ fill: "#58B000" }} className="label-img" />
            </div>
            
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="mt-3"
            >
              <p className="label mb-0">Hour A Day</p>
              <StopIcon style={{ fill: "#CEE8FF" }} className="label-img" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="mt-2"
            >
              <p className="label mb-0">On Hold</p>
              <StopIcon style={{ fill: "#A020F0" }} className="label-img" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="mt-2"
            >
              <p className="label mb-0">BlockedByInfo</p>
              <StopIcon style={{ fill: "#ff7676" }} className="label-img" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="mt-2"
            >
              <p className="label mb-0">PS</p>
              <StopIcon style={{ fill: "#f587cb" }} className="label-img" />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              className="mt-2"
            >
              <p className="label mb-0">BC</p>
              <StopIcon style={{ fill: "#f5d2e7" }} className="label-img" />
            </div>
          </FormGroup>
        </FormControl>
      </Popover>
    </div>
  );
}
