import React, { useEffect, useState } from "react";
import { AppTheme } from "theme/customeTheme";
import { trelloLabels } from "theme/trelloLabels";

const MobileCustomBadge = ({
    name,
    child_cards,
    labelStatus,
    cardId,
    promise,
    PrelimDate,
    FinalDate,
}) => {
    const [labelBackgroundColor, setLabelBackgroundColor] = useState();
    const [labelBorderColor, setLabelBorderColor] = useState();
    useEffect(() => {
        if (labelStatus?.includes(trelloLabels.labelName.IPR)) {
            setLabelBorderColor("4px solid #6b1987");
        }
    }, [labelStatus]);
    const getGradientColor = () => {
        let color1 = "white";
        let color2 = "white";
        let color3 = "white";
        let color4 = "white";
        let color5 = "white";
        let color6 = "white";
        let color7 = "white";

        if (promise !== null && (PrelimDate !== null || FinalDate !== null)) {
            //Green
            color1 = "#58B000";
        }
        if (promise !== null && PrelimDate == null && FinalDate == null) {
            //light brown
            color1 = "#DCC9B8";
        }
        if (promise === null) {
            //light brown
            color1 = "#DCC9B8";
        }
        if (labelStatus?.includes(trelloLabels.labelName.OnHold)) { //purple
            color4 = "#A020F0";
        }
        if (labelStatus?.includes(trelloLabels.labelName.HourADay)) { //blue
            color2 = "#CEE8FF";
        }
        if (labelStatus?.includes(trelloLabels.labelName.BlockedByInfo)) { //red
            color3 = "#ff7676";
        }
        if (labelStatus?.includes(trelloLabels.labelName.PS)) {
            // pink
            color5 = "#f587cb";
        }
        if (labelStatus?.includes(trelloLabels.labelName.BC)) {
            // pink
            color6 = "#f5d2e7";
        }


        return {
            background: `linear-gradient(to right,  ${color1} 0%, ${color1} 16.67%, ${color2} 16.67%, ${color2} 33.33%, ${color3} 33.33%, ${color3} 50%, ${color4} 50%, ${color4} 66.67% ,${color5} 66.67%, ${color5} 83.33%, ${color6} 83.33%, ${color6} 100%)`,

        };
    };

    return (
        <div style={{ ...getGradientColor(), border: labelBorderColor, width: '42px', height: '18px', marginLeft: '86%', marginTop: '-16px' }}
            cardId={cardId}>

            &nbsp;
        </div>
    );
};

export default MobileCustomBadge;
