import StarIcon from "@material-ui/icons/Star";
import { Box } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { AppTheme } from "theme/customeTheme";
import { useState, useEffect } from "react";
import { formatDistanceToNow } from "date-fns";
import moment from "moment";

const Sidebar = ({ sideBarTitleInfo, sideBarInfo, workerName }) => {
  const [lastUpdatedTwoWeeksAgo, setlastUpdatedTwoWeeksAgo] = useState(false);
  useEffect(() => {
    const todayDate = new Date();
    let days = moment(todayDate).diff(moment(sideBarInfo?.updatedAt), "days", false);
    
    if(days<=14){
        setlastUpdatedTwoWeeksAgo(true)
    }else{
        setlastUpdatedTwoWeeksAgo(false)
    }
    
  }, [sideBarInfo?.updatedAt]);
  return (
    <Box pl={4} className="comments-sidebar">
      <Box textAlign={"center"} mb={"42px"}>
        <Button
          variant="contained"
          className="sidebar-button"
          style={{ backgroundColor: AppTheme.colors.primary }}
        >
          {sideBarTitleInfo}
        </Button>
      </Box>
      <Typography variant="h2" className="label" gutterBottom>
        Project Name:
      </Typography>
      <Typography variant="h3" className="address font-weight-normal">
        {sideBarInfo?.card_title?.name}
      </Typography>
      <Typography variant="h2" className="label">
        Worker :{" "}
        <span className="font-weight-normal">{workerName.fullName}</span>
      </Typography>
      <div variant="h2" className="label mt-3" style={{ display: "flex"}}>
        UpdatedAt: </div><span className="font-weight-normal mt-0" style={{ display: "flex"}}>{moment(sideBarInfo?.updatedAt).format("DD MMM yyyy hh:mm:a")}
        <StarIcon
        style={lastUpdatedTwoWeeksAgo ? { color: '#cd9f21' } : {color: '#006d8f'}}
          className={lastUpdatedTwoWeeksAgo ? "" : "yellow-star"} 
        /></span>
      {/* </div> */}
    </Box>
  );
};

export default Sidebar;
