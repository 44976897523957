import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Popover,
  Tab,
  Tabs,
  Tooltip,
  useTheme
} from "@material-ui/core";
import { alpha } from "@material-ui/core/styles";
import NotificationsIcon from "@material-ui/icons/Notifications";
// import NotificationsIcon from "../../../../../../../public/images/notification.png";
import CmtCardHeader from "../../../../../../@coremat/CmtCard/CmtCardHeader";
import CmtCardContent from "../../../../../../@coremat/CmtCard/CmtCardContent";
import CmtList from "../../../../../../@coremat/CmtList";
import CmtCard from "../../../../../../@coremat/CmtCard";

// import { getCustomDateTime, getNewDate } from '../../../@jumbo/utils/dateHelper';
import NotificationItem from "./NotificationItem";
import PerfectScrollbar from "react-perfect-scrollbar";
import clsx from "clsx";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import { getCustomDateTime, getNewDate } from "@jumbo/utils/dateHelper";
import CmtAvatar from "@coremat/CmtAvatar";
import GridContainer from "@jumbo/components/GridContainer";
import { useDispatch, useSelector } from "react-redux";
import DashboardServices from "services/dashboard";
import InfiniteScroll from "react-infinite-scroller";
import Axios from "services/config";
import { clearNotificationData } from "../../../../../../redux/actions/Dashboard.action"
import CommentsDialogBox from "@jumbo/components/TableComponents/Comments/CommentsDialogBox";
import { BrowserView, MobileView } from "react-device-detect";

const useStyles = makeStyles(theme => ({
  cardRoot: {
    "& .Cmt-header-root": {
      paddingTop: 4,
      paddingBottom: 4,
      backgroundColor: "#006D8F",
    },
    "& .Cmt-header-content .Cmt-title": {
      color: "#ffffff !important",
      fontSize: 25,
      fontFamily: "poppins",
      fontWeight: 600,
      padding: "5px 0px"
    },
    "& .Cmt-card-content": {
      padding: "7px 16px 7px 16px !important"
    }
  },
  typography: {
    padding: theme.spacing(2)
  },
  iconRoot: {
    position: "relative",
    color: alpha(theme.palette.common.white, 0.38),
    "&:hover, &.active": {
      color: theme.palette.common.white
    },
    marginTop: 20,
  },
  counterRoot: {
    color: theme.palette.common.white,
    border: `solid 1px ${theme.palette.common.white}`,
    backgroundColor: theme.palette.warning.main,
    width: 20
  },
  scrollbarRoot: {
    height: 300,
    padding: 16
  },
  popoverRoot: {
    "& .MuiPopover-paper": {
      width: 450,
      borderRadius: "15px 15px 0px 0px"
    }
  }
}));

const HeaderNotifications = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fetching, setFetching] = useState(true);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [mention, setMention] = React.useState(false);
  const [notificationType, setNotificationType] = React.useState('NEW_PROJECT');
  const theme = useTheme();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ auth }) => auth);
  const { notificationData, notificationList } = useSelector(({ dashboard }) => dashboard);
  React.useEffect(() => {
    dispatch(DashboardServices.getNotificationData(page, limit, notificationType));
  }, [notificationType])
  const onOpenPopOver = event => {
    setAnchorEl(event.currentTarget);
  };
  const onClosePopOver = () => {
    setAnchorEl(null);
  };
  const notificationHandleChange = (event, value) => {
    setNotificationType(value);
    dispatch(clearNotificationData());
  };
  const handleDialogBox = (openDialog, item) => {
    setOpenDialog(openDialog);
    setMention(item);
  };
  useEffect(() => {
    setNotificationOpen(true)
    return function clean() {
      dispatch(clearNotificationData());
      setPage(1);
    };
  }, []);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const loader = (
    <div key="loader" className="loader">
      Loading ...
    </div>
  );
  React.useEffect(() => {
    setFetching(false);
  }, [notificationList])
  const getThreadMessages = () => {
    setFetching(true);
    if (notificationData?.totalItems !== notificationList.length && fetching == false) {
      setPage(page + 1);
      dispatch(DashboardServices.getNotificationData(page + 1, limit, notificationType));
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <BrowserView>
        <Box display="flex" alignItems="center" pr={2}>
          <Tooltip title="Notifications">
            <IconButton
              onClick={onOpenPopOver}
              className={clsx(classes.iconRoot, "Cmt-appIcon", {
                active: notificationData?.totalItems > 0
              })}
            >
              <Badge badgeContent={notificationData?.totalItems} classes={{ badge: classes.counterRoot }} overlap="rectangular">
                {/* <NotificationsIcon /> */}
                <img src="/images/notification.png" className={classes.loginSideImg2} />
              </Badge>
            </IconButton>
          </Tooltip>
          <Box display="flex" alignItems="center" paddingTop="15px">
            <Typography
              component="h3"
              variant="h6"
              style={{
                color: "#000000",
                fontSize: 20,
                paddingRight: 10
              }}
            >
              | {authUser?.fullName}
            </Typography>
            {/* <CmtAvatar size={35} /> */}
            <CmtAvatar size={35} style={{ backgroundColor: "#006D8F", fontSize: "13px" }}>{authUser?.innitials}</CmtAvatar>
          </Box>
          <Popover
            className={classes.popoverRoot}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClosePopOver}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <CmtCard className={classes.cardRoot}>
              <CmtCardHeader
                title="Notifications"
                actionsPos="top-corner"
                // actions={actions}
                separator={{
                  color: theme.palette.borderColor.dark,
                  borderWidth: 1,
                  borderStyle: "solid"
                }}
              />
              <CmtCardContent>
                {/* <div>Working Notifications</div> */}
                <Tabs
                  value={notificationType}
                  onChange={notificationHandleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab value="NEW_PROJECT" label="New Project" />
                  <Tab value="TAGGED" label="Tagged" />
                </Tabs>
                {
                  notificationOpen == true &&
                  <div
                    id="notificationDiv"
                    style={{
                      // display: 'flex',
                      height: "450px !important",
                    }}
                    className="comments-section"
                  >
                    <InfiniteScroll
                      loadMore={() => getThreadMessages()}
                      hasMore={notificationData?.totalItems ? notificationData?.totalItems !== notificationList.length ? true : false : false}
                      loader={loader}
                      useWindow={false}
                      threshold={0.8}
                      scrollabletarget="notificationDiv"
                      style={{ height: "450px !important" }}
                    >
                      {notificationList?.length > 0 ? (
                        // <PerfectScrollbar className={classes.scrollbarRoot}>
                        <CmtList
                          data={notificationList}
                          renderRow={(item, index) => (
                            <NotificationItem onClose={onClosePopOver} notificationType={notificationType} page={page} key={index} item={item} handleDialogBox={handleDialogBox} />
                          )}
                        />
                        // </PerfectScrollbar>
                      ) : (
                        <Box p={6}>
                          <Typography variant="body2">No notifications found</Typography>
                        </Box>
                      )}
                    </InfiniteScroll>
                  </div>
                }

              </CmtCardContent>
            </CmtCard>
          </Popover>
          <CommentsDialogBox openDialog={openDialog} projectInfo={mention?.projectCardId} commentSection={mention?.messageId?.thread} handleCloseDialog={handleCloseDialog} />
        </Box>
      </BrowserView>
      <MobileView>
        <Box display="flex" alignItems="center" pr={2}>
          <h2 className="text-dark" style={{ marginRight: '45px', position: 'inherit', marginTop: '10px', fontFamily: 'Nobile', fontSize: '18px', fontWeight: '400' }}>PRIORITY SHEETS</h2>
          <img src="/apple-icon-60x60.png"></img>
        </Box>
        
        {/* <Box display="flex" style={{top:'-23px', position:'relative'}} >
          <Tooltip title="Notifications">
            <IconButton
              onClick={onOpenPopOver}
              className={clsx(classes.iconRoot, "Cmt-appIcon", {
                active: notificationData?.totalItems > 0
              })}
            >
              <Badge badgeContent={notificationData?.totalItems} classes={{ badge: classes.counterRoot }}>
                <img src="/images/notification.png" className={classes.loginSideImg2} />
              </Badge>
            </IconButton>
          </Tooltip>
          <Box display="flex" alignItems="center" paddingTop="15px">

            <CmtAvatar size={35} style={{ backgroundColor: "#006D8F", fontSize: "13px" }}>{authUser?.innitials}</CmtAvatar>
          </Box>
          <Popover
            className={classes.popoverRoot}
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClosePopOver}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >

            <CmtCard className={classes.cardRoot}>
              <CmtCardHeader
                title="Notifications"
                actionsPos="top-corner"
                // actions={actions}
                separator={{
                  color: theme.palette.borderColor.dark,
                  borderWidth: 1,
                  borderStyle: "solid"
                }}
              />
              <CmtCardContent>
                <Tabs
                  value={notificationType}
                  onChange={notificationHandleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <Tab value="NEW_PROJECT" label="New Project" />
                  <Tab value="TAGGED" label="Tagged" />
                </Tabs>
                {
                  notificationOpen == true &&
                  <div
                    id="notificationDiv"
                    style={{
                      // display: 'flex',
                      height: "450px !important",
                    }}
                    className="comments-section"
                  >
                    <InfiniteScroll
                      loadMore={() => getThreadMessages()}
                      hasMore={notificationData?.totalItems ? notificationData?.totalItems !== notificationList.length ? true : false : false}
                      loader={loader}
                      useWindow={false}
                      threshold={0.8}
                      scrollabletarget="notificationDiv"
                      style={{ height: "450px !important" }}
                    >
                      {notificationList?.length > 0 ? (
                        // <PerfectScrollbar className={classes.scrollbarRoot}>
                        <CmtList
                          data={notificationList}
                          renderRow={(item, index) => (
                            <NotificationItem onClose={onClosePopOver} notificationType={notificationType} page={page} key={index} item={item} handleDialogBox={handleDialogBox} />
                          )}
                        />
                        // </PerfectScrollbar>
                      ) : (
                        <Box p={6}>
                          <Typography variant="body2">No notifications found</Typography>
                        </Box>
                      )}
                    </InfiniteScroll>
                  </div>
                }

              </CmtCardContent>
            </CmtCard>
          </Popover>
          <CommentsDialogBox openDialog={openDialog} projectInfo={mention?.projectCardId} commentSection={mention?.messageId?.thread} handleCloseDialog={handleCloseDialog} />
        </Box> */}
      </MobileView>
    </>
  );
};

export default HeaderNotifications;
