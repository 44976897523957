import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TableServices from 'services/table';

const CommentInput = ({ originalRow }) => {
  const dispatch = useDispatch();
  const [comment, setComment] = useState(originalRow?.client_comment || "");

  useEffect(() => {
    setComment(originalRow?.client_comment || "");
  }, [originalRow?.client_comment]);

  const handleTextAreaChange = (event) => {
    setComment(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      updateComment();
    }
  };

  const updateComment = () => {
    const updateCommentPayload = {
      comment_id: originalRow.comment_id,
      cmtvalue: comment,
    };
    dispatch(TableServices.userCommentInFrontApp(updateCommentPayload));
  };

  return (
    <textarea
      style={{
        width: "-webkit-fill-available",
        padding: '5px',
        marginTop: '5px',
        borderRadius: '5px'
      }}
      maxRows={2}
      aria-label="maximum height"
      placeholder="Enter Comments here."
      value={comment}
      onChange={handleTextAreaChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default CommentInput;
