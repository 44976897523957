import { Grid, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { components } from 'react-select';
import TableServices from 'services/table';
import { trelloLabels } from 'theme/trelloLabels';

const MultipleCardComponent = ({ cardItem, editData, typeOfCard, worker, cardId, index, handleBlur, handleChange, fieldName, array }) => {
    // console.log("cardId---", typeOfCard);
    
    const { workermanager } = useSelector(({ table }) => table);
    // console.log("workermanager", workermanager);
    const dispatch = useDispatch();
    const { allProjectLabels } = useSelector(({ table }) => table);
    // console.log("allProjectLabels", allProjectLabels);
    const { projectTypeData } = useSelector(({ table }) => table);
    // const Index = array?.findIndex(e => e?.trello_id == obj?.trello_id)
    // console.log("Index",Index);
    const initialSelectedWorker =
        typeOfCard === 'Mechanical' ? editData?.cell?.row?.original?.new_Mec_Worker.map(value => ({ label: value?.fullName })) :
            typeOfCard === 'Electrical' ? editData?.cell?.row?.original?.new_Ele_Worker.map(value => ({ label: value?.fullName })) :
                typeOfCard === 'Plumbing' ? editData?.cell?.row?.original?.new_Plu_Worker.map(value => ({ label: value?.fullName })) : [{ label: '--', value: '--' }];;

    const initialSelectedManager =
        typeOfCard === 'Mechanical' ? editData?.cell?.row?.original?.new_Mec_manager.map(value => ({ label: value?.fullName })) :
            typeOfCard === 'Electrical' ? editData?.cell?.row?.original?.new_Ele_manager.map(value => ({ label: value?.fullName })) :
                typeOfCard === 'Plumbing' ? editData?.cell?.row?.original?.new_Plu_manager.map(value => ({ label: value?.fullName })) : [{ label: '--', value: '--' }];;

    // console.log("initialSelectedWorker", initialSelectedWorker);
    const [selectedWorker, setSelectedWorker] = useState(initialSelectedWorker);
    const [selectedManager, setSelectedManager] = useState(initialSelectedManager);
    // console.log("projectTypeData",projectTypeData);
    // console.log("");
    // React.useEffect(() => {
    //     dispatch(TableServices.getAllProjectLabel());
    // }, [])
    // console.log("allWorkCardLabels--", allWorkCardLabels);
    const arrayValueChange = (obj) => {
        const Index = array?.findIndex(e => e?.trello_id == obj?.trello_id)
        if (index !== -1) {
            array[Index] = obj
            // console.log("onchane new obj ", array);
            handleChange(array)

        }
    }
    const onItemValueChange = (value) => {
        // console.log("onchane", value);
        const hourChange = {
            ...cardItem,
            ...{ Estimated_Hours: value }
        }
        arrayValueChange(hourChange)
    }
    const onWorkCardLabelValueChange = (value) => {
        let finalArray = value.map(function (obj) {
            return obj.value;
        });
        const labelChange = {
            ...cardItem,
            ...{ labelNames: finalArray }
        }
        arrayValueChange(labelChange)
    }
    const workerOptions = [
        { value: '--', label: '--' },
        ...workermanager.map(worker => ({
          value: worker?.id,
          label: worker?.fullName
        }))
      ];
      
    const handleWorkerChange = async (selectedOption) => {
        // setSelectedWorker(selectedOption);
        const selectedWorker = selectedOption?.value;
        try {
            await dispatch(TableServices.updateWorker(cardId, selectedWorker));
            setSelectedWorker(selectedOption);
        } catch (error) {
            console.error('Error updating worker:', error);
        }

    };
    // console.log("workerOptions",workerOptions);
    const handleManagerChange = async (selectedOption, obj, event) => {
        const selectedManager = selectedOption?.value;
        try {
            await dispatch(TableServices.updateManager(cardId, selectedManager));
            setSelectedManager(selectedOption);
        } catch (error) {
            console.error('Error updating worker:', error);
        }
    };
    // console.log("workerOptions",selectedWorker);
    return (
        <>
            <Grid item md={5}>
                <TextField
                    fullWidth
                    disabled
                    value={cardItem.child_card_title}
                    placeholder="Mechanical"
                    variant="outlined"
                    name="cardItem.child_card_title"
                    multiline
                    className="edit-value"
                    style={{
                        maxWidth: "250px"
                    }}
                />
            </Grid>
            <Grid item md={2}>
                <TextField
                    // fullWidth
                    // error={Boolean(touched.Spent_Mech_Hours && errors.Spent_Mech_Hours)}
                    // helperText={touched.Spent_Mech_Hours && errors.Spent_Mech_Hours}
                    value={cardItem?.Estimated_Hours}
                    placeholder="hour"
                    variant="outlined"
                    name={`cardItem?..${index}.Estimated_Hours`}
                    type='number'
                    // onBlur={handleBlur}
                    // onChange={handleChange}
                    onChange={(e) => {
                        onItemValueChange(e?.target?.value)

                    }}
                    multiline
                    className="edit-value"
                    style={{
                        maxWidth: "60px",
                        marginLeft: "20px"
                    }}
                />
            </Grid>
            <Grid item md={5}>
                <Select
                    isMulti
                    value={cardItem?.labelNames?.map(label => ({ 'value': label, 'label': label }))}
                    options={allProjectLabels}
                    placeholder="Work card Labels.."
                    // styles={customStyles}
                    maxMenuHeight={200}
                    onChange={(xx) => {
                        onWorkCardLabelValueChange(xx)
                    }}
                />
            </Grid>
            <Grid item md={3} className='mt-3'>
                <Typography className="edit-label">Worker:</Typography>
            </Grid>
            <Grid item md={1} className='mt-3'></Grid>
            <Grid item md={8} className='mt-3'>
                <Select
                // defaultValue={selectedWorker}
                    value={selectedWorker}
                    options={workerOptions}
                    // placeholder="Worker"
                    maxMenuHeight={200}
                    onChange={handleWorkerChange} />
            </Grid>
            <Grid item md={3} className='mt-3'>
                <Typography className="edit-label">Manager:</Typography>
            </Grid>
            <Grid item md={1} className='mt-3'></Grid>
            <Grid item md={8} className='mt-3'>
                <Select
                    value={selectedManager} 
                    options={workerOptions}
                    // placeholder="Manager..."
                    maxMenuHeight={200}
                    onChange={handleManagerChange}
                />
            </Grid>
        </>
    )
}

export default MultipleCardComponent;