import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import TableServices from 'services/table';
import Linkify from 'react-linkify';

const DeliveryChecklistItem = ({ item, cardId, data, childCardId, typeOfCard }) => {
  const [info, setInfo] = useState(item?.name);
  const [isChecked, setIsChecked] = useState(item.state === 'complete');
  const [isEditing, setIsEditing] = useState(false); 
  const dispatch = useDispatch();

  const updateText = () => {
    const selectedCardId = typeOfCard === 'maincard' ? cardId : childCardId;

    const EditPayload = {
      cardId: selectedCardId,
      checkListId: item?.idChecklist,
      checkItemId: item?.id,
      name: info,
    };

    dispatch(TableServices.updateChecklist(EditPayload, selectedCardId));
    setIsEditing(false); 
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      updateText();
    }
  };

  const handleTextAreaChange = (event) => {
    setInfo(event.target.value);
  };

  const handleDoubleClick = () => {
    setIsEditing(true); 
  };

  return (
    <Linkify>
      <p
        className={`comments-message form-control ${isEditing ? 'editing' : ''}`}
        style={{ textDecoration: item.state === 'complete' ? 'line-through' : 'none' }}
        onDoubleClick={handleDoubleClick}
        onBlur={updateText} 
      >
        {isEditing ? (
          <textarea
          className="comments-message form-control"
            value={info}
            onChange={handleTextAreaChange}
            onKeyDown={handleKeyDown}
            autoFocus 
          />
        ) : (
          info
        )}
      </p>
    </Linkify>
  );
};

export default DeliveryChecklistItem;
