import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TableServices from 'services/table';

const FolderInput = ({ originalRow }) => {
  const dispatch = useDispatch();
  const [folder, setFolder] = useState(originalRow?.Folder_number || "");

  useEffect(() => {
    setFolder(originalRow?.Folder_number || "");
  }, [originalRow?.Folder_number]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      updateFolder();
    }
  };

  const handleInputChange = (event) => {
    setFolder(event.target.value);
  };

  const updateFolder = () => {
    const updatedfolder = {
      comment_id: originalRow.comment_id,
      folderNumber: folder,
    };
    dispatch(TableServices.userFolderInput(updatedfolder));
  };

  return (
    <input
      type="text"
      style={{
        width: '125px',
        padding: '5px',
        marginTop: '5px',
        borderRadius: '5px'
      }}
      placeholder="Enter Folder"
      value={folder}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
    />
  );
};

export default FolderInput;
