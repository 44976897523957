import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Axios from 'axios';
import React, { useState } from 'react';
import { Mention, MentionsInput } from 'react-mentions';
import { useDispatch, useSelector } from 'react-redux';
import { sendTextMessage } from 'redux/actions/Chat';
import TableServices from 'services/table';
import { AppTheme } from 'theme/customeTheme';
import AttachFileSharpIcon from '@material-ui/icons/AttachFile';
import UploadMediaFilesBox from '../CommentComponents/UploadMediaFilesBox';
import SendIcon from '@material-ui/icons/Send';

const useStyles = makeStyles(theme => ({
  chatFooterRoot: {
    border: `1px solid #D7D7D7`,
    backgroundColor: "#ECECEC",
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    marginTop: 16,
    '& .mentionTextbox__suggestions': {
      top: 'auto !important',
      bottom: '100%',
      maxHeight: '300px',
      overflowY: 'scroll'
    }
  },
  chatEditFooterRoot: {
    backgroundColor: "#ECECEC",
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    '& .mentionTextbox__suggestions': {
      top: 'auto !important',
      bottom: '100%',
      maxHeight: '300px',
      overflowY: 'scroll'
    }
  },
  textFieldRoot: {
    marginRight: 20,
    '& .MuiInputBase-input': {
      fontFamily: '"Mulish", sans-serif',
      fontWeight: 400,
      width: "100% !important",
      padding: "10.5px 14px !important",
      color: "#737B7D",
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 5,
      backgroundColor: "#ffffff"
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: "transparent !important"
    }
  },
  iconBtnRoot: {
    fontFamily: '"Mulish", sans-serif',
    fontSize: 18,
    fontWeight: 700,
    padding: 5,
    color: '#FFFFFF',
    backgroundColor: '#003D8F',
    borderRadius: 5,
    textTransform: 'capitalize'
  },
}));

const QAFooter = ({ cardType, openQuestionBox, editQuestion, editMode, chatData }) => {
  const [message, setMessage] = useState(chatData ? chatData.message : '');
  const [mentions, setMentions] = useState('');
  const [open, setOpen] = React.useState(false);
  const [pasteFAQFlag, setPasteFAQFlag] = useState(true);
  const [FAQfileArray, setFAQFileArray] = useState({});
  let index = useState(0);
  const dispatch = useDispatch();
  const classes = useStyles();
  // const mentions = useState([]);
  const { trelloUsersData } = useSelector(({ table }) => table);
  React.useEffect(() => {
    dispatch(TableServices.getTrelloUsers());
  }, [])
  const onSendMessage = () => {
    if (editMode)
      editQuestion()
    else
      openQuestionBox();
    if (message) {
      // console.log("onSendMessage", message);
      dispatch(sendTextMessage(message));
      const FAQConversation = {
        message: message,
        thread: cardType._id,
        message_type: "question",
        tagged_users: mentions,
        thread_message_id: chatData?.id
      }
      dispatch(TableServices.updateConversations(FAQConversation, "faq"));
      setMessage('');
    }
  };
  const handleChange = (event) => {
    const value = event.target.value;
    // console.log("event::", value);
    const regex = /[^{}]+(?=})/g;
    const chatMentions = value.match(regex);
    // console.log("chatMentions::", chatMentions);
    setMessage(event.target.value);
    setMentions(chatMentions)
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  function renderSuggestion(entry, search, highlightedDisplay, index, focused) {
    // Modify the suggestion dropdown by returning valid JSX
    return (
      <>
        <span>{entry.display}</span>
      </>
    );
  }
  const defaultStyle = {
    width: editMode ? '615px' : '730px',
    height: '45px',
    backgroundColor: 'white',
    borderRadius: '5px',
    fontSize: '16px'
  };
  const defaultMentionStyle = {
    fontWeight: "600",
    backgroundColor: "#cee4e5",
    margin: "5px",
  };
  const onPasteEvent = () => {
    // console.log("paste temppppppp FAQ");
    // let isPaste = true;
    if (pasteFAQFlag) {
      window.addEventListener('paste', e => {
        const file = e.clipboardData.files;
        setFAQFileArray(file)
        // console.log("paste file--FAQ", file);
        setPasteFAQFlag(false);
      });
    } else {
      // console.log('removeEventListener useEffect');
      window.removeEventListener('paste', onPasteEvent)
      setPasteFAQFlag(true);
      setFAQFileArray({})
    }
  }
  // console.log('trelloUsersData',trelloUsersData);
  return (
    <>
      {/* <MentionsInput value={this.state.value} onChange={this.handleChange}> */}
      <div className={editMode ? classes.chatEditFooterRoot : classes.chatFooterRoot}>
        <MentionsInput
          // markup="@__id__"
          // markup="@[__display__](__id__)"
          placeholder="Write Here... "
          value={message}
          onChange={handleChange}
          onPaste={onPasteEvent}
          onKeyPress={(e) => {
            if (e?.key === "Enter") {
              onSendMessage();
            }
          }}
          style={defaultStyle}
          className="mentionTextbox"
        >
          <Mention
            markup="[__display__]{__id__}"
            trigger="@"
            data={(trelloUsersData?.map((val) => {
              return {
                id: val.userName,
                display: val.userName,
              }
            }
            ))}
            style={defaultMentionStyle}
            renderSuggestion={renderSuggestion}
            appendSpaceOnAdd={true}
          />
        </MentionsInput>
        <IconButton style={editMode ? { padding: '3px' } : {}} aria-label="delete" color="primary" onClick={handleClickOpen}>
          <AttachFileSharpIcon />
        </IconButton>
        <UploadMediaFilesBox fileArray={FAQfileArray} FAQType={'question'} message={message} cardType={cardType} mentions={mentions} open={open} handleClose={handleClose} />
        {
          editMode ? (
            <SendIcon className="pointer" onClick={onSendMessage} />
          ) : (
            <Button className={classes.iconBtnRoot} variant="contained"
              onClick={onSendMessage} style={{ backgroundColor: AppTheme.colors.primary }}>
              Send
            </Button>
          )}
      </div>
    </>
  );
};

export default QAFooter;
