import CmtAdvCard from "@coremat/CmtAdvCard";
import CmtAdvCardContent from "@coremat/CmtAdvCard/CmtAdvCardContent";
import CmtCardHeader from "@coremat/CmtCard/CmtCardHeader";
import { StatisticsCard } from "@jumbo/components/Common";
import { Box, Button, Card } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import makeStyles from '@material-ui/core/styles/makeStyles';
import React from "react";
import { AppTheme } from "theme/customeTheme";
import GridContainer from "../../../@jumbo/components/GridContainer";
import PageContainer from "../../../@jumbo/components/PageComponents/layouts/PageContainer";
import IntlMessages from "../../../@jumbo/utils/IntlMessages";
import Graph from "./Graph";
import SalesGauge from "./SalesGauge";
import RecentPaymentsTable from './RecentPaymentsTable';
import CmtCard from "@coremat/CmtCard";
import CmtCardContent from "@coremat/CmtCard/CmtCardContent";
import CmtAvatar from '@coremat/CmtAvatar';
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import DashboardServices from 'services/dashboard';
const salesStatistic = {
    title: 'PROJECT STATUS',
    salesGaugeData: [
        {
            category: 'New Added',
            value: 85,
            full: 100,
        },
        {
            category: 'Due in 2 weeks',
            value: 51,
            full: 100,
        },
        {
            category: 'Hr. Per Day',
            value: 66,
            full: 100,
        },
    ],
};
const useStyles = makeStyles(theme => ({
    cardRoot: {
        color: theme.palette.common.white,
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.18)"
    },
    cardHeader: {
        padding: 10,
        marginTop: 20
    },
    titleRoot: {
        fontSize: 24,
        textTransform: 'uppercase',
        letterSpacing: 0.4,
    },
    projetHeader: {
        padding: "8px 64px",
        textAlign: "center",
    },
    notificationCard: {
        color: theme.palette.common.white,
        borderTopLeftRadius: "15px",
        borderTopRightRadius: "15px"
    },
    notificationContent: {
        fontSize: '18px',
        padding: '25px',
        color: '#000000'
    },
    notificationTime: {
        fontSize: '18px',
        padding: '25px',
        color: '#B3B3B3'
    },
    cardContent: {
        paddingLeft: '15px'
    },
}));
const DashboardPage = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { dashboardData } = useSelector(({ dashboard }) => dashboard);
    React.useEffect(() => {
        dispatch(DashboardServices.getDashboardData());
    }, [])
    // console.log("dashboardData::", dashboardData);
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <title> Priority Sheets | Dashboard</title>
            </Helmet>
            <PageContainer className="pageContainer">
                <GridContainer style={{ marginTop: 60 }}>
                    <Grid item xs={12} sm={6} md={4} className="card-content">
                        <StatisticsCard
                            className={classes.cardRoot}
                            backgroundColor="#7CE3BE"
                            title="COMPLETED"
                            titleProps={{
                                variant: 'inherit',
                                component: 'h4',
                                className: classes.titleRoot,
                            }}
                            // amount="26,873"
                            amount={dashboardData?.completedCards ? dashboardData?.completedCards : 0}
                            description="">
                            <Graph fill={'#8BF0CC'} stroke={'#FFFFFF1C'} />
                        </StatisticsCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className="card-content">
                        <StatisticsCard
                            className={classes.cardRoot}
                            backgroundColor="#EAC76C"
                            title="IN PROGRESS"
                            titleProps={{
                                variant: 'inherit',
                                component: 'h4',
                                className: classes.titleRoot,
                            }}
                            // amount="2735"
                            amount={dashboardData?.inProgressCount ? dashboardData?.inProgressCount : 0}
                            description="">
                            <Graph fill={"#EAC76C"} stroke={'#FFFFFF1C'} />
                        </StatisticsCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} className="card-content">
                        <StatisticsCard
                            className={classes.cardRoot}
                            backgroundColor="#FFA292"
                            title="BLOCKED"
                            titleProps={{
                                variant: 'inherit',
                                component: 'h4',
                                className: classes.titleRoot,
                            }}
                            // amount="141"
                            amount={dashboardData?.blockedCards ? dashboardData?.blockedCards : 0}
                            description="">
                            <Graph fill={"#FFA292"} stroke={'#FFFFFF1C'} />
                        </StatisticsCard>
                    </Grid>
                </GridContainer>
                <GridContainer style={{ marginTop: 60 }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <CmtCard className={classes.cardRoot}>
                            <CmtCardHeader className={classes.cardHeader} titleProps={{
                                style: {
                                    color: AppTheme.colors.primary,
                                    fontSize: '25px',
                                    textAlign: 'center',
                                    fontFamily: "Poppins",
                                    fontWeight: "600"
                                }
                            }
                            } title='Mechanical'
                                subTitleProps={{
                                    style: {
                                        color: AppTheme.colors.primary,
                                        fontSize: '25px',
                                        textAlign: 'center',
                                        fontFamily: "Poppins",
                                        fontWeight: "600"
                                    }
                                }} subTitle={dashboardData?.mechanicalCards ? dashboardData?.mechanicalCards : 0} />
                            {/* }} subTitle="35" />   */}
                            <hr style={{ margin: '0px 20px', borderColor: 'rgba(0, 109, 143, 0.5)' }}></hr>
                            <RecentPaymentsTable />
                        </CmtCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <CmtCard className={classes.cardRoot}>
                            <CmtCardHeader className={classes.cardHeader} titleProps={{
                                style: {
                                    color: AppTheme.colors.primary,
                                    fontSize: '25px',
                                    textAlign: 'center',
                                    fontFamily: "Poppins",
                                    fontWeight: "600"
                                }
                            }
                            } title='Electrical'
                                subTitleProps={{
                                    style: {
                                        color: AppTheme.colors.primary,
                                        fontSize: '25px',
                                        textAlign: 'center',
                                        fontFamily: "Poppins",
                                        fontWeight: "600"
                                    }
                                }} subTitle={dashboardData?.electricalCards ? dashboardData?.electricalCards : 0} />
                            {/* }} subTitle="51" />  */}
                            <hr style={{ margin: '0px 20px', borderColor: 'rgba(0, 109, 143, 0.5)' }}></hr>
                            <RecentPaymentsTable />
                        </CmtCard>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <CmtCard className={classes.cardRoot}>
                            <CmtCardHeader className={classes.cardHeader} titleProps={{
                                style: {
                                    color: AppTheme.colors.primary,
                                    fontSize: "25px",
                                    textAlign: "center",
                                    fontFamily: "Poppins",
                                    fontWeight: "600"
                                }
                            }
                            } title='Plumbing'
                                subTitleProps={{
                                    style: {
                                        color: AppTheme.colors.primary,
                                        fontSize: '25px',
                                        textAlign: 'center',
                                        fontFamily: "Poppins",
                                        fontWeight: "600"
                                    }
                                }} subTitle={dashboardData?.plumbingCards ? dashboardData?.plumbingCards : 0}
                            // }} subTitle="10"
                            />
                            <hr style={{ margin: '0px 20px', borderColor: 'rgba(0, 109, 143, 0.5)' }}></hr>
                            <RecentPaymentsTable />
                        </CmtCard>
                    </Grid>
                </GridContainer>
                <GridContainer style={{ marginTop: 60 }}>
                    <Grid item xs={12} sm={6} md={4}>
                        <CmtAdvCard>
                            <CmtCardHeader titleProps={{
                                style: {
                                    color: AppTheme.colors.white,
                                    fontSize: 25,
                                    fontWeight: 700,
                                    fontFamily: 'Poppins'
                                }
                            }
                            } className={classes.projetHeader} backgroundColor={AppTheme.colors.primary} title={salesStatistic.title} />
                            <CmtAdvCardContent className={classes.cardContentRoot}>
                                <GridContainer style={{ marginTop: 30 }}>
                                    <Grid item xs={12}>
                                        <Box className={classes.salesGaugeRoot}>
                                            <SalesGauge salesGaugeData={salesStatistic.salesGaugeData} />
                                        </Box>
                                    </Grid>
                                </GridContainer>
                            </CmtAdvCardContent>
                        </CmtAdvCard>
                    </Grid>
                </GridContainer>
            </PageContainer >
        </>
    );
};

export default DashboardPage;
