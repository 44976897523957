import React, { useEffect, useState } from 'react';
import ReactLinkify from 'react-linkify';
import { useDispatch } from 'react-redux';
import TableServices from 'services/table';

const ChecklistItem = ({ item, cardId, data, childCardId, typeOfCard }) => {
  const [info, setInfo] = useState(item?.name);
  const [isChecked, setIsChecked] = useState(item.state === 'complete');
  const [isEditing, setIsEditing] = useState(false); 
  const dispatch = useDispatch();

  const toggleEditing = () => {
    setIsEditing(!isEditing); 
  };

  const updateText = () => {
    const selectedCardId = typeOfCard === 'maincard' ? cardId : childCardId;

    const EditPayload = {
      cardId: selectedCardId,
      checkListId: item?.idChecklist,
      checkItemId: item?.id,
      name: info,
    };

    dispatch(TableServices.updateChecklist(EditPayload, selectedCardId));
    setIsEditing(false); 
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      updateText();
    }
  };

  const handleTextAreaChange = (event) => {
    setInfo(event.target.value);
  };

  return (
    <ReactLinkify>
      <p className={`comments-message form-control ${isEditing ? 'editing' : ''}`}
        onDoubleClick={toggleEditing}>
        {isEditing ? (
          <textarea
            className="comments-message form-control"
            value={info}
            style={{ textDecoration: item.state === 'complete' ? 'line-through' : 'none' }}
            onChange={handleTextAreaChange}
            onKeyDown={handleKeyDown}
            onBlur={updateText} 
            autoFocus />
        ) : (
          <div style={{ textDecoration: item.state === 'complete' ? 'line-through' : 'none' }}>
            {info}
          </div>
        )}
      </p>
    </ReactLinkify>
  );
};

export default ChecklistItem;