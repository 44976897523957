import {
  StaticOptionsKeyValueFilter, TableDateRangeFilter, doFilter,
  getSrNo,
  usePrevious
} from '@jumbo/components/TableComponents/table-utils';
import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateFAQNewNotification,
  UpdateFAQReadNotification,
  UpdateTableRow,
  UpdateThreadNewNotification,
  UpdateThreadReadNotification
} from "redux/actions/Table.action";
import TableServices from "services/table";
import { io } from "socket.io-client";
import { BASE_WS_URL } from "utils/globals";
// import CriteriaRating from './CriteriaRating'
import CommentInput from '@jumbo/components/TableComponents/FrontApp Component/CommentInput';
import CheckboxInput from '@jumbo/components/TableComponents/FrontApp Component/DTCheckbox';
import FolderInput from '@jumbo/components/TableComponents/FrontApp Component/FolderInput';
import FrontAppTable from "./MainTable";
const filterMap = {
  cmt_subject: "cmt_subject",
  comment: "comment",
  comment_date: ["CommentStartDate", "CommentEndDate"],
  user_mail: "user_mail",
  username: "username",
  cmt_conversation: "cmt_conversation",
  tabname: "tabname",
  Folder_number: "Folder_number",
  client_comment: "client_comment",
  status: "status",
  PM: "PM",
  DT: "DT"
};
// const useStyles = makeStyles((theme) => ({
//     root: {
//         backgroundColor: "#F2FAFD",
//         padding: "16px 16px 0 16px",
//         borderRadius: 0,
//         borderTopRightRadius: 10,
//         marginTop: "70px"
//     },
// }));
function FrontApp({ }) {
  // const classes = useStyles();
  const mountedRef = useRef();
  const tableRef = useRef(null);
  const ITEMS_PER_PAGE = 15;
  const [lastLockedIndex, setLastLockedIndex] = useState(2);
  const [rightLockedIndex, setRightLockedIndex] = useState(8);
  const [filtersToApply, setFiltersToApply] = React.useState({});
  const [countdown, setCountdown] = useState(5);
  const prevFiltersToApply = usePrevious(filtersToApply);
  const dispatch = useDispatch();
  const { frontTableData } = useSelector(({ table }) => table);
  const [paginationOptions, setPaginationOptions] = useState({
    limit: ITEMS_PER_PAGE,
    offset: 0,
    currentPage: 0,
    orderBy: "",
    orderType: null,
  });
  const columns = React.useMemo(
    () => [
      {
        Header: "Sr.",
        accessor: "Sr_NO",
        Cell: (props) => {
          return (
            <div className="table_srNo">
              <span className="table_index ">{getSrNo(props)}</span>
            </div>
          );
        },
        disableFilters: true,
      },
      {
        id: "cmt_subject",
        Header: "Subject",
        accessor: (originalRow, rowIndex) => {
          return (
            <p
              title={originalRow?.cmt_subject}
              className="text-truncate review-project"
            >
              {originalRow?.cmt_subject}
            </p>
          );
        },
      },
      {
        id: "Folder_number",
        Header: "Folder",
        accessor: (originalRow, rowIndex) => {
          return (
            <FolderInput
              originalRow={originalRow}
            />
          )
        }
      },
      {
        id: "comment",
        Header: "Comment",
        accessor: (originalRow, rowIndex) => {
          return originalRow?.comment;
        },
      },
      {
        id: "comment_date",
        Header: "Comment Date",
        accessor: (originalRow, rowIndex) => {
          return (
            <p title={originalRow?.comment_date} className="text-truncate">
              {originalRow?.comment_date}
            </p>
          );
        },
        Filter: TableDateRangeFilter,
      },
      {
        id: "user_mail",
        Header: "User Mail",
        accessor: (originalRow, rowIndex) => {
          const email = originalRow?.user_mail;
          const mailtoLink = `mailto:${email}`;
          return (
            <a
              title={email}
              href={mailtoLink}
              className="text-truncate"
              dangerouslySetInnerHTML={{ __html: email }}
            />
          );
        },
      },
      {
        id: "username",
        Header: "User name",
        accessor: (originalRow, rowIndex) => {
          return (
            <p title={originalRow?.username} className="text-truncate">
              {originalRow?.username}
            </p>
          );
        },
      },
      {
        id: "cmt_conversation",
        Header: "Conversation",
        accessor: (originalRow, rowIndex) => {
          return (
            <a
              target="blank"
              href={originalRow?.cmt_conversation}
              title={originalRow?.cmt_conversation}
              className="text-truncate"
            >
              {originalRow?.cmt_conversation}
            </a>
          );
        },
      },
      {
        id: "tabname",
        Header: "Tab Name",
        accessor: (originalRow, rowIndex) => {
          return (
            <p title={originalRow?.tabname} className="text-truncate">
              {originalRow?.tabname}
            </p>
          );
        },
      },
      {
        id: "client_comment",
        Header: "Comment Box",
        accessor: (originalRow, rowIndex) => {
          return (
            <CommentInput
              originalRow={originalRow}
            />
          );
        },
      },

      {
        id: "DT",
        Header: "DT Complete",
        accessor: (originalRow, rowIndex) => {
          return (
            <CheckboxInput
              originalRow={originalRow}
              filtersToApply={Object.entries(filtersToApply)}
              paginationOptions={paginationOptions}
              checkboxType="DT" />
          );
        },
        Filter: (props) => StaticOptionsKeyValueFilter(props, [{ label: 'Completed', value: 'Completed' }, { label: 'Not Completed', value: 'NotCompleted' }]),
      },
      {
        id: "PM",
        Header: "PM Complete",
        accessor: (originalRow, rowIndex) => {
          return (
            <CheckboxInput
              originalRow={originalRow}
              paginationOptions={paginationOptions}
              filtersToApply={Object.entries(filtersToApply)}
              checkboxType="PM" />
          );
        },
        Filter: (props) => StaticOptionsKeyValueFilter(props, [{ label: 'Completed', value: 'Completed' }, { label: 'Not Completed', value: 'NotCompleted' }]),
      },
    ],
    []
  );
  // console.log("comment date", TableDateRangeFilter);
  // console.log("columns--", columns);
  const applyFilters = (filters) => {
    // console.log("filters::", filters);
    setFiltersToApply(doFilter(filters, filterMap));
  };
  useEffect(() => {
    const countdownInterval = setInterval(() => {
      setCountdown(prevCountdown => (prevCountdown === 1 ? 5 : prevCountdown - 1));
    }, 1000); // 1000 milliseconds = 1 second

    const refreshInterval = setInterval(() => {
      getTableData(true);
    }, 5000); // 5000 milliseconds = 5 seconds

    return () => {
      clearInterval(countdownInterval);
      clearInterval(refreshInterval);
    };
  }, [paginationOptions, filtersToApply]);
  const getTableData = async (noRefresh = false) => {
    //     // console.log("getPageData 3 ", paginationOptions);
    // const queryString = new URLSearchParams(filtersToApply).toString();
    //     // const queryData = window.location.search;
    //     // const searchString = qs.parse(queryData);
    //     // console.log("searchString  1--", searchString);

    dispatch(
      TableServices.getfrontTableData({
        limit: paginationOptions?.limit,
        currentPage: paginationOptions?.currentPage + 1,
        // currentView: tableView || 'Latest',
        orderByColumn: paginationOptions.orderBy || null,
        noRefresh,
        // columnOrderType: paginationOptions.orderType || null,
        ...filtersToApply,
      })
    );
  };
  const getPageData = (pageNo, itemsPerPage) => {
    setPaginationOptions(old => {
      return {
        limit: itemsPerPage,
        offset: (pageNo) * itemsPerPage,
        currentPage: pageNo,
        orderBy: old.orderBy,
        orderType: old.orderType,
      };
    });
  };
  const setOrderByColumns = (column) => {
    if (paginationOptions.orderBy == column) {
      if (paginationOptions.orderType == "asc") {
        setPaginationOptions(old => {
          return {
            limit: old.limit,
            offset: 0 * old.limit,
            currentPage: 0,
            orderType: "desc",
            orderBy: column,
          };
        });
      }
      if (paginationOptions.orderType == "desc") {
        setPaginationOptions(old => {
          return {
            limit: old.limit,
            offset: 0 * old.limit,
            currentPage: 0,
            orderType: "asc",
            orderBy: column,
          };
        });
      }
    } else {
      setPaginationOptions(old => {
        return {
          limit: old.limit,
          offset: 0 * old.limit,
          currentPage: 0,
          orderType: "asc",
          orderBy: column,
        };
      });
    }
  };
  useEffect(() => {
    // if (
    //   filtersToApply &&
    //   prevFiltersToApply &&
    //   !_.isEqual(prevFiltersToApply, filtersToApply)
    // ) {
    //   if (queryString &&
    //     !_.isEqual(
    //         queryString
    //     )
    // )
    {
      setPaginationOptions(old => {
        return {
          limit: old.limit,
          offset: 0 * old.limit,
          currentPage: 0,
          orderBy: old.orderBy,
          orderType: old.orderType,
        };
      });
      if (paginationOptions.currentPage == 0 && paginationOptions.offset == 0) {
        getTableData();
      }
    }
  }, [filtersToApply, prevFiltersToApply]);
  useEffect(() => {
    if (paginationOptions.limit) {
      //         // if (mountedRef.current) {
      // dispatch(TableServices.getManagersData());
      getTableData();
      //         // }
    }
  }, [
    paginationOptions.currentPage,
    paginationOptions.limit,
    paginationOptions.offset,
    paginationOptions.orderBy,
    paginationOptions.orderType,
  ]);
  useEffect(() => {
    setPaginationOptions(old => {
      return {
        limit: old.limit,
        offset: 0 * old.limit,
        currentPage: 0,
        orderBy: "",
        orderType: null,
      };
    });
    if (paginationOptions.currentPage == 0 && paginationOptions.offset == 0) {
      getTableData();
    }
  }, []);
  useEffect(() => {
    mountedRef.current = true;
    const userToeken = localStorage.getItem("trello_user_token");
    const socket = io(BASE_WS_URL, {
      query: { token: userToeken },
    });
    // console.log("IN Socket");

    if (socket) {
      socket.on("onTrelloCardUpdate", (res) => {
        const UpdatedData = JSON.parse(res);
        if (typeof UpdatedData?.child_cards?.[0] !== "string")
          //fix for ignoring non populated children
          dispatch(UpdateTableRow(UpdatedData));
        // console.log("onTrelloCardUpdate socket", UpdatedData);
      });
      socket.on("onTrelloCardAdded", (res) => {
        // console.log("IN onTrelloCardAdded");
        // console.log("IN onTrelloCardAdded", res);
        //             // getTableData();
        //             // getTableData(tableView, paginationOptions);
        //             // const AddUpdatedData = JSON.parse(res)
        //             // if (typeof AddUpdatedData?.child_cards?.[0] !== 'string')//fix for ignoring non populated children
        //             //     dispatch(UpdateTableRow(AddUpdatedData))
        //             // console.log("socket", AddUpdatedData);
      });
      socket.on("onThreadNewMessagePosted", (res) => {
        const UpdatedData = res;
        if (UpdatedData.message_type == "faq")
          dispatch(UpdateFAQNewNotification(UpdatedData));
        else dispatch(UpdateThreadNewNotification(UpdatedData));
      });
      socket.on("onThreadNewMessageRead", (res) => {
        const UpdatedData = res;
        if (UpdatedData.message_type == "faq")
          dispatch(UpdateFAQReadNotification(UpdatedData));
        else dispatch(UpdateThreadReadNotification(UpdatedData));
      });
    }
    return () => socket.close();
  }, [])
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <title> Priority Sheets | Front App</title>
      </Helmet>
      {/* <Box display="inline-flex" alignItems="center" className="tab-heading" >Active Projects</Box> */}
      <div className="row" style={{ margin: "20px 0px", fontSize: "20px", fontWeight: "600" }}>
        <div className='col-md-9'>Front App:{" "}</div>
        <div className='col-md-3' style={{ textAlign: 'end', marginTop: '-29px' }}><button style={{ margin: '0rem 0.125rem', padding: '0.375rem 0.75rem', color: 'white', backgroundColor: '#006D8F', borderColor: '#006D8F', lineHeight: '1.5', border: '1px solid transparent', fontSize: "15px", fontWeight: "600", borderRadius: '5px' }}>Countdown: {countdown}</button></div>
      </div>
      <div>
        <FrontAppTable
          tableRef={tableRef}
          applyFilters={applyFilters}
          columns={columns}
          lastLockedIndex={lastLockedIndex}
          rightLockedIndex={rightLockedIndex}
          itemsPerPage={paginationOptions.limit}
          TotalLength={frontTableData?.totalitems || 0}
          getPageData={getPageData}
          orderByColumns={setOrderByColumns}
          currentPage={paginationOptions.currentPage}
          orderBy={paginationOptions.orderBy}
          offset={paginationOptions.offset}
          updateCallBack={() => getTableData()}
          data={frontTableData?.frontAppData || []}
        />
        {/* <FrontAppTable /> */}
      </div>
    </React.Fragment>
  );
}

export default FrontApp;
