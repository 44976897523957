import { Checkbox, Collapse, IconButton, TextareaAutosize } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import Axios from "services/config";
import { io } from "socket.io-client";
import { AppTheme } from "theme/customeTheme";
import { BASE_WS_URL } from "utils/globals";
import deleteIcon from "../../../../img/delete.webp";
import editIcon from "../../../../img/Edit.webp";
import QAFooter from "./QAFooter";
import SendIcon from '@material-ui/icons/Send';
import { Mention, MentionsInput } from "react-mentions";
import TableServices from "services/table";
import AttachFileSharpIcon from '@material-ui/icons/AttachFile';
import UploadMediaFilesBox from "../CommentComponents/UploadMediaFilesBox";
import { Grid } from "@material-ui/core";

const colors = [
    "#D7D7D7",
    "#FFC0C0",
    "#C0D9FF",
    "#FFC0F9",
    "#D7D7D7",
    "#D7D7D7",
];
const defaultStyle = {
    width: '552px',
    height: '45px',
    backgroundColor: 'white',
    border: '1px solid #ececec',
    margin: '20px',
    borderRadius: '5px',
    fontSize: '16px'
};
// const defaultMentionStyle = {
//     fontWeight: "600",
//     backgroundColor: "#cee4e5",
//     margin: "5px",
// };
const QAChatSection = ({ chat, index, projectId, TitleInfo }) => {
    // console.log("TitleInfo::", TitleInfo);
    const [writeAns, setWriteAns] = useState(true);
    const [isOpenCollapse, setIsOpenCollapse] = React.useState(null);
    const [answer, setAnswer] = useState('');
    const [open, setOpen] = React.useState(false);
    const [isEditMode, setIsEditMode] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(chat?.is_checked || false);

    // console.log("chat--", chat);

    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem("userInfo"));

    const loader = (
        <div key="loader" className="loader">
            Loading ...
        </div>
    );
    const handleExpandable = (clickedIndex) => {
        // console.log("handleExpandable", chat);
        // setExpand(!expand);
        if (isOpenCollapse === clickedIndex) {
            setIsOpenCollapse(null);
        } else {
            setIsOpenCollapse(clickedIndex);
        }
    };
    const handleChange = (event) => {
        const value = event.target.value;
        console.log("event::", value);
        setAnswer(event.target.value);
    }
    const enableAnswer = () => {
        setWriteAns(false)
    }
    const deleteAnswer = (MessageType) => {
        // console.log("thread_message_id", thread_message_id);
        const idToSend = chat.id ? chat.id : chat._id;
        dispatch(TableServices.deleteFAQ(idToSend, MessageType));
    }
    const handleIsCheked = () => {
        setIsChecked(!isChecked)
        const idToSend = chat.id ? chat.id : chat._id;
        dispatch(TableServices.updateCheckBoxInChat(idToSend, !isChecked));
    }
    const editQuestion = () => {
        setIsEditMode(!isEditMode);
    }
    const sendAnswer = () => {
        // dispatch(sendTextMessage(message));
        const FAQAnswer = {
            message: answer,
            thread: chat?.thread?._id,
            message_type: "answer",
            tagged_users: null,
            // tagged_users: mentions,
            thread_message_id: chat?._id // question id
        }
        dispatch(TableServices.updateConversations(FAQAnswer));
        setWriteAns(true)
    }
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <div key={"Conversation-" + index} className="comments-card">
                <>
                    {userData.userName == chat?.createdBy?.userName ? (
                        <Avatar
                            className="my-avatar"
                            style={{ backgroundColor: "#C0D9FF", color: "#00057C" }}
                            title={chat?.createdBy?.fullName}
                        >
                            {" "}
                            {chat?.createdBy?.innitials}
                        </Avatar>
                    ) :
                        (
                            <Avatar
                                className="avatar"
                                title={chat?.createdBy?.fullName}
                                style={{
                                    backgroundColor: colors[1],
                                    color: AppTheme.colors.primary,
                                }}
                            >
                                {" "}
                                {chat?.createdBy?.innitials}
                            </Avatar>
                        )}
                </>
                <div>
                    <div className="comments-message-card" style={{ display: 'flex' }}>
                        <div style={{ display: 'grid' }}>
                            <Checkbox
                                style={{ color: '#216ba5' }}
                                checked={isChecked}
                                onChange={handleIsCheked}
                            />
                        </div>
                        <div style={{ display: 'grid', minWidth: '630px' }} >
                            {
                                isEditMode === false ? (
                                    <Typography gutterBottom className="comments-message">
                                        {
                                            chat?.thread_question_image_url ? (
                                                <div>
                                                    <div style={{ textDecoration: isChecked ? 'line-through' : 'none', marginTop: '10px' }}>Q: {chat?.message}</div>
                                                    <a target="blank" style={{ color: '#202124' }} href={"https://priority-sheets-media-upload.s3.ap-south-1.amazonaws.com/" + chat?.thread_question_image_url?.media_img}>
                                                        <img src={"https://priority-sheets-media-upload.s3.ap-south-1.amazonaws.com/" + chat?.thread_question_image_url?.thumbnail_img}
                                                            style={{ marginTop: "5px" }} title={chat?.thread_question_image_url?.media_img}
                                                            className="pointer" alt="image" />
                                                    </a>
                                                </div>
                                            ) : (
                                                <div style={{ textDecoration: isChecked ? 'line-through' : 'none', marginTop: '10px' }}>Q: {chat.message}</div>
                                            )
                                        }
                                    </Typography>
                                ) : (
                                    <QAFooter cardType={TitleInfo} openQuestionBox={isEditMode} editQuestion={editQuestion} editMode={isEditMode} chatData={chat} />
                                )
                            }
                        </div>
                        <img style={{ margin: "20px 5px" }} className="pointer label-img" title="Edit" src={editIcon} alt="edit" onClick={() => editQuestion()} />
                        <img style={{ margin: "20px 5px" }} className="pointer label-img" title="Delete" src={deleteIcon} alt="edit" onClick={() => deleteAnswer('question')} />
                        <div onClick={() => handleExpandable(index)}>
                            {isOpenCollapse === index ? <ExpandLess style={{ margin: "20px 5px" }} /> : <ExpandMore style={{ margin: "20px 5px" }} />}
                        </div>
                    </div>
                    <Typography
                        gutterBottom
                        className="comments-date"
                        variant="h6"
                    >
                        {moment(chat?.updatedAt).format("DD-MMM-YY hh:mm")}
                    </Typography>
                </div>
                <Collapse style={{ margin: '0px 0px 0px 15px' }} in={isOpenCollapse === index} timeout="auto" unmountOnExit>
                    <UploadMediaFilesBox projectId={projectId} FAQType={'answer'} message={answer} cardType={chat} mentions={null} open={open} handleClose={handleClose} />

                    <div className="card">
                        <Grid container>
                            {
                                chat?.thread_image_url &&
                                <Grid item xs={12} md={4}>
                                    <div>
                                        <a target="blank" style={{ color: '#202124', margin: '5px', padding: '5px' }} href={"https://priority-sheets-media-upload.s3.ap-south-1.amazonaws.com/" + chat?.thread_image_url?.media_img}>
                                            <img src={"https://priority-sheets-media-upload.s3.ap-south-1.amazonaws.com/" + chat?.thread_image_url?.thumbnail_img}
                                                style={{ marginTop: "5px" }} title={chat?.thread_image_url?.media_img}
                                                className="pointer" alt="image" />
                                        </a>
                                    </div>
                                </Grid>
                            }
                            <Grid item xs={12} md={chat?.thread_image_url ? 8 : 12}>
                                <div style={{ display: "flex" }}>
                                    <TextareaAutosize
                                        aria-label="empty textarea"
                                        placeholder="Write Here..."
                                        style={writeAns == true ? { background: 'lightgray', margin: '10px', padding: '5px 10px', fontFamily: '"Mulish", sans-serif', width: '-webkit-fill-available', border: 'none', borderRadius: '10px' }
                                            : { backgroundColor: 'aliceblue', margin: '10px', padding: '5px 10px', fontFamily: '"Mulish", sans-serif', width: '-webkit-fill-available', border: 'none', borderRadius: '10px' }}
                                        minRows={3}
                                        maxRows={6}
                                        className={'custom-scrollbar text-area-focus'}
                                        value={answer ? answer : chat?.answer}
                                        disabled={writeAns === true ? true : false}
                                        onChange={handleChange}
                                    />
                                    <div style={{ display: "grid", padding: '5px' }}>
                                        {/* <IconButton aria-label="delete" style={{ padding: '5px 0px 0px 0px' }} color="primary" onClick={handleClickOpen}> */}
                                        <AttachFileSharpIcon className="pointer" style={{ padding: '5px 0px 0px 0px' }} onClick={handleClickOpen} />
                                        {/* </IconButton> */}
                                        <img style={{ marginTop: "5px" }} className="pointer label-img" title="Edit" src={editIcon} alt="edit" onClick={enableAnswer} />
                                        <img style={{ marginTop: "5px" }} className="pointer label-img" title="Delete" src={deleteIcon} alt="edit" onClick={() => deleteAnswer('ans')} />
                                        <SendIcon className="pointer" onClick={sendAnswer} />
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Collapse>
            </div>
        </div>

    );
};

export default QAChatSection;
