import useStyles from '@coremat/CmtCard/CmtCardHeader.style';
// import { Button, Checkbox, TextareaAutosize } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
// import { CheckBox, HighlightOff, Send } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableServices from 'services/table';
import ChecklistDialogContent from './popupcomponents/DialogContent';
import ChecklistInput from './popupcomponents/NewCheckList';
import NewInfoChecklist from './NewInfo/DialogContent';
import NewInfoChecklistnew from './NewInfo/NewCheckList';
import DeliveryChecklist from './DeliveryNotes/DeliveryDialogContent';
import DeliveryChecklistnew from './DeliveryNotes/DeliveryNewCheckList';
const StyledDialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);
const LabelPopover = ({ labelType, childCardId, typeOfCard, label, showPopup, setShowPopup, cardId, handleCloseDialog }) => {
  const dispatch = useDispatch();
  const { checkboxData } = useSelector(({ table }) => table);
  const { newcheckboxData } = useSelector(({ table }) => table);
  const { deliverynote } =  useSelector(({ table }) => table);
  const handleOpenDialog = async () => {
    setShowPopup(true);
  
    try {
      if (typeOfCard === 'maincard') {
        dispatch(TableServices.getNeedInfo(cardId));
        if (labelType === 'New Info Added') {
          dispatch(TableServices.getNeedInfoAdded(cardId));
        } 
        if (labelType === 'Delivery Note') {
          dispatch(TableServices.getDeliveryNote(cardId));
        }
      } else if (typeOfCard === 'childcard') {
        dispatch(TableServices.getNeedInfo(childCardId));
        if (labelType === 'New Info Added') {
          dispatch(TableServices.getNeedInfoAdded(childCardId));
        }
        if (labelType === 'Delivery Note') {
          dispatch(TableServices.getDeliveryNote(childCardId));
        }
      } 
    } catch (error) {
    }
  };
  
 
  useEffect(() => {

  }, [checkboxData,newcheckboxData,deliverynote])

  return (
    <>
      {label ? (
        <img
          className="pointer label-img"
          title={labelType}
          src={label}
          alt="edit"
          onClick={handleOpenDialog}
        />
      ) : (
        <></>
      )}

      {showPopup && (labelType === 'NEED INFO' || labelType === 'Blocked by Info') ? (
        <Dialog open={true} onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" maxWidth="lg"
          style={{ marginTop: '14%' }}>
          <DialogTitle id="customized-dialog-title" className="dialog-title">
            NEED INFO
          </DialogTitle>
          <StyledDialogContent className='comments-card'>
            <ChecklistDialogContent data={checkboxData} cardId={cardId} childCardId={childCardId} typeOfCard={typeOfCard} />
            <ChecklistInput data={checkboxData} cardId={cardId} childCardId={childCardId} typeOfCard={typeOfCard}/>
          </StyledDialogContent>
        </Dialog>
      ) : <></>}
      {showPopup && (labelType === 'New Info Added') ? (
        <Dialog open={true} onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" maxWidth="lg"
          style={{ marginTop: '14%' }}>
          <DialogTitle id="customized-dialog-title" className="dialog-title">
          New Info Added
          </DialogTitle>
          <StyledDialogContent className='comments-card'>
            <NewInfoChecklist data={newcheckboxData} cardId={cardId} childCardId={childCardId} typeOfCard={typeOfCard} />
            <NewInfoChecklistnew data={newcheckboxData} cardId={cardId} childCardId={childCardId} typeOfCard={typeOfCard}/>
          </StyledDialogContent>
        </Dialog>
      ) : <></>}
      {showPopup && (labelType === 'Delivery Note') ? (
        <Dialog open={true} onClose={handleCloseDialog} aria-labelledby="customized-dialog-title" maxWidth="lg"
          style={{ marginTop: '14%' }}>
          <DialogTitle id="customized-dialog-title" className="dialog-title">
          Delivery Note
          </DialogTitle>
          <StyledDialogContent className='comments-card'>
            <DeliveryChecklist data={deliverynote} cardId={cardId} childCardId={childCardId} typeOfCard={typeOfCard} />
            <DeliveryChecklistnew data={deliverynote} cardId={cardId} childCardId={childCardId} typeOfCard={typeOfCard}/>
          </StyledDialogContent>
        </Dialog>
      ) : <></>}
    </>
  );
};

export default LabelPopover;