import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { styled, withStyles } from '@material-ui/styles';
import ProjectLabelType from './ProjectLabelType';

const StyledMenu = styled((props) => (
    <Popover
        elevation={0}
        {...props}
    />
))(() => ({
    '& .MuiPaper-root': {
        boxShadow:
            '0px 4px 4px rgba(0, 0, 0, 0.25);',
    },
}));
const CustomMenuItem = withStyles({})(MenuItem)
const CustomLabel = ({ Estimated_Hours, cardType, child_cards }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <div>
            {Estimated_Hours?.status === 'NO_CHILD_CARD' ? (
                <p className="text-truncate custom-label pointer">
                    -
                </p>
            )  : Estimated_Hours?.status === 'ZERO_TIME_ESTIMATES' ? (
                <p  style={{backgroundColor : "#949494"}} className="text-truncate custom-label pointer" onClick={handleClick}>
                    {Number(Estimated_Hours?.hours).toFixed(2)}
                </p>
            ) : Estimated_Hours.status === 'ESTIMATES_FOUND' ? (
                <p className="text-truncate custom-label pointer" onClick={handleClick}>
                    {Number(Estimated_Hours?.hours).toFixed(2)}
                </p>
            ) : Estimated_Hours.status === 'TO_DO_FINAL_REVIEW' ? (
                <p style={{backgroundColor : "#FFB905"}} className="text-truncate custom-label pointer" onClick={handleClick}>
                    {Number(Estimated_Hours?.hours).toFixed(2)}
                </p>
            ) : (
                <p className="text-truncate custom-label pointer" onClick={handleClick}>
                {Number(Estimated_Hours?.hours).toFixed(2)}
            </p>
            )}
            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}

            >
                <div className="hours-spent-popover">
                    <h3>Total Time Estimated : {Number(Estimated_Hours.hours).toFixed(2)} hrs.</h3>
                    {child_cards?.filter(itm => itm.typeOfCard === cardType).map((item, i) => {
                        return (
                            <div key={i} className="workcard-label">
                                <div style={{ margin: 'auto', padding: '5px' }}>
                                    {item?.labelNames?.map((workCardlabel, i) => {
                                        return (
                                            <ProjectLabelType
                                                key={i}
                                                labelType={workCardlabel} />
                                        )
                                    })}
                                </div>
                                <CustomMenuItem
                                    key={i}
                                    style={item?.closed ? { background: '#aeaeae', border: 0 } : {}}
                                >
                                    <>
                                        <a style={{ color: '#136fae' }} href={'https://trello.com/c/' + item?.trello_id}  rel="noreferrer" >
                                            {item.listName} :
                                        </a>
                                        <a style={{ color: 'black' }} href={'https://trello.com/c/' + item?.trello_id}  rel="noreferrer" >
                                            {item.child_card_title} - {item.Estimated_Hours} hr.
                                        </a>
                                    </>
                                </CustomMenuItem>
                            </div>
                        )
                    })}
                </div>
            </StyledMenu>
        </div>
    )
}

export default CustomLabel;