import { makeStyles, TableCell, TableRow } from '@material-ui/core'
import React from 'react'
import { useDrag, useDrop } from 'react-dnd'
// import { Toc } from "@material-ui/icons";
const DND_ITEM_TYPE = 'row'

const useStyles = makeStyles({
    root: {
        "& .MuiTableCell-root": {
            padding: '5px!important'
        }
    }
});
export default function DraggableRow({ row, index, moveRow, tableView }) {
    const dropRef = React.useRef(null)
    // const dragRef = React.useRef(null)
    const classes = useStyles();

    const [{ isOver, canDrop }, drop] = useDrop({
        accept: DND_ITEM_TYPE,
        collect(monitor) {
            return {
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop()
            };
        },
        drop(item, monitor) {
            if (!dropRef.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            // const hoverBoundingRect = dropRef.current.getBoundingClientRect()
            // Get vertical middle
            // const hoverMiddleY =
            //     (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            // const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            // const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            // if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
            //     console.log("DraggableRow--dropRef drag small--", dropRef);
            //     return
            // }
            // // Dragging upwards
            // if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
            //     console.log("DraggableRow--dropRef drag big--", dropRef);
            //     return
            // }
            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag, preview] = useDrag(() => ({
        type: DND_ITEM_TYPE,
        item: { type: DND_ITEM_TYPE, index },
        collect: monitor => ({
            isDragging: monitor.isDragging(),
        }),
    }));
    // const isActive = canDrop && isOver;
    const opacity = isDragging ? 0.2 : 1

    // const backgroundColor = isActive ? "darkgreen" : "aliceblue";
    if (tableView === 'CustomOrder' || tableView === 'CustomOrder2' || tableView === 'DeliveredReadyProject') {
        preview(drag(drop(dropRef)));
    }

    // preview(drop(dropRef))
    // drag(dragRef)
    return (

        <TableRow className={classes.root} ref={dropRef} style={row?.original?.child_cards?.reduce((n, { closed }) => n && closed, true) ?
            { opacity } : { opacity }} {...row.getRowProps()}>
            {/* {
                (tableView === 'CustomOrder' || tableView === 'CustomOrder2' || tableView === 'DeliveredReadyProject') &&
                <TableCell
                    style={{
                        cursor: 'pointer',
                    }} ref={dragRef}><Toc /></TableCell>
            } */}
            {row.cells.map(cell => {
                let parseCheckboxFlag = false;
                let findCheckboxFlag = true;
                //every function.
                // arr1 = [false, true, true] --- Flag :false
                // arr2 = [true, true, true] --- Flag :true
                if (cell?.column?.id === "Comment") {
                    parseCheckboxFlag = cell?.render('Cell')?.props?.data?.map((item) => {
                        // if all items is_all_checked is true then flag is true
                        findCheckboxFlag = item.threads.every((v) => v.is_all_checked === true)
                    })
                }
                return <TableCell
                    className={((cell?.column?.id === "MechWorkCardLabels") || (cell?.column?.id === "Mech_Status") || (cell?.column?.id === "Estimated_Mech_Hours") || (cell?.column?.id === "Mechanical_Last_Member") || (cell?.column?.id === "Manager_Innitial_Mechanical")) ? "Mech-color" :
                        ((cell?.column?.id === "ElectWorkCardLabels") || (cell?.column?.id === "Elec_Status") ||
                            (cell?.column?.id === "Estimated_Elec_Hours") ||
                            (cell?.column?.id === "Electrical_Last_Member") ||
                            (cell?.column?.id === "Manager_Innitial_Electrical")) ? "Elec-color" :
                            ((cell?.column?.id === "PlumWorkCardLabels") ||
                                (cell?.column?.id === "Plum_Status") ||
                                (cell?.column?.id === "Estimated_Plum_Hours") ||
                                (cell?.column?.id === "Plumbing_Last_Member") ||
                                (cell?.column?.id === "Manager_Innitial_Plumbing")
                            ) ? "Plum-color": ""} {...cell.getCellProps()}
                            //  :
                            //     ((cell?.column?.id === "Comment" && findCheckboxFlag === false)) ? "Comment-color" : ""} {...cell.getCellProps()}
                >{cell.render('Cell')}</TableCell>
            })}
        </TableRow>

    )
}