import React, { useState } from 'react';
import { Checkbox } from '@material-ui/core';
import TableServices from 'services/table';
import { useDispatch } from 'react-redux';

const ChecklistCheckbox = ({ item, data, cardId, typeOfCard, childCardId }) => {

    // console.log("item,", item,);
    const [isChecked, setIsChecked] = useState(item.state === 'complete');
    const dispatch = useDispatch();
    const handleCheckboxChange = async () => {
        try {
            const newState = isChecked ? 'incomplete' : 'complete';
            const selectedCardId = typeOfCard === 'maincard' ? cardId : childCardId;
            
            const payload = {
                cardId: selectedCardId,
                checkListId: item.idChecklist,
                checkItemId: item.id,
                state: newState,
            };
            
            // console.log("payload", payload);
            
            setIsChecked(newState === 'complete');
            
            await dispatch(TableServices.updateChecklist(payload, selectedCardId));
        } catch (error) {
            setIsChecked(!isChecked);
        }
    };
    


    return (
        <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginTop: '10px' }}>
            <Checkbox style={{color: '#216ba5'}} checked={isChecked} item={item} onChange={handleCheckboxChange} childCardId={childCardId} typeOfCard={typeOfCard} />
        </div>
    );
};

export default ChecklistCheckbox;
