import { Checkbox, TextareaAutosize } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import TableServices from "services/table";
import { AppTheme } from "theme/customeTheme";
import deleteIcon from "../../../../img/delete.webp";
import editIcon from "../../../../img/Edit.webp";
import ChatFooter from "./ChatFooter";

const colors = [
    "#D7D7D7",
    "#FFC0C0",
    "#C0D9FF",
    "#FFC0F9",
    "#D7D7D7",
    "#D7D7D7",
];
const defaultStyle = {
    width: '552px',
    height: '45px',
    backgroundColor: 'white',
    border: '1px solid #ececec',
    margin: '20px',
    borderRadius: '5px',
    fontSize: '16px'
};
// const defaultMentionStyle = {
//     fontWeight: "600",
//     backgroundColor: "#cee4e5",
//     margin: "5px",
// };
const ConversationChatSection = ({ chat, TitleInfo }) => {
    // console.log("chat", chat);
    const [isChecked, setIsChecked] = React.useState(chat?.is_checked || false);
    const [isEditMode, setIsEditMode] = React.useState(false);
    const dispatch = useDispatch();
    const userData = JSON.parse(localStorage.getItem("userInfo"));
    const deleteAnswer = (MessageType) => {
        const idToSend = chat.id ? chat.id : chat._id;
        dispatch(TableServices.deleteFAQ(idToSend, MessageType));
    };
    const editChat = () => {
        setIsEditMode(!isEditMode);
    }
    const handleIsCheked = () => {
        setIsChecked(!isChecked)
        const idToSend = chat.id ? chat.id : chat._id;
        dispatch(TableServices.updateCheckBoxInChat(idToSend, !isChecked));
    }
    return (
        <div>
            <div key={"Conversation-" + (chat.id || chat._id)} className="comments-card">
                {/* {console.log("matches>>>>", userData.userName ,"==" , chat)} */}
                {userData.userName == chat?.createdBy?.userName ? (
                    <Avatar
                        title={chat?.createdBy?.fullName}
                        className="my-avatar"
                        style={{ backgroundColor: "#C0D9FF", color: "#00057C" }}
                    >
                        {/* {console.log("matches", userData.userName == chat?.createdBy?.userName)} */}
                        {" "}
                        {chat?.createdBy?.innitials}
                    </Avatar>
                ) :
                    (
                        <Avatar
                            className="avatar"
                            title={chat?.createdBy?.fullName}
                            style={{
                                backgroundColor: colors[1]
                                // colors[Math.floor(Math.random() * colors.length)]
                                ,
                                color: AppTheme.colors.primary,
                            }}
                        >
                            {" "}
                            {chat?.createdBy?.innitials}
                        </Avatar>
                    )}
                <div className="comments-message-card" style={{ display: 'flex' }}>
                    <div style={{ display: 'grid' }}>
                        <Checkbox
                            style={{ color: '#216ba5' }}
                            checked={isChecked}
                            onChange={handleIsCheked}
                        />
                    </div>
                    <div style={{ display: 'grid', minWidth: '500px' }}>
                        {/* {console.log("chatmessage",chat)} */}
                        {
                            isEditMode === false ? (
                                <Typography gutterBottom className="comments-message">
                                    {/* {console.log("chat.message",chat.message)} */}
                                    <div style={{ paddingTop: "10px", textDecoration: isChecked ? 'line-through' : 'none' }}>{chat.message}</div>
                                    {
                                        chat?.thread_question_image_url &&
                                        <a target="blank" style={{ color: '#202124' }} href={"https://priority-sheets-media-upload.s3.ap-south-1.amazonaws.com/" + chat?.thread_question_image_url?.media_img}>
                                            <img src={"https://priority-sheets-media-upload.s3.ap-south-1.amazonaws.com/" + chat?.thread_question_image_url?.thumbnail_img}
                                                style={{ marginTop: "5px" }} title={chat?.thread_question_image_url?.media_img}
                                                className="pointer" alt="image" />
                                        </a>
                                    }
                                </Typography>
                            ) : (
                                <ChatFooter cardType={TitleInfo} editMode={isEditMode} chatData={chat} editChat={editChat} />
                                // <TextareaAutosize
                                //     aria-label="empty textarea"
                                //     placeholder="Write Here..."
                                //     style={isEdit == true ? { background: 'lightgray', margin: '10px', padding: '5px 10px', fontFamily: '"Mulish", sans-serif', width: '-webkit-fill-available', border: 'none', borderRadius: '10px' }
                                //         : { backgroundColor: 'aliceblue', margin: '10px', padding: '5px 10px', fontFamily: '"Mulish", sans-serif', width: '-webkit-fill-available', border: 'none', borderRadius: '10px' }}
                                //     minRows={3}
                                //     maxRows={6}
                                //     className={'custom-scrollbar text-area-focus'}
                                //     value={chat.message}
                                //     disabled={isEdit === true ? false : true}
                                //     // onChange={handleChange}
                                // />
                            )
                        }
                    </div>
                    <div style={{ margin: "10px" }}>
                        <img style={{ marginTop: "12px" }} className="pointer label-img" title="Delete" src={deleteIcon} alt="edit" onClick={() => deleteAnswer('chat')} />
                    </div>
                    <div style={{ margin: "10px" }}>
                        <img style={{ marginTop: "12px" }} className="pointer label-img" title="Edit" src={editIcon} alt="edit" onClick={() => editChat()} />
                    </div>
                </div>

                <Typography style={{ marginLeft: '24px', fontSize: '12px', fontStyle: 'italic' }}>Checked by: {chat?.checkedBy}</Typography>
                <Typography
                    gutterBottom
                    className="comments-date"
                    variant="h6"
                    style={{ marginTop: '-16px' }}
                >
                    {moment(chat?.updatedAt).format("DD-MMM-YY hh:mm")}
                </Typography>
            </div>
        </div>

    );
};

export default ConversationChatSection;
