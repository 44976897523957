import { DefaultColumnFilter } from "@jumbo/components/TableComponents/table-utils";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import update from "immutability-helper";
import { matchSorter } from "match-sorter";
import React, { forwardRef, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ColoredBadge from "@jumbo/components/TableComponents/HoursBg";
import {NotificationManager,} from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  useAsyncDebounce,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useSticky } from "react-table-sticky";
import { UpdateTableData } from "redux/actions/Table.action";
import TableServices from "services/table";
import { AppTheme } from "theme/customeTheme";
import dragIcon from "../../../assets/drag.svg";
import CommentCardColor from "./CommentCardLegend";
import DraggableRow from "./DraggableRow";
import ProjectCardColor from "./ProjectCardLegend";
import TablePagination from "./TablePagination";
const useStyles = makeStyles(() => ({
  root: {
    marginTop: "1rem",
    "& .MuiPaginationItem-icon": {
      color: "#3F7786",
    },
    "& .MuiTableCell-root": {
      textAlign: "center",
      padding: "12px",
      fontFamily: '"Mulish", sans-serif',
      fontWeight: 600,
      minWidth: "153px",
      outline: "none",
    },
    '& .MuiPaginationItem-outlined[aria-label="Go to next page"], & .MuiPaginationItem-outlined[aria-label="Go to previous page"], & .MuiPaginationItem-outlined[aria-label="Go to first page"], & .MuiPaginationItem-outlined[aria-label="Go to last page"]': {
      backgroundColor: "#DBE7EA",
      border: 0,
    },
    "& .MuiPaginationItem-page.Mui-selected": {
      borderColor: "#3F7786",
    },
    "& .MuiPaginationItem-ellipsis": {
      border: "1px solid rgba(0, 0, 0, 0.23)",
      borderRadius: "4px",
      height: "32px",
    },
    "& .MuiSelect-select:focus": {
      borderRadius: 4,
    },
    "& .MuiTableCell-head": {
      color: AppTheme.colors.black,
      fontWeight: 600,
      outline: "none",
    },
  },
  Pagination: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
const DragIcon = (column) => {
  return column === "Sr_NO" ||
    column === "card_title" ||
    column === "DeliveryDate" ||
    column === "selection" ||
    column === "id" ||
    column === "action" ||
    column === "Comment" ? (
    ""
  ) : (<img src={dragIcon} alt="drag" className="ml-2 cursor-grab" />);};
const getItemStyle = ({ isDragging, isDropAnimating }, draggableStyle) => ({
  ...draggableStyle,
  userSelect: "none",
  background: "#EFD0C2!important",
  ...(!isDragging && { transform: "translate(0,0)" }),
  ...(isDropAnimating && { transitionDuration: "0.001s" }),});
function fuzzyTextFilterFn(rows, id, filterValue) {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
}
fuzzyTextFilterFn.autoRemove = (val) => !val;
const ReactMainTable = forwardRef(({
  offset,
  tableView,
  columns,
  data,
  applyFilters,
  itemsPerPage,
  currentPage,
  TotalLength,
  getPageData,
  orderByColumns,
  lastLockedIndex,
  rightLockedIndex,
  updateCallBack }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const initialState = {
    selectedFlatRows: [],
    pageIndex: currentPage,
    pageSize: itemsPerPage,
  };
  const { totalHours } = useSelector(({ table }) => table);
  const {
    getTableProps,
    getTableBodyProps,
    rows,
    prepareRow,
    visibleColumns,
    allColumns,
    setColumnOrder,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize, filters },
  } = useTable(
    {
      columns,
      data,
      initialState,
      defaultColumn,
      filterTypes,
      manualFilters: true,
      manualPagination: true,
      pageCount: Math.ceil(TotalLength / itemsPerPage),
      initialState: {
        ...initialState,
      },
    },
    useColumnOrder,
    useFilters,
    useGlobalFilter, 
    useSortBy,
    useSticky,
    usePagination
  );
  const handleChange = (e, p) => {
    gotoPage(p - 1);
  };
  const onNumberPagesChanged = (val) => {
    setPageSize(Number(val));
    gotoPage(0);
  };
  const onApplyFilter = useAsyncDebounce(() => {
    applyFilters(filters);
  }, 200);
  useEffect(() => {
    onApplyFilter();
  }, [filters]);
  useEffect(() => {
    getPageData(pageIndex, pageSize);
  }, [pageSize, pageIndex]);
  const currentColOrder = React.useRef();
  const moveRow = (dragIndex, hoverIndex) => {
    const dragRecord = data[dragIndex];
    dispatch(
      UpdateTableData(
        update(data, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, dragRecord],
          ],
        })
      )
    );
    const hoverRecord = data[hoverIndex];
    let finalCustomOrder = {};
    if (tableView === "CustomOrder2") {
      console.log("CustomOrder2 dragRecord::", dragRecord?.projectMetaData);
      console.log("CustomOrder2 hoverRecord::", hoverRecord?.projectMetaData);
      if (dragRecord?.deliveryDate !== hoverRecord?.deliveryDate) {
        NotificationManager.warning(
          "You can change order within same date",
          "Invalid action",
          5000
        );
      } else {
        if (dragIndex < hoverIndex) {
          finalCustomOrder = {
            old_order:
              dragRecord?.projectMetaData &&
                Array.isArray(dragRecord?.projectMetaData)
                ? dragRecord?.projectMetaData?.[0]?.order2
                : dragRecord?.projectMetaData?.order2,
            new_order:
              hoverRecord?.projectMetaData &&
                Array.isArray(hoverRecord?.projectMetaData)
                ? hoverRecord?.projectMetaData?.[0]?.order2
                : hoverRecord?.projectMetaData?.order2,
            trello_id:
              dragRecord?.projectMetaData &&
                Array.isArray(hoverRecord?.projectMetaData)
                ? dragRecord?.projectMetaData?.[0]?.trello_id
                : dragRecord?.projectMetaData?.trello_id,
          };
        }

        if (hoverIndex < dragIndex) {
          finalCustomOrder = {
            old_order:
              dragRecord?.projectMetaData &&
                Array.isArray(dragRecord?.projectMetaData)
                ? dragRecord?.projectMetaData?.[0]?.order2
                : dragRecord?.projectMetaData?.order2,
            new_order:
              hoverRecord?.projectMetaData &&
                Array.isArray(hoverRecord?.projectMetaData)
                ? hoverRecord?.projectMetaData?.[0]?.order2
                : hoverRecord?.projectMetaData?.order2,
            trello_id:
              dragRecord?.projectMetaData &&
                Array.isArray(hoverRecord?.projectMetaData)
                ? dragRecord?.projectMetaData?.[0]?.trello_id
                : dragRecord?.projectMetaData?.trello_id,
          };
        }
      }
    }
    else {
      if (dragRecord?.deliveryDate !== hoverRecord?.deliveryDate) {
        NotificationManager.warning(
          "You can change order within same date",
          "Invalid action",
          5000
        );
      } else {
        if (dragIndex < hoverIndex) {
          finalCustomOrder = {
            old_order:
              dragRecord?.projectMetaData &&
                Array.isArray(dragRecord?.projectMetaData)
                ? dragRecord?.projectMetaData?.[0]?.order
                : dragRecord?.projectMetaData?.order,
            new_order:
              hoverRecord?.projectMetaData &&
                Array.isArray(hoverRecord?.projectMetaData)
                ? hoverRecord?.projectMetaData?.[0]?.order
                : hoverRecord?.projectMetaData?.order,
            trello_id:
              dragRecord?.projectMetaData &&
                Array.isArray(hoverRecord?.projectMetaData)
                ? dragRecord?.projectMetaData?.[0]?.trello_id
                : dragRecord?.projectMetaData?.trello_id,
          };
        }

        if (hoverIndex < dragIndex) {
          finalCustomOrder = {
            old_order:
              dragRecord?.projectMetaData &&
                Array.isArray(dragRecord?.projectMetaData)
                ? dragRecord?.projectMetaData?.[0]?.order
                : dragRecord?.projectMetaData?.order,
            new_order:
              hoverRecord?.projectMetaData &&
                Array.isArray(hoverRecord?.projectMetaData)
                ? hoverRecord?.projectMetaData?.[0]?.order
                : hoverRecord?.projectMetaData?.order,
            trello_id:
              dragRecord?.projectMetaData &&
                Array.isArray(hoverRecord?.projectMetaData)
                ? dragRecord?.projectMetaData?.[0]?.trello_id
                : dragRecord?.projectMetaData?.trello_id,
          };
        }
      }
    }
    dispatch(
      TableServices.updateCustomOrder(finalCustomOrder, tableView, () => {
        updateCallBack();
      })
    );
  };
  return (
    <React.Fragment>
      <div
        style={{ display: "flex", flexDirection: "column" }}
        className="table-container"
      >
        <DndProvider backend={HTML5Backend}>
          <TableContainer
            className={clsx(
              classes.root,
              "custom-scrollbar",
              "table-responsive"
            )}
          >
            <Table
              {...getTableProps()}
              stickyHeader
              aria-label="sticky table"
              style={{
                border: "none",
                borderSpacing: "0",
                width: "100%",
                position: "relative",
              }}
            >
              <TableHead>
                <DragDropContext
                  onDragStart={(e) => {
                    console.log("debug onDragStart", e);

                    currentColOrder.current = allColumns?.map((o) => o.id);
                  }}
                  onDragUpdate={(dragUpdateObj, b) => {
                    console.log("debug onDragUpdate", dragUpdateObj, b);

                    const colOrder = [...currentColOrder.current];
                    const sIndex = dragUpdateObj.source.index;
                    const dIndex =
                      dragUpdateObj.destination &&
                      dragUpdateObj.destination.index;

                    if (
                      typeof sIndex === "number" &&
                      typeof dIndex === "number"
                    ) {
                      colOrder.splice(sIndex, 1);
                      colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
                      console.log(
                        "debug colOrder lastLockedIndex dragUpdateObj.source.index",
                        colOrder,
                        lastLockedIndex,
                        dragUpdateObj.destination.index
                      );
                      if (
                        lastLockedIndex < dragUpdateObj.destination.index &&
                        rightLockedIndex > dragUpdateObj.destination.index
                      )
                        setColumnOrder(colOrder);
                    }
                  }}
                >
                  <Droppable droppableId={"droppable"} direction="horizontal">
                    {(droppableProvided, snapshot) => {
                      return (
                        <TableRow
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          {visibleColumns.map((column, headerIndex) => (
                            <Draggable
                              draggableId={column.id}
                              key={column.id}
                              index={headerIndex}
                              isDragDisabled={
                                !column.accessor ||
                                column.sticky === "left" ||
                                column.sticky === "right"
                              }
                            >
                              {(provided, snapshot) => {
                                return (
                                  <TableCell
                                    {...column.getHeaderProps()}
                                    className={`th align-middle border-top-0 border-bottom-0 ${snapshot.isDragging ? "shadow-sm" : ""} ${snapshot.isDragging ? "" : "fixed-column-" + headerIndex}`}
                                    onClick={() => orderByColumns(column.id)}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    ref={provided.innerRef}
                                    style={{
                                      ...getItemStyle(
                                        snapshot,
                                        provided.draggableProps.style
                                      ),
                                      backgroundColor: "#FFF6F6",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        margin: "0 auto",
                                      }}
                                      className="custom-column-width"
                                    >
                                      <a
                                        {...column.getHeaderProps(
                                          column.getSortByToggleProps()
                                        )}
                                        style={{
                                          left: "auto",
                                          backgroundColor: "#FFF6F6",
                                        }}
                                      >
                                        {column.render("Header")}
                                      </a>
                                      {column.id === "name" && <ProjectCardColor />}
                                      {column.id === "Comment" && <CommentCardColor />}
                                      {DragIcon(column.id)}
                                    </div>
                                  </TableCell>
                                );
                              }}
                            </Draggable>
                          ))}
                          {droppableProvided.placeholder}
                        </TableRow>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
                {/* ))} */}
                
                <TableRow className="tr">
                  {visibleColumns.map((column, index) => (
                    <TableCell
                      {...column.getHeaderProps()}
                      className={"th align-middle border-top-0 border-bottom-0 fixed-column-" + index}
                      style={{
                        backgroundColor: "none",
                        top: "45px",
                      }}
                    >
                      <div>
                        {column.canFilter ? column.render("Filter") : null}{column.id === "Estimated_Mech_Hours" && (
                          <ColoredBadge totalHours={totalHours?.TotalMechHours} />
                        )}
                        {column.id === "Estimated_Plum_Hours" && (
                          <ColoredBadge totalHours={totalHours?.TotalPlumHours} />
                        )}
                        {column.id === "Estimated_Elec_Hours" && (
                          <ColoredBadge totalHours={totalHours?.TotalElecHours} />
                        )}
                      </div>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody {...getTableBodyProps()}>
                {rows.map((row, i) => {
                  return (
                    prepareRow(row) || (
                      <DraggableRow
                        index={i}
                        row={row}
                        moveRow={moveRow}
                        {...row.getRowProps()}
                        tableView={tableView} />
                    )
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DndProvider>
      </div>
      <TablePagination
        classes={classes}
        offset={offset}
        pageIndex={pageIndex}
        pageSize={pageSize}
        TotalLength={TotalLength}
        handleChange={handleChange}
        onNumberPagesChanged={onNumberPagesChanged}
      />
    </React.Fragment>
  );
}
);
export default ReactMainTable;
