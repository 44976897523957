import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Switch from "@material-ui/core/Switch";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import QuestionMarkIcon from "@material-ui/icons/HelpOutline";
// import SettingsIcon from "@material-ui/icons/Settings";
import InfoIcon from '@material-ui/icons/Info';
import * as React from "react";
// import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px 10px",
    borderRadius: 5,
    border: "1px solid #3F7786;",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);",
  },
}));
// const BMMSwitch = withStyles((theme) => ({
//   root: {
//     width: 26,
//     height: 16,
//     padding: 0,
//     margin: theme.spacing(1),
//   },
//   switchBase: {
//     padding: 1,
//     "&$checked": {
//       transform: "translateX(11px)",
//       color: "#ffffff !important",
//       "& + $track": {
//         backgroundColor: "#52d869 !important",
//         opacity: 1,
//         border: "none",
//       },
//     },
//     "&$focusVisible $thumb": {
//       //   color: '#52d869',
//       border: "6px solid #fff",
//     },
//   },
//   thumb: {
//     width: 13,
//     height: 13,
//   },
//   track: {
//     borderRadius: 26 / 2,
//     border: `1px solid ${theme.palette.grey[400]}`,
//     backgroundColor: theme.palette.grey[50],
//     opacity: 1,
//     transition: theme.transitions.create(["background-color", "border"]),
//   },
//   checked: {},
//   focusVisible: {},
// }))(({ classes, ...props }) => {
//   return (
//     <Switch
//       focusVisibleClassName={classes.focusVisible}
//       disableRipple
//       classes={{
//         root: classes.root,
//         switchBase: classes.switchBase,
//         thumb: classes.thumb,
//         track: classes.track,
//         checked: classes.checked,
//       }}
//       {...props}
//     />
//   );
// });

// // const SwitchListItem = ({ column, index, setHideColumn, scrollableTableHiddenColumns }) => {
// const ProjectColor = () => {
//   // console.log("SwitchListItem column::", column);

//   return (
//     <div
//       style={{
//         display: "flex",
//         justifyContent: "space-between",
//         alignItems: "center",
//       }}
//     >
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         {/* <p className="label mb-0">Hour A Day</p> ={" "}
//         <p className="label mb-0">Red</p> */}
//       </div>
//     </div>
//   );
// };
export default function CommentCardColor(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <IconButton
        aria-label="delete"
        title="Project Card Colors"
        className="icon-class"
        onClick={handleClick}
        style={{ padding: 4 }}
      >
        <InfoIcon style={{ fill: "#006d8f",  height:'17px' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={9}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        <FormControl
          component="fieldset"
          className="settings-menu"
          onClick={handleClick}
        >
          <FormGroup aria-label="position" row={false}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="label mb-0">All</p>
              <div style={{ textAlign: "end" }}>
                <FiberManualRecordIcon
                  style={{ fill: "#ff8484", alignItems: "center" }}
                  className="label-img"
                />
                <QuestionMarkIcon
                  titleAccess="All"
                  style={{ color: "#ff8484" }}
                  className="label-img"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="label mb-0">PM</p>
              <div style={{ textAlign: "end" }}>
                <FiberManualRecordIcon
                  style={{ fill: "#cd86f9" }}
                  className="label-img"
                />
                <QuestionMarkIcon
                  titleAccess="PM"
                  style={{ color: "#cd86f9" }}
                  className="label-img"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="label mb-0">Mechanical</p>
              <div style={{ textAlign: "end" }}>
                <FiberManualRecordIcon
                  style={{ fill: "#90EE90" }}
                  className="label-img"
                />
                <QuestionMarkIcon
                  titleAccess="Mechanical"
                  style={{ color: "#90EE90" }}
                  className="label-img"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="label mb-0">Electrical</p>
              <div style={{ textAlign: "end" }}>
                <FiberManualRecordIcon
                  style={{ fill: "#f6d26f" }}
                  className="label-img"
                />
                <QuestionMarkIcon
                  titleAccess="Electrical"
                  style={{ color: "#f6d26f" }}
                  className="label-img"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <p className="label mb-0">Plumbing</p>
              <div style={{ textAlign: "end" }}>
                <FiberManualRecordIcon
                  style={{ fill: "#87CEEB" }}
                  className="label-img"
                />
                <QuestionMarkIcon
                  titleAccess="Plumbing"
                  style={{ color: "#87CEEB" }}
                  className="label-img"
                />
              </div>
            </div>
          </FormGroup>
        </FormControl>
      </Popover>
    </div>
  );
}
