import React, { useEffect, useState } from 'react';
import { trelloLabels } from "theme/trelloLabels";
// import ProjectLabelPopover from './LabletypePopover';
import hourglass from "../../../img/Hour a day.webp";
// import report from "../../../img/report.webp";
import createPdf from "../../../img/create pdf.webp";
import deliveryNote from "../../../img/delivery note.webp";
import internalReview from "../../../img/internal review.webp";
import WaitingforPayments from "../../../img/mdi_payment-clock.webp";
import IPR from "../../../img/mdi_tab-find (1).webp";
import needInfo from "../../../img/need info.webp";
import newInfoAdded from "../../../img/new info added.webp";
import onHold from "../../../img/on hold.webp";
import partialDelivery from "../../../img/partial delivery.webp";
import pc from "../../../img/pc.webp";
import revision from "../../../img/revision.webp";
// import { useDispatch } from "react-redux";
import bc from "../../../img/bc.webp";
import blockedByInfo from "../../../img/blocked by info.webp";
import blockedByTrade from "../../../img/blocked by trade.webp";
import ps from "../../../img/ps.webp";
import qdcEle from "../../../img/qdc ele.webp";
import qdcMech from "../../../img/qdc mech.webp";
import qdcPlum from "../../../img/qdc plumb.webp";
// import edit from "../../../img/edit.webp";
import elec_T24 from "../../../img/et.webp";
import mech_T24 from "../../../img/mt.webp";
import notApproved from "../../../img/notApproved.webp";
import prelimpayment from "../../../img/payment (1).png";
import fullpayment from "../../../img/payment.png";
import PEC from "../../../img/status btn 10.webp";
import LabelPopover from "./LabletypePopover";
const labelImages = {
  [trelloLabels.labelName.HourADay]: hourglass,
  [trelloLabels.labelName.InternalReview]: internalReview,
  [trelloLabels.labelName.PartialDelivery]: partialDelivery,
  [trelloLabels.labelName.PC]: pc,
  [trelloLabels.labelName.BlockedByInfo]: blockedByInfo,
  [trelloLabels.labelName.BlockedByTrade]: blockedByTrade,
  [trelloLabels.labelName.OnHold]: onHold,
  [trelloLabels.labelName.Electrical]: qdcEle,
  [trelloLabels.labelName.ElectricalT24]: elec_T24,
  [trelloLabels.labelName.Mechanical]: qdcMech,
  [trelloLabels.labelName.MechanicalT24]: mech_T24,
  [trelloLabels.labelName.Plumbing]: qdcPlum,
  [trelloLabels.labelName.NewInfoAdded]: newInfoAdded,
  [trelloLabels.labelName.Revision]: revision,
  [trelloLabels.labelName.BC]: bc,
  [trelloLabels.labelName.CreatePDF]: createPdf,
  [trelloLabels.labelName.PS]: ps,
  [trelloLabels.labelName.DeliveryNote]: deliveryNote,
  [trelloLabels.labelName.NeededInfo]: needInfo,
  [trelloLabels.labelName.NotApproved]: notApproved,
  [trelloLabels.labelName.WaitingforPayments]: WaitingforPayments,
  [trelloLabels.labelName.IPR]: IPR,
  [trelloLabels.labelName.PEC]: PEC,
  [trelloLabels.labelName.FullPayment]: fullpayment,
  [trelloLabels.labelName.PrelimPayment]: prelimpayment
};

const ProjectLabelType = ({ labelType, cardId, childCardId, typeOfCard }) => {
  const [label, setLabel] = useState('');
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    setLabel(labelImages[labelType] || '');
  }, [labelType]);

  const handleLabelClick = () => {
    if (labelType === trelloLabels.labelName.NeededInfo) {
      setShowPopup(!showPopup);
    }
    if (labelType === trelloLabels.labelName.BlockedByInfo) {
      setShowPopup(!showPopup);
    }
  };

  const handleCloseDialogBox = () => {
    setShowPopup(false);
  };

  return (
    <LabelPopover
      labelType={labelType}
      typeOfCard={typeOfCard}
      childCardId={childCardId}
      label={label}
      showPopup={showPopup}
      cardId={cardId}
      handleLabelClick={handleLabelClick}
      setShowPopup={setShowPopup}
      handleCloseDialog={handleCloseDialogBox}
    />
  );
};

export default ProjectLabelType;
