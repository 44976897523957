import React from 'react';
import ProjectLabelType from './ProjectLabelType';

const LabelHighlighters = ({ labelNames, cardId, name, typeOfCard }) => {
    if (!labelNames || labelNames.length === 0) {
        return <ProjectLabelType labelType={null} cardId={cardId} name={name} typeOfCard={typeOfCard} />;
    }

    return (
        <>
            {labelNames.map((label, id) => (
                <ProjectLabelType key={id} labelType={label} cardId={cardId} name={name} typeOfCard={typeOfCard} />
            ))}
        </>
    );
}

export default LabelHighlighters;