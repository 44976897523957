import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { useLocation } from "react-router-dom";
import { setAuthUser } from "redux/actions/Auth";
import ForgotPasswordPage from "./Auth/ForgotPassword";
import Login from "./Auth/Login";
import Register from "./Auth/Register";
import Error404 from "./Pages/404";
import DashboardPage from "./Pages/Dashboard";
import MyReviewReactTable from "./Pages/MyReviewReactTable";
import ReactTable from "./Pages/React Table";
import FrontApp from "./Pages/FrontApp";
const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        (authUser || localStorage.getItem('userInfo')) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location }
            }}
          />
        )}
    />
  );
};

const GuestRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return <Route {...rest} render={props => (authUser ? props?.history.goBack() : <Component {...props} />)} />;
};
const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect ( () => {
   if(localStorage.getItem('userInfo')) {
      dispatch(setAuthUser(JSON.parse(localStorage.getItem('userInfo'))))
    }
  },[]);
  
  if (location.pathname === "" || location.pathname === "/") {
    return <Redirect to={"/dashboard"} />;
  } else if (authUser && location.pathname === "/signin") {
    return <Redirect to={"/dashboard"} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/dashboard" component={DashboardPage} />
        <RestrictedRoute path="/table" component={ReactTable} />
        <RestrictedRoute path="/my-review" component={MyReviewReactTable} />
        <RestrictedRoute path="/front-app" component={FrontApp} />
        <GuestRoute path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
