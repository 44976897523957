import React, { useEffect, useState } from 'react';
// import { AppTheme } from "theme/customeTheme";
// import MenuItem from '@material-ui/core/MenuItem';
// import Popover from '@material-ui/core/Popover';
// import { styled, withStyles } from '@material-ui/styles';
// import ProjectLabelType from './ProjectLabelType';
import { trelloLabels } from 'theme/trelloLabels';
import { useDispatch, useSelector } from 'react-redux';
import TableServices from 'services/table';

// const StyledMenu = styled((props) => (
//     <Popover
//         elevation={0}
//         {...props}
//     />
// ))(({ }) => ({
//     '& .MuiPaper-root': {
//         boxShadow:
//             '0px 4px 4px rgba(0, 0, 0, 0.25);',
//     },
// }));
// const CustomMenuItem = withStyles({
//     root: {
//         fontFamily: '"Poppins", sans-serif',
//         color: "#4E4E4E",
//         fontSize: 16,
//         padding: "10px 15px",
//         borderRadius: 16,
//         margin: 16,
//         letterSpacing: 0.2,
//         minHeight: "auto",
//         fontWeight: 500,
//         border: "4px solid #96C0FF",
//         fontStyle: "italic",
//         whiteSpace: "nowrap",
//         overflow: "hidden",
//         textOverflow: "ellipsis",
//         // '&:hover': {
//         //     backgroundColor: AppTheme.colors.primary,
//         //     color: "#FFFFFF"
//         //     // borderRadius: 5,
//         // }
//     }
// })(MenuItem)
const CustomStatus = ({  cardType, child_cards }) => {
    // console.log("child_cards",child_cards);
    const [previousCardType, setPreviousCardType] = React.useState(null);
    const { allstatus } = useSelector(({ table }) => table);
    const [selectedValues, setSelectedValues] = useState({});
    // console.log("allstatus", allstatus);
    const dispatch = useDispatch();
    // useEffect(() => {
    //     dispatch(TableServices.getAllStatus());
    // });
    useEffect(() => {
        const initialSelectedValues = {};
        child_cards.forEach((card) => {
            const listName = card.listName;
            const existingStatus = allstatus.find((status) => status.value === card.idList);

            if (existingStatus) {
                initialSelectedValues[listName] = existingStatus.value;
            } else {
            }
        });

        setSelectedValues(initialSelectedValues);
    }, [child_cards, allstatus]);
    const handleSelectChange = async (event, listName) => {
        // console.log("listName", listName);
        // console.log("Dropdown selected:", event.target.value);
        const newValue = event.target.value;
        setSelectedValues({
            ...selectedValues,
            [listName]: newValue,
        });

        const cardTrelloId = child_cards.find(card => card.typeOfCard === cardType)?.trello_id;
        // console.log("cardTrelloId", cardTrelloId);
        if (cardTrelloId) {
            const updateStatusPayload = {
                cardTrelloId: cardTrelloId,
                targetListId: newValue,
            };

            // console.log("cardTrelloId", updateStatusPayload);
            try {
                await dispatch(TableServices.updateStatus(updateStatusPayload));
            } catch (error) {
                console.error('Error updating status:', error);
                // Handle the error gracefully, e.g., show an error message to the user.
            }
        }
    };


    const getUniqueListName = (array) => {
        let uniqueList = [];
        for (let index = 0; index < array?.length; index++) {
            const sub = array[index]?.listName;
            if (cardType === previousCardType) {
                if ((array[index].typeOfCard === cardType)) {
                    const findIndex = uniqueList.findIndex((e) => e === sub)
                    if (findIndex === -1) {
                        uniqueList.push(sub)
                    }
                }
            }
            else {
                setPreviousCardType(cardType);
                uniqueList = [];
            }

        }
        return uniqueList;
    }
    const getGradientColor = (child_cards) => {
        const findLabels = child_cards?.filter((e) => e.typeOfCard === cardType);
        if (findLabels?.[0]?.listName === 'Completed (PM)') {
            return "gradient-completed"
        }
        else if (findLabels?.[0]?.labelNames?.includes(trelloLabels.labelName.BlockedByInfo) &&
            findLabels?.[0]?.labelNames?.includes(trelloLabels.labelName.NeededInfo) &&
            findLabels?.[0]?.labelNames?.includes(trelloLabels.labelName.BlockedByTrade)) {
            return "gradient-123"
        }
        else if (findLabels?.[0]?.labelNames?.includes(trelloLabels.labelName.BlockedByInfo) &&
            findLabels?.[0]?.labelNames?.includes(trelloLabels.labelName.NeededInfo)) {
            return "gradient-12"
        }
        else if (findLabels?.[0]?.labelNames?.includes(trelloLabels.labelName.BlockedByInfo) &&
            findLabels?.[0]?.labelNames?.includes(trelloLabels.labelName.BlockedByTrade)) {
            return "gradient-13"
        }
        else if (findLabels?.[0]?.labelNames?.includes(trelloLabels.labelName.NeededInfo) &&
            findLabels?.[0]?.labelNames?.includes(trelloLabels.labelName.BlockedByTrade)) {
            return "gradient-23"
        }
        else if (findLabels?.[0]?.labelNames?.includes(trelloLabels.labelName.BlockedByInfo)) {
            return "gradient-1"
        }
        else if (findLabels?.[0]?.labelNames?.includes(trelloLabels.labelName.NeededInfo)) {
            return "gradient-2"
        }
        else if (findLabels?.[0]?.labelNames?.includes(trelloLabels.labelName.BlockedByTrade)) {
            return "gradient-3"
        }
        else
            return "";
    }
    return (
        <div>
            {getUniqueListName(child_cards).map((listName, i) => (
                <div key={i}>
                    <select
                        className={`custom-status ${getGradientColor(child_cards)}`}
                        value={selectedValues[listName] || ''}
                        onChange={(event) => handleSelectChange(event, listName)}
                        variant="outlined">
                        <option>--select--</option>
                        {allstatus.map((status) => (
                            <option key={status.value} value={status.value}>
                                {status.label}
                            </option>
                        ))}
                    </select>
                </div>
            ))}
        </div>
    );
}

export default CustomStatus;