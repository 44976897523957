import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import { styled, withStyles } from '@material-ui/styles';
import moment from 'moment';
import React, { useEffect } from 'react';
import { setUpdatedChatConversation } from 'redux/actions/Table.action';
import { AppTheme } from 'theme/customeTheme';
import CommentsDialogBox from './Comments/CommentsDialogBox';

const StyledMenu = styled((props) => (
    <Popover
        elevation={0}
        {...props}
    />
))(({ }) => ({
    '& .MuiPaper-root': {
        boxShadow:
            '0px 4px 4px rgba(0, 0, 0, 0.25);',
    },
}));
const CustomMenuItem = withStyles({
    root: {
        fontFamily: '"Mulish", sans-serif',
        color: "#000000",
        fontSize: 16,
        padding: "10px 15px",
        // borderRadius: 5,
        // marginBottom: 4,
        letterSpacing: 0.2,
        minHeight: "auto",
        fontWeight: 600,
        '&:hover': {
            backgroundColor: AppTheme.colors.primary,
            color: "#FFFFFF"
            // borderRadius: 5,
        }
    }
})(MenuItem)
const MobileDeliveryDateComponent = ({ deliveryDates }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [commentSection, setcommentSection] = React.useState(null);
    const [deliveryDate, setDeliveryDate] = React.useState(null);
    const today = moment(new Date()).format('YYYY-MM-DD');
    const todayDate = today + "T00:00:00.000Z";
    const handleOpenDialog = (deliveryDates) => {
        setOpenDialog(true);
        // setcommentSection(chatInfo);
    };
    const handleCloseDialog = () => {
        setUpdatedChatConversation({});
        setOpenDialog(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        showDeliveryDate();
    }, [deliveryDates])

    const showDeliveryDate = () => {
        let prelimeDate = deliveryDates?.PrelimDue;
        let FinalDate = deliveryDates?.FinalDue;

        if (prelimeDate == null && FinalDate == null) {
            setDeliveryDate("No Dates");
        }
        else if (prelimeDate == null && FinalDate !== null) {
            if (FinalDate < todayDate) {
                setDeliveryDate("Delivery Date Passed");
            }
            else {
                setDeliveryDate("(F)" + moment(FinalDate).utcOffset('-0700').format("DD MMM yyyy hh:mm:a"));
            }
        }
        else if (FinalDate == null && prelimeDate !== null) {
            if (prelimeDate < todayDate) {
                setDeliveryDate("Delivery Date Passed");
            }
            else {
                setDeliveryDate("(P)" + moment(prelimeDate).utcOffset('-0700').format("DD MMM yyyy hh:mm:a"));
            }
        }
        else {
            if (FinalDate < todayDate && prelimeDate < todayDate) {
                setDeliveryDate("Delivery Date Passed");
            }
            else if (prelimeDate < FinalDate) {
                if (prelimeDate > todayDate) {
                    // setDeliveryDate(moment(prelimeDate).format("DD MMM yyyy hh:mm:a"));
                    setDeliveryDate(`(P)${moment(prelimeDate).utcOffset('-0700').format("DD MMM yyyy hh:mm:a")} \n(F)${moment(FinalDate).utcOffset('-0700').format("DD MMM yyyy hh:mm:a")} `);

                }
                else {
                    // setDeliveryDate(moment(FinalDate).format("DD MMM yyyy hh:mm:a"));
                    setDeliveryDate(`(F)${moment(FinalDate).utcOffset('-0700').format("DD MMM yyyy hh:mm:a")} \n(P)${moment(prelimeDate).utcOffset('-0700').format("DD MMM yyyy hh:mm:a")} `);
                }
            }
            else {
                if (FinalDate > todayDate) {
                    // setDeliveryDate(moment(FinalDate).format("DD MMM yyyy hh:mm:a"));
                    setDeliveryDate(`(F)${moment(FinalDate).utcOffset('-0700').format("DD MMM yyyy hh:mm:a")} \n(P)${moment(prelimeDate).utcOffset('-0700').format("DD MMM yyyy hh:mm:a")} `);
                }
                else {
                    // setDeliveryDate(moment(prelimeDate).format("DD MMM yyyy hh:mm:a"));
                    setDeliveryDate(`(P)${moment(prelimeDate).utcOffset('-0700').format("DD MMM yyyy hh:mm:a")} \n(F)${moment(FinalDate).utcOffset('-0700').format("DD MMM yyyy hh:mm:a")} `);
                }
            }
        }
    };

    return (
        <>

            {
                deliveryDate !== 'No Dates' &&
                <p title={deliveryDate}
                    onClick={handleClick}
                    className={`date-label pointer ${deliveryDate === "Delivery Date Passed" ? 'text-red' : ''}`}>
                    {deliveryDate}
                </p>
            }

            {
                deliveryDate == 'No Dates' &&
                <p title={deliveryDate} className=" date-label pointer">
                    {deliveryDate}
                </p>
            }

            <StyledMenu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}

            >
                <div className="actions-menu p-2">
                    <CustomMenuItem
                        key={'DeliveryDate'}
                    >
                        <div>
                            {
                                deliveryDates?.PrelimDue &&
                                <div>
                                    Prelime Date : {moment(deliveryDates?.PrelimDue).format("DD MMM yyyy hh:mm:a")}
                                </div>
                            }
                            {
                                deliveryDates?.FinalDue &&
                                <div>
                                    Final Date : {moment(deliveryDates?.FinalDue).format("DD MMM yyyy hh:mm:a")}
                                </div>
                            }
                        </div>
                    </CustomMenuItem>
                </div>
            </StyledMenu>
        </>
    )
}
export default MobileDeliveryDateComponent;