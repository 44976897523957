export const AppTheme = {
    colors: {
        primary: "#006D8F",
        white: '#FFFFFF',
        black: '#000000'
    },
    CustomLabelcolors: {
        green: "#58B000",
        red: '#FF0000',
        yellow: '#FFB905',
    },
    BadgeBackgroundColors: {
        // pink: "#FFF5FB",
        pink: "#f5d2e7",
        orange: "#FFDBC0",
        green: '#BEF9C4',
        yellow: '#f2d891',
        lightBlue: '#CEE8FF',
        red: '#FF7676',
        lightRed: '#ff767675',
        lightBrown: '#DCC9B8',
        purple: '#A020F0',
        blue: '#000070'
    },

    BadgeBorderColors: {
        pink: "#FFABDE",
        orange: "#FF783F",
        green: '#71FFAA',
        lightBlue: '#3DA4FF',
        red: '#FF5252',
        lightBrown: '#9F6D40',
        yellow: '#cd9f21',
        purple: '#A020F0',
    },
}