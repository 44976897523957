export const trelloLabels = {
    labelName: {
        HourADay: "Hour a Day",
        Mechanical: "Mechanical",
        MechanicalT24: "Mechanical T-24",
        Electrical: "Electrical",
        ElectricalT24: "Electrical T-24",
        Plumbing: "Plumbing",
        InternalReview: "Internal review",
        PartialDelivery: "Partial Delivery",
        PC: "PC",
        BlockedByInfo: "Blocked by Info",
        BlockedByTrade: "Blocked by Trade",
        OnHold: "On Hold",
        // QDC: "QDC",
        // QDCElect: "QDC-Elect",
        // QDCMech: "QDC-Mech",
        // QDCPlumb: "QDC-Plumb",
        NewInfoAdded: "New Info Added",
        Revision: "Revision",
        BC: "BC",
        CreatePDF: "Create PDF",
        PS: "PS",
        DeliveryNote: "Delivery Note",
        // MainCard: "MAIN CARD",
        // PromisedDate: "Promised Date",
        NeededInfo: "NEED INFO",
        NotApproved: "Not Approved",
        WaitingforPayments: "Waiting for Payments",
        IPR: "IPR",
        PEC: "PEC",
        FullPayment: "Waiting for Payment - FULL",
        PrelimPayment: "Waiting for Payment - PRELIM"
    },
}
