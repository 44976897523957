import { ManagersFilter, ProjectTypeFilter, StaticOptionsKeyValueFilter, doFilter, getSrNo, usePrevious } from '@jumbo/components/TableComponents/table-utils'
import _ from 'lodash'
import qs from 'query-string'
import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { UpdateFAQNewNotification, UpdateFAQReadNotification, UpdateTableRow, UpdateThreadNewNotification, UpdateThreadReadNotification } from 'redux/actions/Table.action'
import TableServices from 'services/table'
import { io } from 'socket.io-client'
import { BASE_WS_URL } from 'utils/globals'
import CriteriaRating from './CriteriaRating'
import ReactMainTable from './MainTable'
const filterMap = {
    "project_name": "project_name",
    "worker_name_ref": "worker_name_ref",
    "trade_name": "trade_name",
    "project_phase_label": "project_phase_label"
}
// const useStyles = makeStyles((theme) => ({
//     root: {
//         backgroundColor: "#F2FAFD",
//         padding: "16px 16px 0 16px",
//         borderRadius: 0,
//         borderTopRightRadius: 10,
//         marginTop: "70px"
//     },
// }));
function MyReviewReactTable({ tradeInfo, projectInfo }) {
    // const classes = useStyles();
    const mountedRef = useRef()
    const tableRef = useRef(null);
    const ITEMS_PER_PAGE = 15;
    const history = useHistory();
    const [lastLockedIndex, setLastLockedIndex] = useState(2);
    const [rightLockedIndex, setRightLockedIndex] = useState(23);
    const [filtersToApply, setFiltersToApply] = React.useState({})
    const searchString = qs.parse(history.location.search);
    const userData = JSON.parse(localStorage.getItem("userInfo"));

    const [tableView, setTableView] = useState('Latest');
    const prevFiltersToApply = usePrevious(filtersToApply)
    const dispatch = useDispatch()
    const { reviewTableData } = useSelector(({ table }) => table);
    const { tableData } = useSelector(({ table }) => table);
    const [paginationOptions, setPaginationOptions] = useState({
        limit: ITEMS_PER_PAGE,
        offset: 0,
        currentPage: 0,
        orderBy: '',
        orderType: null,

    });
    // console.log("reviewTableData--", reviewTableData);
    const columns = React.useMemo(
        () => [
            {
                "Header": "Sr.",
                "accessor": "Sr_NO",
                sticky: 'left',
                Cell: (props) => {
                    return (
                        // className={tableView === 'CustomOrder' || tableView === 'CustomOrder2' || tableView === 'DeliveredReadyProject' ? "table_Custom_srNo" : "table_index"}
                        <div className="table_srNo">
                            <span className="table_index">{getSrNo(props)}</span>
                        </div>
                    )
                },
                disableFilters: true,
            },
            {
                id: 'project_name',
                "Header": "Project",
                sticky: 'left',
                accessor: (originalRow, rowIndex) => {
                    return (
                        <p title={originalRow?.project_name} className="text-truncate review-project">
                            <a style={{ color: 'black' }} href={'https://trello.com/c/' + originalRow?.project_name_ref} target="_blank" rel="noreferrer">
                                {originalRow?.project_name}
                            </a>
                        </p>
                    )
                    // return  <CustomBadge
                    // cardId={originalRow?.project_name_ref}
                    // name={originalRow?.project_name}
                    // labelStatus={originalRow?.labelNames}
                    // PrelimDate={originalRow?.PrelimDue}
                    // FinalDate={originalRow?.FinalDue}
                    // promise={originalRow?.Promise}
                    // child_cards={originalRow?.child_cards} />
                },

            },
            {
                id: 'trade_name',
                "Header": "Trade",
                sticky: 'left',
                accessor: (originalRow, rowIndex) => {
                    return (
                        <p title={originalRow?.trade_name} className="text-truncate">
                            {originalRow?.trade_name}
                        </p>
                    )
                },
                Filter: (props) => StaticOptionsKeyValueFilter(props, [{ label: 'Mechanical', value: 'Mechanical' }, { label: 'Electrical', value: 'Electrical' }, { label: 'Plumbing', value: 'Plumbing' }, { label: 'All', value: 'All' }, { label: 'PM', value: 'PM' }]),
            },
            {
                id: 'worker_name_ref',
                "Header": "Worker",
                // sticky: 'left',
                accessor: (originalRow, rowIndex) => {
                    return (
                        <p title={originalRow?.worker_name} className="text-truncate">
                            {originalRow?.worker_name}
                        </p>
                    )
                },
                Filter: ManagersFilter,
            },
            {
                id: 'project_phase_label',
                "Header": "Phase",
                accessor: (originalRow, rowIndex) => {
                    return (
                        <p title={originalRow?.project_phase_label} className="text-truncate">
                            {originalRow?.project_phase_label}
                        </p>
                    )
                },
                Filter: ProjectTypeFilter
            },
            {
                id: 'criteria',
                "Header": "Rating",
                accessor: (originalRow, rowIndex) => {
                    return (
                        <CriteriaRating
                            criteriDetail={originalRow.criteria}
                        />
                    )
                },
            },
        ],
        ([tableView])
    )
    // console.log("columns--", columns);
    const applyFilters = (filters) => {
        // console.log("filters::", filters);
        setFiltersToApply(doFilter(filters, filterMap))
    }
    const getViewValue = (tableView) => {
        console.log("tableView::", tableView);
        setTableView(tableView)
    }
    const getTableData = async () => {
        // console.log("getPageData 3 ", paginationOptions);
        const queryString = new URLSearchParams(filtersToApply).toString();
        // const queryData = window.location.search;
        // const searchString = qs.parse(queryData);
        // console.log("searchString  1--", searchString);

        dispatch(TableServices.getReviewTableData({
            limit: paginationOptions?.limit,
            currentPage: paginationOptions?.currentPage + 1,
            orderByColumn: paginationOptions.orderBy || null,
            columnOrderType: paginationOptions.orderType || null,
            trade_name: tradeInfo ? tradeInfo?.trade_name : '',
            project_name_ref: projectInfo ? projectInfo?.trello_id : '',
            ...filtersToApply
        }));
    }
    const getPageData = (pageNo, itemsPerPage) => {
        setPaginationOptions(old => {
            return {
                limit: itemsPerPage,
                offset: (pageNo) * itemsPerPage,
                currentPage: pageNo,
                orderBy: old.orderBy,
                orderType: old.orderType
            }
        });
    }
    const setOrderByColumns = (column) => {
        if (paginationOptions.orderBy == column) {
            if (paginationOptions.orderType == "asc") {
                setPaginationOptions(old => {
                    return {
                        limit: old.limit,
                        offset: (0) * old.limit,
                        currentPage: 0,
                        orderType: "desc",
                        orderBy: column,
                    }
                });
            }
            if (paginationOptions.orderType == "desc") {
                setPaginationOptions(old => {
                    return {
                        limit: old.limit,
                        offset: (0) * old.limit,
                        currentPage: 0,
                        orderType: "asc",
                        orderBy: column,
                    }
                });
            }
        }
        else {
            setPaginationOptions(old => {
                return {
                    limit: old.limit,
                    offset: (0) * old.limit,
                    currentPage: 0,
                    orderType: "asc",
                    orderBy: column,
                }
            });
        }
    }
    useEffect(() => {

        if (filtersToApply && prevFiltersToApply &&
            !_.isEqual(
                prevFiltersToApply,
                filtersToApply,
            )
        ) {

            setPaginationOptions(old => {
                return {
                    limit: old.limit,
                    offset: (0) * old.limit,
                    currentPage: 0,
                    orderBy: old.orderBy,
                    orderType: old.orderType
                }
            });
            if (paginationOptions.currentPage == 0 && paginationOptions.offset == 0) {
                getTableData();
            }
        }
    }, [filtersToApply, prevFiltersToApply])
    useEffect(() => {
        if (paginationOptions.limit) {
            // if (mountedRef.current) {
            dispatch(TableServices.getManagersData());
            getTableData();
            // }
        }
    }, [paginationOptions.currentPage, paginationOptions.limit, paginationOptions.offset, paginationOptions.orderBy, paginationOptions.orderType]);
    useEffect(() => {
        setPaginationOptions(old => {
            return {
                limit: old.limit,
                offset: (0) * old.limit,
                currentPage: 0,
                orderBy: '',
                orderType: null,
            }
        });
        if (paginationOptions.currentPage == 0 && paginationOptions.offset == 0) {
            getTableData();
        }
    }, [tableView]);
    useEffect(() => {
        mountedRef.current = true
        const userToeken = localStorage.getItem('trello_user_token');
        const socket = io(BASE_WS_URL, {
            query: { "token": userToeken }
        });
        // console.log("IN Socket");

        if (socket) {
            socket.on("onTrelloCardUpdate", (res) => {
                const UpdatedData = JSON.parse(res)
                if (typeof UpdatedData?.child_cards?.[0] !== 'string')//fix for ignoring non populated children
                    dispatch(UpdateTableRow(UpdatedData))
                // console.log("onTrelloCardUpdate socket", UpdatedData);
            })
            socket.on("onTrelloCardAdded", (res) => {
                console.log("IN onTrelloCardAdded");
                console.log("IN onTrelloCardAdded", res);
                // getTableData();
                // getTableData(tableView, paginationOptions);
                // const AddUpdatedData = JSON.parse(res)
                // if (typeof AddUpdatedData?.child_cards?.[0] !== 'string')//fix for ignoring non populated children
                //     dispatch(UpdateTableRow(AddUpdatedData))
                // console.log("socket", AddUpdatedData);
            })
            socket.on("onThreadNewMessagePosted", (res) => {
                const UpdatedData = res
                if (UpdatedData.message_type == 'faq')
                    dispatch(UpdateFAQNewNotification(UpdatedData))
                else
                    dispatch(UpdateThreadNewNotification(UpdatedData))
            })
            socket.on("onThreadNewMessageRead", (res) => {
                const UpdatedData = res
                if (UpdatedData.message_type == 'faq')
                    dispatch(UpdateFAQReadNotification(UpdatedData))
                else
                    dispatch(UpdateThreadReadNotification(UpdatedData))
            })
        };
        return () => socket.close();
    }, [tableView]);
    return (
        <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <title> Priority Sheets | Report</title>
            </Helmet>
            {/* <Box display="inline-flex" alignItems="center" className="tab-heading" >Active Projects</Box> */}
            <div style={{ margin: '20px 0px', fontSize: '20px', fontWeight: '600' }}>Rating Review : </div>
            <div >
                <ReactMainTable
                    tableRef={tableRef}
                    applyFilters={applyFilters}
                    columns={columns}
                    lastLockedIndex={lastLockedIndex}
                    rightLockedIndex={rightLockedIndex}
                    itemsPerPage={paginationOptions.limit}
                    TotalLength={reviewTableData?.totalItems || 0}
                    getPageData={getPageData}
                    orderByColumns={setOrderByColumns}
                    currentPage={paginationOptions.currentPage}
                    orderBy={paginationOptions.orderBy}
                    offset={paginationOptions.offset}
                    tableView={tableView}
                    updateCallBack={() => getTableData()}
                    data={reviewTableData?.data || []} />
            </div>
        </React.Fragment>
    )
}

export default MyReviewReactTable
