// import { useEffect, useState } from "react";
import { trelloLabels } from "theme/trelloLabels";
import React from "react";


const CustomBadge = ({
  name,
  // child_cards,
  labelStatus,
  cardId,
  promise,
  PrelimDate,
  FinalDate,
}) => {
  // const [labelBackgroundColor, setLabelBackgroundColor] = useState();
  // const [labelBorderColor, setLabelBorderColor] = useState();
  // useEffect(() => {
  //   if (labelStatus?.includes(trelloLabels.labelName.IPR)) {
  //     setLabelBorderColor("4px solid #6b1987");
  //   } else {
  //     setLabelBorderColor("")
  //   }
  // }, [labelStatus]);
  const getGradientColor = () => {
    let colors = ["white", "white", "white", "white", "white", "white"];
  
    if (promise !== null && (PrelimDate !== null || FinalDate !== null)) {
      // Green
      colors[0] = "#58B000";
    }
    if (promise !== null && PrelimDate === null && FinalDate === null) {
      // Light brown
      colors[0] = "#DCC9B8";
    }
    if (promise === null) {
      // Light brown
      colors[0] = "#DCC9B8";
    }
    if (labelStatus?.includes(trelloLabels.labelName.OnHold)) {
      // Purple
      colors[3] = "#A020F0";
    }
    if (labelStatus?.includes(trelloLabels.labelName.HourADay)) {
      // Blue
      colors[1] = "#CEE8FF";
    }
    if (labelStatus?.includes(trelloLabels.labelName.BlockedByInfo)) {
      // Red
      colors[2] = "#ff7676";
    }
    if (labelStatus?.includes(trelloLabels.labelName.PS)) {
      // Pink
      colors[4] = "#f587cb";
    }
    if (labelStatus?.includes(trelloLabels.labelName.BC)) {
      // Pink
      colors[5] = "#f5d2e7";
    }
  
    return {
      background: `linear-gradient(to right, ${colors[0]} 0%, ${colors[0]} 16.67%, ${colors[1]} 16.67%, ${colors[1]} 33.33%, ${colors[2]} 33.33%, ${colors[2]} 50%, ${colors[3]} 50%, ${colors[3]} 66.67%, ${colors[4]} 66.67%, ${colors[4]} 83.33%, ${colors[5]} 83.33%, ${colors[5]} 100%)`,
      border: labelStatus?.includes(trelloLabels.labelName.IPR) ? "4px solid #6b1987" : "",
    };
  };
  


  return (
    <p
      title={name}
      className={`text-truncate highest-priorities `}
      style={{ ...getGradientColor(), border: labelStatus?.includes(trelloLabels.labelName.IPR) ? "4px solid #6b1987" : "" }}>
      <a
        style={{ color: "black" }}
        href={"https://trello.com/c/" + cardId}
        target="_blank"
        rel="noreferrer"
      >
        {name}
      </a>
    </p>
  );
};

export default CustomBadge;
