import React, { useEffect, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import MarkdownEditor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css'; 
const Description = ({ setDescription, onUpdateDescription }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [updatedDescription, setUpdatedDescription] = useState(setDescription);
  const [originalDescription, setOriginalDescription] = useState(setDescription);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    onUpdateDescription(updatedDescription);
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setUpdatedDescription(originalDescription);
    setIsEditing(false);
  };

  useEffect(() => {
    setOriginalDescription(setDescription);
    setUpdatedDescription(setDescription);
  }, [setDescription]);

  return (
    <div>
      {!isEditing ? (
        <div>
          <button className='btn btn-secondary' onClick={handleEditClick} style={{ marginTop: "-74px", marginLeft: '85%' }}>Edit</button>
          <br />
          <ReactMarkdown>{updatedDescription}</ReactMarkdown>
        </div>
      ) : (
        <div>
          <MarkdownEditor
            value={updatedDescription}
            onChange={({ text }) => setUpdatedDescription(text)}
            preview="edit"
            components={{
          toolbar: (command, disabled, executeCommand) => {
            if (command.keyCommand === 'code') {
              return (
                <button 
                  aria-label="Insert code"
                  disabled={disabled}
                  onClick={(evn) => {
                    evn.stopPropagation();
                    executeCommand(command, command.groupName)
                  }}
                >
                  Code
                </button>
              )
            }
          }
        }}
          />
          <div className='d-flex mt-3'>
            <button className='btn btn-primary' onClick={handleSaveClick}>
              Save
            </button>
            &nbsp;&nbsp;&nbsp;
            <button className='btn btn-secondary' onClick={handleCancelClick}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Description;

