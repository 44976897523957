import React, { useEffect, useState } from 'react';
import ReactLinkify from 'react-linkify';
import { useDispatch } from 'react-redux';
import TableServices from 'services/table';

const ChecklistItem = ({ item, cardId, data, childCardId, typeOfCard }) => {
  const [info, setInfo] = useState(item?.name);
  const [isChecked, setIsChecked] = useState(item.state === 'complete');
  const [isEditing, setIsEditing] = useState(false);
  const dispatch = useDispatch();

  const updateText = () => {
    const selectedCardId = typeOfCard === 'maincard' ? cardId : childCardId;

    const EditPayload = {
      cardId: selectedCardId,
      checkListId: item?.idChecklist,
      checkItemId: item?.id,
      name: info,
    };

    dispatch(TableServices.updateChecklist(EditPayload, selectedCardId));
    setIsEditing(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      updateText();
    }
  };

  const handleTextAreaChange = (event) => {
    setInfo(event.target.value);
  };

  const handleDoubleClick = () => {
    setIsEditing(true);
  };

  return (
    <ReactLinkify>    <p
      className={`comments-message form-control ${isEditing ? 'editing' : ''}`}
      // value={info}
      style={{ textDecoration: item.state === 'complete' ? 'line-through' : 'none' }}
      onDoubleClick={handleDoubleClick}
      onBlur={updateText}
    >
      {isEditing ? (
        <textarea
          value={info}
          className='comments-message form-control'
          onChange={handleTextAreaChange}
          onKeyDown={handleKeyDown}
          autoFocus
        />
      ) : (
        info
      )}
    </p>
    </ReactLinkify>

  );
};

export default ChecklistItem;
