import { Dialog, DialogContent, Grid } from '@material-ui/core';
import React from 'react';
// import editIcon from "../../../../img/edit.webp";
import editIcon1 from "../../../../img/edit.webp";
import DialogTitleCustom from './DialogTitleCustom';
import EditableField from './EditableField';
// const useStyles = makeStyles((theme) => ({
//     root: {
//         "& .MuiDialogContent-root ": {
//             padding: 0
//         },
//         "&.MuiDialog-paperWidthSm": {
//             maxWidth: '800px'
//         }
//     },

// }));
const TableEditPopup = ({ editData, trelloCardId }) => {
    // const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <img className="pointer" src={editIcon1} alt="edit" onClick={handleClickOpen} />
            {open && <Dialog
                fullWidth={true}
                scroll="paper"
                open={open}
                keepMounted
                maxWidth="md"
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                // className={classes.root}
            >
                <DialogTitleCustom id="alert-dialog-slide-title" onClose={handleClose}>Edit</DialogTitleCustom>
                <DialogContent className="px-0 py-3 dialog-content">
                    <DialogContent dividers>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} style={{ paddingRight: "16px", borderRight: "2px solid rgba(0, 109, 143, 0.5)" }}>
                                <EditableField editData = {editData} trelloCardId= {trelloCardId} handleDialogueClose={handleClose}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </DialogContent>
            </Dialog>}
        </>
    )
}

export default TableEditPopup;