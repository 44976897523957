import { Dialog, DialogContent, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import editIcon from "../../../../img/edit.webp";
import editIcon1 from "../../../../img/edit.webp";
import DialogTitleCustom from './DialogTitleCustom';
import EditableField from './EditableField';
// import MobileEditableField from './EditableField';
import MobileEditableField from './MobileEditableField';
import MobileDialogTitleCustom from './MobleDialogTitleCustom';
const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiDialogContent-root ": {
            padding: 0
        },
        "&.MuiDialog-paperWidthSm": {
            maxWidth: '800px'
        }
    },

}));
const MobileTableEditPopup = ({ editData, trelloCardId }) => {
    // console.log("editData---",editData);
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <img className="pointer"  src="/images/create.svg" alt="edit" onClick={handleClickOpen} style={{position: "relative", top:"20px"}}/>
            {open && <Dialog
                fullWidth={true}
                scroll="paper"
                open={open}
                keepMounted
                maxWidth="md"
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                className={classes.root}
            >
                <MobileDialogTitleCustom id="alert-dialog-slide-title" onClose={handleClose}>Edit</MobileDialogTitleCustom>
                <DialogContent className="px-0 py-3 dialog-content">
                    <DialogContent dividers>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} style={{ paddingRight: "16px", borderRight: "2px solid rgba(0, 109, 143, 0.5)" }}>
                                <MobileEditableField editData = {editData} trelloCardId= {trelloCardId} handleDialogueClose={handleClose}/>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </DialogContent>
            </Dialog>}
        </>
    )
}

export default MobileTableEditPopup;