import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import TableServices from 'services/table';

const UploadMediaFilesBox = ({ projectId, fileArray, FAQType, message, cardType, mentions, open, handleClose }) => {
    let index = useState(0);
    // const [state, setstate] = useState("");
    const dispatch = useDispatch();

    useEffect(() => {
        //on paste call upload media api
        // console.log("onUploadMediaFile useEffect-- ", fileArray);
        if (typeof fileArray !== 'undefined')
            onUploadMediaFile(fileArray);
    }, [fileArray])
    const onFileUploadInput = (index) => {
        const file = document.getElementById('fileuploadinput').files;
        // console.log("onFileUploadInput Event---", file);
        onUploadMediaFile(file);
    };
    const onUploadMediaFile = (file) => {
        const fileLength = file.length;
        let CommentMediaUpload = {};
        if (fileLength > 0) {
            for (let i = 0; i < fileLength; i++) {
                let form_Data = new FormData();
                form_Data.append('type', 'thread_media')
                form_Data.append('attachment', file[i])
                if (FAQType === 'question') {
                    CommentMediaUpload = {
                        message: message,
                        thread: cardType._id,
                        message_type: "faq",
                        tagged_users: mentions,
                    }
                    form_Data.append('metadata', JSON.stringify({
                        trello_id: cardType.project,
                        thread_id: cardType._id,
                    }))
                }
                else if (FAQType === 'answer') {
                    CommentMediaUpload = {
                        message: message,
                        thread: cardType?.thread?._id,
                        message_type: "faq",
                        tagged_users: null,
                        thread_message_id: cardType?.id // question id
                    }
                    form_Data.append('metadata', JSON.stringify({
                        trello_id: projectId,
                        thread_id: cardType.thread._id,
                    }))
                }
                else {
                    CommentMediaUpload = {
                        message: message,
                        thread: cardType._id,
                        message_type: "chat",
                        tagged_users: mentions,
                    }
                    form_Data.append('metadata', JSON.stringify({
                        trello_id: cardType.project,
                        thread_id: cardType._id,
                    }))
                }

                dispatch(TableServices.onFileuploadMedia(form_Data, CommentMediaUpload, FAQType));
            }
        }
    };
    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Upload</DialogTitle>

            <DialogContent>
                <input accept="image/*" id="fileuploadinput" type="file" multiple={true}
                    onInput={(e) => onFileUploadInput(index)} />
            </DialogContent>

            <DialogActions>
                <Typography>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Done
                    </Button>
                </Typography>
            </DialogActions>
        </Dialog>
    );
};

export default UploadMediaFilesBox;
