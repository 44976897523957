import React, { useState, useEffect } from 'react';
import { Checkbox } from "@material-ui/core";
import { useDispatch } from 'react-redux';
import TableServices from 'services/table';

const CheckboxInput = ({ originalRow, checkboxType,  paginationOptions, filtersToApply }) => {
  // console.log("filtersToApply", filtersToApply);
  // console.log("paginationOptions", paginationOptions);
  const dispatch = useDispatch();
  const [statusChecked, setStatusChecked] = useState('');
  // console.log("checkboxtype",originalRow[checkboxType] );
  useEffect(() => {
    setStatusChecked(originalRow[checkboxType] === 'Completed');
  }, [originalRow[checkboxType]]);
  const handleCheckboxChange = () => {
    setStatusChecked(!statusChecked);
    const newValue = statusChecked ? 'NotCompleted' : 'Completed';
    updateStatus(newValue);
  };  

  const updateStatus = (value) => {
    const updateStatusPayload = {
      comment_id: originalRow.comment_id,
    };

    if (checkboxType === 'DT') {
      updateStatusPayload.DTvalue = value;
    } else if (checkboxType === 'PM') {
      updateStatusPayload.PMvalue = value;
    }

    dispatch(TableServices.updateDTPMCheckboxStatus(updateStatusPayload, paginationOptions, filtersToApply));
    // console.log("paginationOptions", paginationOptions);
    // console.log("filtersToApply//////", filtersToApply);
  };

  return (
    <Checkbox
      style={{ color: '#216ba5' }}
      checked={statusChecked}
      onChange={handleCheckboxChange}
    />
  );
};

export default CheckboxInput;
