import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
  Slide,
} from "@material-ui/core";
import StarIcon from "@material-ui/icons/Star";
import React, { Suspense } from "react";
import { useDispatch } from "react-redux";
import { clearRatingDetail } from "redux/actions/Table.action";
import TableServices from "services/table";
import RatingForm from "./RatingForm";
import { Skeleton } from "@material-ui/lab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiRating-label": {
      padding: "10px",
      fontSize: "28px",
    },
  },
}));
const WorkerRatingByTrade = ({
  cardType,
  projectInfo,
  threadNewWorker,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [workerName, setWorkerName] = React.useState(2);
  const userData = JSON.parse(localStorage.getItem("userInfo"));
  const handleClickOpen = (value) => {
    dispatch(
      TableServices.getRatingCriteriaByTrade(cardType, projectInfo.trello_id)
    );
    setOpen(true);
    setWorkerName(value);
  };
  const handleClose = () => {
    setOpen(false);
    dispatch(clearRatingDetail());
  };
  return (
    <>
      <Suspense fallback={<div><Skeleton style={{ width: '100%' }} /></div>}>
        {Array.isArray(threadNewWorker) ? (
          threadNewWorker?.map((val, i) => {
            let findWorker = userData?.can_rate_to?.findIndex(
              (item) => item?.trello_id === val?.trello_id
            );
            return (
              <div
                key={i}
                style={{ display: "flex" }}
              >
                {val?.fullName}
                {i < threadNewWorker.length - 1 && ", "}
                {findWorker !== -1 && <StarIcon onClick={() => handleClickOpen(val)} style={{ fill: "#006d8f" }} />}
              </div>
            );
          })
        ) : (
          <>
            <div
              style={{ display: "flex"}}
              onClick={() => handleClickOpen(threadNewWorker)}
            >
              {threadNewWorker?.fullName}
              {userData?.can_rate_to?.findIndex(
                (item) => item?.trello_id === threadNewWorker?.trello_id
              ) !== -1 && <StarIcon style={{ fill: "#006d8f" }} />}
            </div>
          </>
        )}
        <Box>
          {open && (
            <Dialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open || false}
              maxWidth="xl"
              className={classes.root}
              TransitionComponent={Transition}
            >
              <DialogTitle id="customized-dialog-title" className="dialog-title">
                Rating
              </DialogTitle>
              <DialogContent dividers className="comments-box dialog-content">
                <RatingForm
                  handleClose={handleClose}
                  projectInfo={projectInfo}
                  workerName={workerName}
                  cardType={cardType}
                />
              </DialogContent>
            </Dialog>
          )}
        </Box>
      </Suspense>
    </>
  );
};

export default WorkerRatingByTrade;
