import * as React from 'react';
import Popover from '@material-ui/core/Popover';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
// import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import {  makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl'
// import Checkbox from '@material-ui/core/Checkbox';
// import { AppTheme } from 'theme/customeTheme';
import { trelloLabels } from 'theme/trelloLabels';
import ProjectLabelType from '@jumbo/components/TableComponents/ProjectLabelType';

const useStyles = makeStyles(() => ({
    paper: {
        padding: "20px 10px",
        borderRadius: 5,
        border: "1px solid #3F7786;",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);"
    },
}));
// const BMMSwitch = withStyles((theme) => ({
//     root: {
//         width: 26,
//         height: 16,
//         padding: 0,
//         margin: theme.spacing(1),
//     },
//     switchBase: {
//         padding: 1,
//         '&$checked': {
//             transform: 'translateX(11px)',
//             color: "#ffffff !important",
//             '& + $track': {
//                 backgroundColor: '#52d869 !important',
//                 opacity: 1,
//                 border: 'none',
//             },
//         },
//         '&$focusVisible $thumb': {
//             //   color: '#52d869',
//             border: '6px solid #fff',
//         },
//     },
//     thumb: {
//         width: 13,
//         height: 13,
//     },
//     track: {
//         borderRadius: 26 / 2,
//         border: `1px solid ${theme.palette.grey[400]}`,
//         backgroundColor: theme.palette.grey[50],
//         opacity: 1,
//         transition: theme.transitions.create(['background-color', 'border']),
//     },
//     checked: {},
//     focusVisible: {},
// }))(({ classes, ...props }) => {
//     return (
//         <Switch
//             focusVisibleClassName={classes.focusVisible}
//             disableRipple
//             classes={{
//                 root: classes.root,
//                 switchBase: classes.switchBase,
//                 thumb: classes.thumb,
//                 track: classes.track,
//                 checked: classes.checked,
//             }}
//             {...props}
//         />
//     );
// });



// const SwitchListItem = ({ column, index, setHideColumn, scrollableTableHiddenColumns }) => {
const SwitchListItem = ({ column, index }) => {
    // console.log("SwitchListItem column::", column);
    // const label = { inputProps: { 'aria-label': 'Hide or Show Columns' } };

    // const [checked, setChecked] = React.useState(false)

    return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <p className="label mb-0" key={"column-name-" + index} >{column.value}</p> <ProjectLabelType labelType={column.value}/>
        </div>
    )
}
export default function LegendIconList(props) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    let legendIcon = Object.entries(trelloLabels?.labelName).map(([key, value]) => ({ key, value }));
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <div>
            <IconButton aria-label="delete" title="Hide/Show Columns" onClick={handleClick} style={{ padding: 0 }}>
                <SettingsIcon style={{ fill: "#006d8f" }} />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                elevation={9}

                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                classes={{
                    paper: classes.paper,
                }}

            >
                <FormControl component="fieldset" className="settings-menu" >

                    <FormGroup aria-label="position" row={false}>
                        {legendIcon?.map((column, index) => {
                            return (
                                <SwitchListItem key={index} index={index} column={column} />
                            )
                        }
                        )}


                    </FormGroup>
                </FormControl>
            </Popover>
        </div>
    );
}