import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { Button, FormHelperText, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
// import Select from 'react-select/dist/declarations/src/Select';
import ReactSelect from "react-select";
import * as Yup from 'yup';
import { Formik } from 'formik';
import moment from "moment";
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import DatePicker from 'react-date-picker';
import { AppTheme } from 'theme/customeTheme';
import TableServices from 'services/table';
import { useDispatch, useSelector } from 'react-redux';
import MultipleCardComponent from './MultipleCardComponent';
import { DateTimePicker } from '@material-ui/pickers';
// import axios from 'axios';

const useStyles = makeStyles(theme => ({
    editPopupRoot: {
        '& .MuiOutlinedInput-multiline': {
            padding: "0px"
        },
    }
}));
const EditableField = ({ editData, trelloCardId, handleDialogueClose }) => {
    // console.log("editData>>>",editData);
    const dispatch = useDispatch();
    const classes = useStyles();
    const { allProjectLabels } = useSelector(({ table }) => table);
    const { workermanager } = useSelector(({ table }) => table);
    const [selectedWorker, setSelectedWorker] = useState(editData?.cell?.row?.original?.new_worker[0]?.fullName || '--');
    const [selectedManager, setSelectedManager] = useState(editData?.cell?.row?.original?.new_manager[0]?.fullName || '--');
    const handleWorkerChange = async (event) => {
        const selectedWorkerValue = event.target.value;
        try {
            dispatch(TableServices.updateWorker(trelloCardId, selectedWorkerValue));
            setSelectedWorker(selectedWorker);
        } catch (error) {
            console.error('Error updating worker:', error);
        }
    };
    const handleManagerChange = async (event) => {
        const selectedManagerValue = event.target.value;
        try {
            dispatch(TableServices.updateManager(trelloCardId, selectedManagerValue));
            setSelectedManager(selectedManager);
            console.log("selected!!!!");
        } catch (error) {
            console.error('Error updating worker:', error);
        }
    };
    // console.log("workermanager---", workermanager);
    return (
        <div className="edit-section custom-scrollbar">
            <Formik
                enableReinitialize
                initialValues={{
                    trello_card_id: trelloCardId,
                    card_title: editData?.cell?.row?.original?.card_title ? editData?.cell?.row?.original?.card_title?.name : '',
                    labelNames: editData?.cell?.row?.original?.labelNames ? editData?.cell?.row?.original?.labelNames : [],
                    PrelimDue: editData?.cell?.row?.original?.PrelimDue ? editData?.cell?.row?.original?.PrelimDue : '',
                    FinalDue: editData?.cell?.row?.original?.FinalDue ? editData?.cell?.row?.original?.FinalDue : '',
                    mechanical: editData.cell?.row?.original?.child_cards?.filter((e) => e.typeOfCard == "Mechanical") || [],
                    electrical: editData.cell?.row?.original?.child_cards?.filter((e) => e.typeOfCard == "Electrical") || [],
                    plumbing: editData.cell?.row?.original?.child_cards?.filter((e) => e.typeOfCard == "Plumbing") || [],
                    Promise: editData?.cell?.row?.original?.Promise ? editData?.cell?.row?.original?.Promise : '',
                    project_type: editData?.cell?.row?.original?.Project_Type ? editData?.cell?.row?.original?.Project_Type : 'option',
                    // new_worker: editData?.cell?.row?.original?.new_worker[0]?.fullName || [],
                }}
                validationSchema={Yup.object().shape({
                    card_title: Yup.string().max(255).required('Highest Priorities is required'),
                    // PrelimDue: Yup.date().required('Prelime Date required'),
                    // FinalDue: Yup.date().required('Final Date required'),
                    // Spent_Mech_Hours: Yup.number().typeError('Mechanical hours is required').min(0, 'Mechanical hours must be greater than or equal to 0')
                    //     .required('Mechanical hours is required'),
                    // Spent_Elec_Hours: Yup.number().typeError('Electrical hours is required').min(0, 'Electrical hours must be greater than or equal to 0')
                    //     .required('Electrical hours is required'),
                    // Spent_Plum_Hours: Yup.number().typeError('Plumbing hours is required').min(0, 'Plumbing hours must be greater than or equal to 0')
                    //     .required('Plumbing hours is required'),
                    // Promise: Yup.string().required('Promise is required'),
                })}
                onSubmit={(values) => {
                    const updateCardDetails = {
                        ...values,
                        PrelimDue: values.PrelimDue,//moment(values.PrelimDue).format('YYYY-MM-DD'),
                        FinalDue: values.FinalDue,//moment(values.FinalDue).format('YYYY-MM-DD'), // moment(values.PrelimDue)?.utcOffset('-0800').format("YYYY-MM-DDTHH:mm:ss")
                        trello_id: values.trello_card_id,
                    }
                    // console.log("updateCardDetails:", updateCardDetails);
                    dispatch(TableServices.updateTrelloCardDetails(updateCardDetails));
                    handleDialogueClose();
                }}
            >
                {({ errors, handleBlur, handleChange, handleSubmit, setFieldValue,
                    // isSubmitting,
                    touched,
                    values
                }) => {
                    {/* console.log('current val', values?.mechanical); */ }
                    return (
                        <>
                            <Grid className="edit-fileds" container>
                                <Grid item xs={12} sm={4} md={4}>
                                    <Typography className="edit-label">Worker</Typography>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                                    <select
                                        className='css-1s2u09g-control'
                                        onChange={handleWorkerChange}
                                        placeholder="Worker"
                                    // maxMenuHeight={200}
                                    >
                                        {selectedWorker ? (
                                            <option value={selectedWorker}>{selectedWorker}</option>
                                        ) : (
                                            <option value="--" selected>--</option>
                                        )}
                                        {workermanager?.map((manager, index) => (
                                            <option key={index} value={manager.id}>
                                                {manager.fullName}
                                            </option>

                                        ))}
                                        <option value="--">--</option>
                                    </select>
                                </Grid>
                            </Grid>

                            <Grid className="edit-fileds" container>
                                <Grid item xs={12} sm={4} md={4}>
                                    <Typography className="edit-label">Manager</Typography>
                                </Grid>
                                <Grid item xs={12} sm={8} md={8}>
                                    <select onChange={handleManagerChange} placeholder="Manager" className='css-1s2u09g-control'>
                                        <option value={selectedManager}>{selectedManager}</option>
                                        {workermanager?.map((manager, index) => (
                                            <option key={index} value={manager.id}>{manager.fullName}</option>
                                        ))}
                                        <option value="--">--</option>
                                    </select>

                                </Grid>
                            </Grid>

                            <form
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        handleSubmit();
                                    }
                                }}
                                onSubmit={handleSubmit} className={classes.editPopupRoot}>
                                <Grid className="edit-fileds" container>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Typography className="edit-label">Prilim Date</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                        <DateTimePicker
                                            error={Boolean(touched.PrelimDue && errors.PrelimDue)}
                                            helperText={touched.PrelimDue && errors.PrelimDue}
                                            value={values?.PrelimDue ? new Date(values?.PrelimDue) : null}
                                            // value={values?.PrelimDue == null ? null : moment(values?.PrelimDue).format('DD-MMMM-YY')}
                                            dateFormat="dd MMM yyyy"
                                            onBlur={handleBlur}
                                            onChange={(date, e) => {
                                                // console.log('setting value',date);
                                                // if (date) {
                                                //  setFieldValue('PrelimDue', moment(date).format('YYYY-MM-DD'))
                                                // } else {
                                                // }
                                                setFieldValue('PrelimDue', date)
                                            }}
                                            // showMonthYearPicker
                                            // className=""
                                            placeholderText="Select Date"
                                            className="edit-date-picker edit-value filter-box"
                                            wrapperClassName="custom-wrapper pr-0"
                                            isClearable
                                        />
                                    </Grid>
                                </Grid>
                                <Grid className="edit-fileds" container>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Typography className="edit-label">Final Date</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                        <DateTimePicker
                                            error={Boolean(touched.FinalDue && errors.FinalDue)}
                                            helperText={touched.FinalDue && errors.FinalDue}
                                            value={values?.FinalDue ? new Date(values?.FinalDue) : null}
                                            // value={values?.FinalDue == null ? null : moment(values?.FinalDue).format('DD-MMMM-YY')}
                                            dateFormat="dd MMM yyyy"
                                            onBlur={handleBlur}
                                            onChange={(date, e) => {
                                                // if (date) {
                                                //     setFieldValue('FinalDue', moment(date).format('YYYY-MM-DD'))
                                                // } else {
                                                // }
                                                setFieldValue('FinalDue', date)
                                            }}
                                            // showMonthYearPicker
                                            className="filter-box"
                                            placeholderText="Select Date"
                                            className="edit-date-picker edit-value"
                                            wrapperClassName="custom-wrapper pr-0"
                                            isClearable
                                        />
                                    </Grid>
                                </Grid>
                                <Grid className="edit-fileds" container>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Typography className="edit-label">Highest Priorities</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                        <TextField
                                            fullWidth
                                            error={Boolean(touched.card_title && errors.card_title)}
                                            helperText={touched.card_title && errors.card_title}
                                            value={values?.card_title}
                                            placeholder="Highest Priorities.."
                                            variant="outlined"
                                            name="card_title"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            // multiline
                                            className="edit-value"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid className="edit-fileds" container>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Typography className="edit-label">Project Label</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8} className="edit-fileds" container>
                                        <ReactSelect
                                            isMulti
                                            value={values?.labelNames?.map(label => ({ 'value': label, 'label': label }))}
                                            options={allProjectLabels}
                                            placeholder="Project Card Label.."
                                            // maxMenuHeight={200}
                                            onChange={(xx) => {
                                                let finalArray = xx.map(function (obj) {
                                                    return obj.value;
                                                });
                                                setFieldValue('labelNames', finalArray)
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid className="edit-fileds" container>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Typography className="edit-label">Mechanical</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                        {
                                            values?.mechanical?.map((mechanicalitem, index) => {
                                                {/* console.log('mechanicalitem---', mechanicalitem) */ }
                                                return (
                                                    <Grid key={'mechanical-edit-field-' + index} className="edit-fileds" container>
                                                        <MultipleCardComponent
                                                            array={values?.mechanical}
                                                            worker={values?.new_worker}
                                                            typeOfCard={mechanicalitem?.typeOfCard}
                                                            cardId={mechanicalitem?.trello_id}
                                                            // handleBlur={handleBlur}
                                                            handleChange={(array) => setFieldValue("mechanical", array, true)}
                                                            fieldName='mechanical'
                                                            cardItem={mechanicalitem}
                                                            index={index}
                                                            editData={editData} />

                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                                <Grid className="edit-fileds" container>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Typography className="edit-label">Electrical</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                        {
                                            values?.electrical?.map((electricalitem, index) => {
                                                return (
                                                    <Grid key={'electrical-edit-field-' + index} className="edit-fileds" container>
                                                        <MultipleCardComponent
                                                            array={values?.electrical}
                                                            // handleBlur={handleBlur}
                                                            typeOfCard={electricalitem?.typeOfCard}
                                                            handleChange={(array) => setFieldValue("electrical", array, true)}
                                                            fieldName='electrical'
                                                            cardItem={electricalitem}
                                                            cardId={electricalitem?.trello_id}
                                                            editData={editData}
                                                            index={index} />
                                                    </Grid>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                                <Grid className="edit-fileds" container>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Typography className="edit-label">Plumbing</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                        {
                                            values?.plumbing?.map((plumbingitem, index) => {
                                                return (
                                                    <Grid key={'plumbing-edit-field-' + index} className="edit-fileds" container>
                                                        <MultipleCardComponent
                                                            array={values?.plumbing}
                                                            editData={editData}
                                                            // handleBlur={handleBlur}
                                                            typeOfCard={plumbingitem?.typeOfCard}
                                                            handleChange={(array) => setFieldValue("plumbing", array, true)}
                                                            fieldName='plumbing'
                                                            cardItem={plumbingitem}
                                                            cardId={plumbingitem?.trello_id}
                                                            index={index} />
                                                    </Grid>

                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                                <Grid className="edit-fileds" container>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Typography className="edit-label">Promise</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                        <Select
                                            labelId="demo-simple-select-error-label"
                                            id="demo-simple-select-error"
                                            value={values?.Promise}
                                            name="Promise"
                                            placeholder="Select Promise"
                                            onChange={handleChange}
                                            // onChange={(e) => {
                                            //     setFieldValue('Promise', e?.target?.value);
                                            // }}
                                            error={Boolean(touched.Promise && errors.Promise)}
                                            className="edit-dropdown"
                                        >
                                            <MenuItem value="option"> select option</MenuItem>
                                            <MenuItem value="Customer"> Customer </MenuItem>
                                            <MenuItem value="Personal">Personal </MenuItem>
                                        </Select>
                                        {
                                            (touched.Promise && errors.Promise) &&
                                            <FormHelperText style={{ color: "#f44336", marginLeft: "14px" }}>{errors.Promise}</FormHelperText>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid className="edit-fileds" container>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Typography className="edit-label">Project Type</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                        <Select
                                            labelId="demo-simple-select-error-label"
                                            id="demo-simple-select-error"
                                            value={values?.project_type}
                                            name="project_type"
                                            placeholder="Select Project Type"
                                            onChange={handleChange}
                                            error={Boolean(touched.project_type && errors.project_type)}
                                            className="edit-dropdown"
                                        >
                                            <MenuItem value="option"> select option</MenuItem>
                                            <MenuItem value="Full">FULL</MenuItem>
                                            <MenuItem value="Prelim"> PRELIM</MenuItem>
                                            <MenuItem value="T-24">T-24</MenuItem>
                                            <MenuItem value="Revision"> Revision</MenuItem>
                                            <MenuItem value="Plan Check">Plan Check</MenuItem>
                                            <MenuItem value="Plan Check/Revision">Plan Check/Revision</MenuItem>
                                            <MenuItem value="Full/Plan Check">Full/Plan Check</MenuItem>
                                            <MenuItem value="Full/Revision">Full/Revision</MenuItem>
                                            <MenuItem value="ComCheck">ComCheck</MenuItem>
                                            <MenuItem value="Rescheck">Rescheck</MenuItem>
                                            <MenuItem value="WEC">WEC</MenuItem>
                                        </Select>
                                        {
                                            (touched.project_type && errors.project_type) &&
                                            <FormHelperText style={{ color: "#f44336", marginLeft: "14px" }}>{errors.project_type}</FormHelperText>
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sm={12} md={12} style={{ textAlign: "center", marginBottom: "20px" }}>
                                    <Button onClick={handleSubmit} variant="contained" className="update-button" style={{ backgroundColor: AppTheme.colors.primary }}>
                                        Save changes
                                    </Button>
                                </Grid>
                            </form>
                        </>
                    );
                }}
            </Formik>
        </div>
    )
}
export default EditableField;