import {
    STORE_TABLE_DATA, STORE_MANAGERS_DATA,STORE_DESCRIPTION, STORE_ATTACHMENT, STORE_WORKER_MANAGER, STORE_TRADE_CRITERIA,STORE_COMMENTS, STORE_ALL_CUSTOM_FEILDS, STORE_FOLDERS_DATA, STORE_PROJECT_PHASE_DATA, STORE_PROJECT_TYPE_DATA, STORE_ALL_PROJECT_LABEL, STORE_ALL_STATUS, CLEAR_SPENT_TIME_DATA, STORE_LIST_SPENT_TIME_SUMMARY, STORE_CHILD_CARD_SPENT_DETAILS, STORE_ALL_WORKCARD_LABEL, STORE_TRELLO_USERS_DATA,STORE_DROPDOWN, STORE_TITLE,STORE_UPDATED_CUSTOM_ORDER,STORE_UPDATE_DESC, STORE_UPDATE_CUSTOM_FIELDS,STORE_UPDATED_CARD_DETAIL, SET_CHECKBOX, STORE_UPDATED_CHAT_CONVERSATION, STORE_UPDATED_FAQ_DATA, STORE_UPDATED_ALL_CHECK, UPDATE_TABLE_ROW, UPDATE_TABLE_DATA, CLEAR_CONVERSATION_DATA, CLEAR_RATING_DATA, UPDATE_THREAD_NEW_NOTIFICATION, UPDATE_FAQ_NEW_NOTIFICATION, UPDATE_THREAD_READ_NOTIFICATION, UPDATE_FAQ_READ_NOTIFICATION, STORE_REVIEW_TABLE_DATA, STORE_FRONT_TABLE_DATA, STORE_TOTAL_HOUR_SUM, SET_NEWCHECKBOX, SET_DELIVERYNOTE,
} from "../../@jumbo/constants/ActionTypes";

const INIT_STATE = {
    tableData: {},
    reviewTableData: {},
    frontTableData: {},
    totalHours: {},
    managerData: [],
    workermanager: [],
    checkboxData: [],
    deliverynote: [],
    newcheckboxData: [],
    allComments:[],
    tradeCriteria: {},
    folderData: [],
    projectTypeData: [],
    allCustomFeilds: [],
    allProjectLabels: [],
    listSpentTimeSummary: [],
    childCardSpentDetails: {},
    allWorkCardLabels: [],
    allstatus: [],
    getAttachment:[],
    trelloUsersData: [],
    updatedCardDetails: [],
    updateCustomFields: {},
    updatedCustomOrder: [],
    lastSentMessage: {},
    lastFAQMessage: {},
    setDescription: {},
    updateTitle:{}
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case SET_CHECKBOX:
            return {
                ...state,
                checkboxData: action.payload,
            };
        case SET_NEWCHECKBOX:
            return {
                ...state,
                newcheckboxData: action.payload,
            }
        case SET_DELIVERYNOTE:
            return {
                ...state,
                deliverynote: action.payload,
            };
        case STORE_TABLE_DATA: {
            return {
                ...state,
                tableData: action.payload
            };
        }
        case STORE_REVIEW_TABLE_DATA: {
            return {
                ...state,
                reviewTableData: action.payload
            };
        }
        case STORE_FRONT_TABLE_DATA: {
            return {
                ...state,
                frontTableData: action.payload
            };
        }
        case STORE_DESCRIPTION: {
            return {
                ...state,
                setDescription: action.payload
            }
        }
        case STORE_ATTACHMENT: {
            return {
                ...state,
                getAttachment: action.payload
            }
        }
        case STORE_TOTAL_HOUR_SUM: {
            return {
                ...state,
                totalHours: action.payload
            }
        }
        case STORE_FOLDERS_DATA: {
            return {
                ...state,
                folderData: action.payload
            };
        }
        case STORE_PROJECT_TYPE_DATA: {
            return {
                ...state,
                projectTypeData: action.payload
            };
        }
        case STORE_ALL_PROJECT_LABEL: {
            return {
                ...state,
                allProjectLabels: action.payload
            };
        }
        case STORE_ALL_STATUS: {
            return {
                ...state,
                allstatus: action.payload
            };
        }
        case STORE_COMMENTS: {
            return {
                ...state,
                allComments: action.payload
            }
        }
        case STORE_LIST_SPENT_TIME_SUMMARY: {
            return {
                ...state,
                listSpentTimeSummary: action.payload
            };
        }
        case STORE_CHILD_CARD_SPENT_DETAILS: {
            return {
                ...state,
                childCardSpentDetails: action.payload
            };
        }
        case STORE_ALL_WORKCARD_LABEL: {
            return {
                ...state,
                allWorkCardLabels: action.payload
            };
        }
        case STORE_ALL_CUSTOM_FEILDS: {
            return {
                ...state,
                allCustomFeilds: action.payload
            }
        }
        case STORE_MANAGERS_DATA: {
            return {
                ...state,
                managerData: action.payload
            };
        }
        case STORE_TRADE_CRITERIA: {
            return {
                ...state,
                tradeCriteria: action.payload
            };
        }
        case STORE_WORKER_MANAGER: {
            return {
                ...state,
                workermanager: action.payload
            }
        }
        case STORE_TRELLO_USERS_DATA: {
            return {
                ...state,
                trelloUsersData: action.payload
            };
        }
        case STORE_UPDATED_CUSTOM_ORDER: {
            return {
                ...state,
                updatedCustomOrder: action.payload
            };
        }
        case STORE_UPDATED_CARD_DETAIL: {
            return {
                ...state,
                updatedCardDetails: action.payload
            };
        }
        case STORE_UPDATE_CUSTOM_FIELDS: {
            return {
                ...state,
                updateCustomFields: action.payload
            }
        }
        case STORE_UPDATE_DESC: {
            return {
                ...state,
                updateDescription: action.payload
            }
        }
        case STORE_TITLE: {
            return {
                ...state,
                updateTitle: action.payload
            }
        }
        case STORE_DROPDOWN: {
            return {
                ...state,
                updateDropdown: action.payload
            }
        }
        case STORE_UPDATED_CHAT_CONVERSATION: {
            return {
                ...state,
                lastSentMessage: action.payload
            };
        }
        case STORE_UPDATED_FAQ_DATA: {
            return {
                ...state,
                lastFAQMessage: action.payload
            };
        }
        case STORE_UPDATED_ALL_CHECK: {
            const findIndex = state?.tableData?.trelloCards?.findIndex((item) => item.trello_id === action.payload?.data?.projectId)
            if (findIndex !== -1) {
                const cards = state?.tableData?.trelloCards?.map((item) => {
                    // console.log("threadArray",action.payload?.data?.projectId, ",", item.trello_id)
                    if (item.trello_id === action.payload?.data?.projectId) {
                        const threadArray = item?.threads?.map((thread, index) => {
                            // console.log("threadArray====>", thread.thread_name);
                            // console.log("threadArray", action.payload?.data.thread.thread_name)

                            if (thread.thread_name === action.payload?.data.thread.thread_name) {
                                console.log("action.payload?.data", item.threads[index].is_all_checked);
                                if (action.payload?.data.isThreadHasAllChecked && action.payload?.data.isThreadHasAllChecked === true) {
                                    item.threads[index].is_all_checked = true
                                    // return item
                                } else {
                                    item.threads[index].is_all_checked = false
                                    // return item
                                }
                            } else {
                                // return iaction.payload?.datatem
                            }
                        })
                        // console.log('this is item====>', ...item);
                        return {
                            ...item,

                        }
                    }
                    else
                        return item
                });
                return {
                    ...state,
                    tableData: {
                        ...state?.tableData,
                        ...{ trelloCards: cards }
                    }
                };
            } else {
                return state;
            }
        }
        case UPDATE_TABLE_ROW: {
            const findIndex = state?.tableData?.trelloCards?.findIndex((item) => item.trello_id === action.payload?.trello_id)
            if (findIndex !== -1) {
                const cards = state?.tableData?.trelloCards?.map((item) => {
                    if (item.trello_id === action.payload?.trello_id) {
                        return action.payload
                    }
                    return item
                })
                return {
                    ...state,
                    tableData: {
                        ...state?.tableData,
                        ...{ trelloCards: cards }
                    }
                };
            } else {
                return state;
            }

        }
        case UPDATE_THREAD_NEW_NOTIFICATION: {
            const findIndex = state?.tableData?.trelloCards?.findIndex((item) => item.trello_id === action.payload?.projectId)
            if (findIndex !== -1) {
                const cards = state?.tableData?.trelloCards?.map((item) => {
                    if (item.trello_id === action.payload?.projectId) {
                        if (action.payload?.threadName === "Mechanical") {
                            item.userNotificationsStatuses[0].MechNotification = true;
                            return item
                        } else if (action.payload?.threadName === "Electrical") {
                            item.userNotificationsStatuses[0].ElecNotification = true;
                            return item
                        } else if (action.payload?.threadName === "Plumbing") {
                            item.userNotificationsStatuses[0].PlumNotification = true;
                            return item
                        } else if (action.payload?.threadName === "PM") {
                            item.userNotificationsStatuses[0].PMNotification = true;
                            return item
                        } else if (action.payload?.threadName === "All") {
                            item.userNotificationsStatuses[0].AllNotification = true;
                            return item
                        }
                        return item
                    }
                    else
                        return item
                })
                return {
                    ...state,
                    tableData: {
                        ...state?.tableData,
                        ...{ trelloCards: cards }
                    }
                };
            } else {
                return state;
            }
        }
        case UPDATE_FAQ_NEW_NOTIFICATION: {
            const findIndex = state?.tableData?.trelloCards?.findIndex((item) => item.trello_id === action.payload?.projectId)
            if (findIndex !== -1) {
                const cards = state?.tableData?.trelloCards?.map((item) => {
                    if (item.trello_id === action.payload?.projectId) {
                        if (action.payload?.threadName === "Mechanical") {
                            item.userNotificationsStatuses[0].MechFAQNotification = true;
                            return item
                        } else if (action.payload?.threadName === "Electrical") {
                            item.userNotificationsStatuses[0].ElecFAQNotification = true;
                            return item
                        } else if (action.payload?.threadName === "Plumbing") {
                            item.userNotificationsStatuses[0].PlumFAQNotification = true;
                            return item
                        } else if (action.payload?.threadName === "PM") {
                            item.userNotificationsStatuses[0].PMFAQNotification = true;
                            return item
                        } else if (action.payload?.threadName === "All") {
                            item.userNotificationsStatuses[0].AllFAQNotification = true;
                            return item
                        }
                        return item
                    }
                    else
                        return item
                })
                return {
                    ...state,
                    tableData: {
                        ...state?.tableData,
                        ...{ trelloCards: cards }
                    }
                };
            } else {
                return state;
            }
        }
        case UPDATE_THREAD_READ_NOTIFICATION: {
            const findIndex = state?.tableData?.trelloCards?.findIndex((item) => item.trello_id === action.payload?.projectId)
            if (findIndex !== -1) {
                const cards = state?.tableData?.trelloCards?.map((item) => {
                    if (item.trello_id === action.payload?.projectId) {
                        if (action.payload?.threadName === "Mechanical") {
                            item.userNotificationsStatuses[0].MechNotification = false;
                            return item
                        } else if (action.payload?.threadName === "Electrical") {
                            item.userNotificationsStatuses[0].ElecNotification = false;
                            return item
                        } else if (action.payload?.threadName === "Plumbing") {
                            item.userNotificationsStatuses[0].PlumNotification = false;
                            return item
                        } else if (action.payload?.threadName === "PM") {
                            item.userNotificationsStatuses[0].PMNotification = false;
                            return item
                        } else if (action.payload?.threadName === "All") {
                            item.userNotificationsStatuses[0].AllNotification = false;
                            return item
                        }
                        return item
                    }
                    else
                        return item
                })
                return {
                    ...state,
                    tableData: {
                        ...state?.tableData,
                        ...{ trelloCards: cards }
                    }
                };
            } else {
                return state;
            }
        }
        case UPDATE_FAQ_READ_NOTIFICATION: {
            const findIndex = state?.tableData?.trelloCards?.findIndex((item) => item.trello_id === action.payload?.projectId)
            if (findIndex !== -1) {
                const cards = state?.tableData?.trelloCards?.map((item) => {
                    if (item.trello_id === action.payload?.projectId) {
                        if (action.payload?.threadName === "Mechanical") {
                            item.userNotificationsStatuses[0].MechFAQNotification = false;
                            return item
                        } else if (action.payload?.threadName === "Electrical") {
                            item.userNotificationsStatuses[0].ElecFAQNotification = false;
                            return item
                        } else if (action.payload?.threadName === "Plumbing") {
                            item.userNotificationsStatuses[0].PlumFAQNotification = false;
                            return item
                        } else if (action.payload?.threadName === "PM") {
                            item.userNotificationsStatuses[0].PMFAQNotification = false;
                            return item
                        } else if (action.payload?.threadName === "All") {
                            item.userNotificationsStatuses[0].AllFAQNotification = false;
                            return item
                        }
                        return item
                    }
                    else
                        return item
                })
                return {
                    ...state,
                    tableData: {
                        ...state?.tableData,
                        ...{ trelloCards: cards }
                    }
                };
            } else {
                return state;
            }
        }
        case UPDATE_TABLE_DATA: {
            return {
                ...state,
                tableData: {
                    ...state?.tableData,
                    ...{ trelloCards: action.payload }
                }
            };
        }
        case CLEAR_CONVERSATION_DATA: {
            return {
                ...state,
                lastSentMessage: {},
                lastFAQMessage: {}
            };
        }
        case CLEAR_RATING_DATA: {
            return {
                ...state,
                tradeCriteria: {}
            };
        }
        case CLEAR_SPENT_TIME_DATA: {
            return {
                ...state,
                listSpentTimeSummary: []
            };
        }
        default:
            return state;
    }
};
