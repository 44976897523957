import { setTableData, setManagersData, setFoldersData, setAttachment, setAllprojectLabel,setDescription,setAllStatus,setComments, setAllworkCardLabel,setCustomFeilds, setTrelloUsersData, setUpdatedCustomOrder,setupdateCustomFields,setupdateDropdown,setupdateCardTitle, setupdateCardDescription,setUpdatedCardDetails, setUpdatedChatConversation, setListSpentTimeSummary, setNewCheckboxData, setCheckboxData, setChildCardSpentDetails, setProjectTypeData, setTradeCriteria, setProjectPhaseData, clearRatingDetail, setWorkerManager,clearFolder, clearCommentDetail, setReviewTableData, setFrontTableData, setMechHours, setElecHours, setPlumHours, setTotalHourSum, setCheckboxDetail,setDeliveryNote } from "redux/actions/Table.action";
import { fetchError, fetchStart, fetchSuccess } from "../../redux/actions";
import axios from '../config';
// import { toast } from "react-hot-toast";
const TableServices = {
    getTableData: ({ limit, currentPage, currentView, orderByColumn, columnOrderType, ...filtersToApply }) => {
        const queryString = new URLSearchParams(filtersToApply).toString();
        return dispatch => {
            try {
                dispatch(fetchStart());
                dispatch(TableServices.getTotalHoursOfSum({ queryString, currentView }));
                axios
                    .get('trello_cards/all?' + `limit=${limit}&page=${currentPage}&view=${currentView}&orderBy=${orderByColumn}&orderType=${columnOrderType}${queryString ? ("&" + queryString) : ""}`)
                    .then(res => {
                        if (res.status === 200) {
                                dispatch(fetchSuccess());
                                dispatch(setTableData(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status === "403") {
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    getFoldersData: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('trello_cards/get-folder-list?')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setFoldersData(res?.data?.data?.map((val) => {
                                return {
                                    value: val.Folder,
                                    label: val.Folder,
                                }
                            }
                            )));
                        } else {
                            dispatch(fetchError(res.data.error));
                            console.log("error 1:", res);
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status === "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
                console.log("error 3:", error);
            }
        };
    },
    getProjectTypeData: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('trello_cards/get-project-type-list')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setProjectTypeData(res?.data?.data?.map((val) => {
                                return {
                                    value: val.Project_Type,
                                    label: val.Project_Type,
                                }
                            }
                            )));
                        } else {
                            dispatch(fetchError(res.data.error));
                            console.log("error 1:", res);
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status === "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
                console.log("error 3:", error);
            }
        };
    },
    getAllProjectLabel: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('trello_cards/highlights')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // console.log('getAllProjectLabel: res data', res?.data?.data);
                            dispatch(setAllprojectLabel(res?.data?.data.map((val, i) => {
                                return {
                                    value: val.name,
                                    label: val.name,
                                }
                            }
                            )));
                        } else {
                            dispatch(fetchError(res.data.error));
                            console.log("error 1:", res);
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status === "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
                console.log("error 3:", error);
            }
        };
    },
    getAllStatus: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('trello_cards/getTrelloLists')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // console.log('getAllstatus', res?.data?.data[0]);
                            dispatch(setAllStatus(res?.data?.data.map((val, i) => {
                                return {
                                    value: val.id,
                                    label: val.name,
                                }
                            }
                            )));
                        } else {
                            dispatch(fetchError(res.data.error));
                            console.log("error 1:", res);
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status === "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
                console.log("error 3:", error);
            }
        };
    },
    getCustomFields: ({ trelloCardId }) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                dispatch(setCustomFeilds({}));
                axios
                    .get(`trello_cards/getCardCustomFields/${trelloCardId}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setCustomFeilds(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateCustomFields: (updateCustomFields) => {
        // console.log("updateCustomFields",updateCustomFields);
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .put('trello_cards/updateCustomFieldsOnCards', updateCustomFields)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setupdateCustomFields(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateDescription: (updateCardDescription) => {
        // console.log("updateCardDescription",updateCardDescription);
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post('trello_cards/updateCardDescription', updateCardDescription)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setupdateCardDescription(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateTitle: (updateCardTitle) => {
        // console.log("updateCardTitle",updateCardTitle);
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post('trello_cards/updateCardTitle', updateCardTitle)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setupdateCardTitle(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateDropdown: (updateDropdown) => {
        // console.log("updateDropdown",updateDropdown);
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .put('trello_cards/updateDropdownCustomFieldsOnCards', updateDropdown)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setupdateDropdown(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    getComments: ({ trelloCardId }) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                dispatch(setDescription({}));
                axios
                    .get(`trello_cards/getCommentsOfCards/${trelloCardId}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setComments(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    getAttachment: ({ trelloCardId }) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                dispatch(setAttachment({}));
                axios
                    .get(`trello_cards/getAttachmentsOnCard/${trelloCardId}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setAttachment(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    getDescription: ({ trelloCardId }) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                dispatch(setDescription({}));
                axios
                    .get(`trello_cards/getCardDescription/${trelloCardId}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setDescription(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },

    getAllWorkCardLabel: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('work_cards/work_highlights')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // console.log('setAllworkCardLabel: res data', res?.data?.data);
                            dispatch(setAllworkCardLabel(res?.data?.data.map((val, i) => {
                                return {
                                    value: val.label,
                                    label: val.label,
                                }
                            }
                            )));
                        } else {
                            dispatch(fetchError(res.data.error));
                            console.log("error 1:", res);
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status == "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
                console.log("error 3:", error);
            }
        };
    },
    getManagersData: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('trello_member/get-members?')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // console.log('managerData: res data', res?.data);
                            dispatch(setManagersData(res?.data?.data?.map((val) => {
                                return {
                                    value: val.trello_id,
                                    label: val.fullName,
                                }
                            }
                            )));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    getTrelloUsers: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('user/all')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // console.log('TrelloUsersData: res data', res?.data);
                            dispatch(setTrelloUsersData(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateCustomOrder: (finalCustomOrder, tableView, callback) => {
        // console.log("updateCustomOrder finalCustomOrder:", finalCustomOrder, orderBy);
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .put(`trello_cards/update_custom_order?view=${tableView}`, finalCustomOrder)
                    .then(res => {
                        console.log("customOrder::", res?.data);
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setUpdatedCustomOrder(res?.data));
                            callback()
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateTrelloCardDetails: (updateCardDetails) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .put('trello_cards/update-card-details', updateCardDetails)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setUpdatedCardDetails(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateConversations: (chatConversation) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post('thread_message/createMessage', chatConversation)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // dispatch(setUpdatedChatConversation(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateStatus: (updateStatusPayload) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post('trello_cards/changeCardList', updateStatusPayload)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // dispatch(setUpdatedChatConversation(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateCheckBoxInChat: (threadMessageId, checked) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post(`thread_message/toggle_check/${threadMessageId}/${checked}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // dispatch(setUpdatedChatConversation(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateWorker: (cardId,selectedWorkerId) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post(`trello_fields/powerup_update_MemberAndWorker?card_trello_id=${cardId}&worker_trello_id=${selectedWorkerId}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // dispatch(setUpdatedChatConversation(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateManager: (cardId,selectedManagerId) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post(`trello_fields/powerup_update_MemberAndWorker?card_trello_id=${cardId}&member_trello_id=${selectedManagerId}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // dispatch(setUpdatedChatConversation(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateWorkerPut: (cardId) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get(`trello_fields/get-pec-field/${cardId}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            
                            // dispatch(setUpdatedChatConversation(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    updateChecklist: (payload, selectedCardId) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post(`trello_fields/updatechecklist`, payload)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(TableServices.getNeedInfo(selectedCardId));
                            dispatch(TableServices.getNeedInfoAdded(selectedCardId));
                            dispatch(TableServices.getDeliveryNote(selectedCardId))
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    addChecklist: (addpayload, selectedCardId) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post(`trello_fields/addToChecklist`, addpayload)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(TableServices.getNeedInfo(selectedCardId));
                            dispatch(TableServices.getNeedInfoAdded(selectedCardId));
                            dispatch(TableServices.getDeliveryNote(selectedCardId))
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    removeTaggedNotification: (removeNotificationData) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post(`notification/ReadThreadNotification/${removeNotificationData}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // dispatch(setUpdatedChatConversation(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    removeProjectNotification: (removeNotificationData) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post(`notification/ReadNotificationAcknowledge/${removeNotificationData}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // dispatch(setUpdatedChatConversation(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    readThreadNotification: (readThreadData) => {
        // console.log("readThreadNotification---", readThreadData);
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .put('trello_cards/read-thread-notification', readThreadData)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // dispatch(setUpdatedChatConversation(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    getNeedInfo: (card_id) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                // dispatch(setCheckboxData({}));
                axios
                    .get(`trello_fields/getTrelloChecklist/${card_id}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setCheckboxData(res.data.data));
                            //   console.log("setcheckbox",res.data.data);
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(error => {
                        dispatch(fetchError(error.message));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        }
    },
    getNeedInfoAdded: (card_id) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                // dispatch(setNewCheckboxData({}));
                axios
                    .get(`trello_fields/getTrelloChecklist/${card_id}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setNewCheckboxData(res.data.NewInfo));
                            //   console.log("setcheckbox",res.data.data);
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(error => {
                        dispatch(fetchError(error.message));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        }
    },
    getDeliveryNote: (card_id) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                // dispatch(setNewCheckboxData({}));
                axios
                    .get(`trello_fields/getTrelloChecklist/${card_id}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setDeliveryNote(res.data.DeliveryNotes));
                            //   console.log("setcheckbox",res.data.data);
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(error => {
                        dispatch(fetchError(error.message));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        }
    },
    getListSpentTimeSummary: (cardSortId) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get(`trello_cards/spent-time-summary-with-children/${cardSortId}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setListSpentTimeSummary(res?.data?.data?.listwiseDetails));
                            dispatch(setChildCardSpentDetails(res?.data?.data?.childCardsSpentDetails));
                        } else {
                            dispatch(fetchError(res.data.error));
                            console.log("error 1:", res);
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status == "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
                console.log("error 3:", error);
            }
        };
    },
    deleteFAQ: (threadMessageId, messageType) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post(`thread_message/deletemessage/${threadMessageId}?message_type=${messageType}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // dispatch(setUpdatedChatConversation(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    onFileuploadMedia: (uploadMedia, CommentMediaUpload, FAQType) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post('thread_message/media_upload', uploadMedia)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            if (FAQType === 'question' || FAQType === 'chat')
                                CommentMediaUpload.thread_question_image_url = res?.data?.data
                            else
                                CommentMediaUpload.thread_image_url = res?.data?.data
                            // console.log("updated CommentMediaUpload--", CommentMediaUpload);
                            dispatch(TableServices.updateConversations(CommentMediaUpload, "faq"))
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    getRatingCriteriaByTrade: (cardType, trello_id) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    // .get(`ratingCriteriaByTrade/get-rating-criterias-by-trade?${trade = 'Mechanical'}`)
                    .get(`ratingCriteriaByTrade/get-rating-criterias-by-trade/${cardType}/${trello_id}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setTradeCriteria(res?.data?.data))
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    userRatingByProjectTrade: (ratingPayload) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                console.log("ratingPayload", ratingPayload);
                axios
                    .post('user/rating', ratingPayload)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(clearRatingDetail());
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    // Comment on FrontApp Table
    userCommentInFrontApp: (updateCommentPayload) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post('metadata/updateComment', updateCommentPayload)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(clearCommentDetail());
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    userFolderInput: (updatedfolder) => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post('metadata/addFolderNumber', updatedfolder)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(clearFolder());
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    //DTPM checkbox status
    updateDTPMCheckboxStatus: (updateDTPMCheckboxPayload, paginationOptions, filtersToApply) => {
        // const queryString = new URLSearchParams(filtersToApply).toString();
        // console.log("filtersToApply", filtersToApply);
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .post('metadata/updateDTPM', updateDTPMCheckboxPayload)
                    .then(res => {
                        if (res.status === 200) {
                            // console.log("helllo");
                            dispatch(fetchSuccess());
                            // dispatch(setCheckboxDetail(res?.data));
                            dispatch(TableServices.getfrontTableData(paginationOptions, filtersToApply));
                            // console.log("filter--->>>", filtersToApply);
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    //Review Table Api
    getReviewTableData: ({ limit, currentPage, orderByColumn, columnOrderType, ...filtersToApply }) => {
        const queryString = new URLSearchParams(filtersToApply).toString();
        // console.log("queryString >>> ", queryString);
        // console.log("filtersToApply >>> ", filtersToApply);
        return dispatch => {
            try {
                dispatch(fetchStart());
                dispatch(setReviewTableData({}));
                axios
                    .get('user/report-history?' + `limit=${limit}&page=${currentPage}&orderBy=${orderByColumn}&orderType=${columnOrderType}${queryString ? ("&" + queryString) : ""}`)
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            dispatch(setReviewTableData(res?.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status == "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    //Front App Table Api
    getfrontTableData: ({ noRefresh, limit, currentPage, orderByColumn, ...filtersToApply }) => {
        const queryString = new URLSearchParams(filtersToApply).toString();
        // console.log("queryString >>> ", queryString);
        // console.log("filtersToApply >>> ", filtersToApply);
        return dispatch => {
            try {
                dispatch(fetchStart());
                // dispatch(setFrontTableData({}));
                if (!noRefresh)
                    dispatch(setTableData({}));
                axios
                    .get('metadata/frontapp?' + `page=${currentPage}&limit=${limit}${queryString ? ("&" + queryString) : ""}`)
                    .then(res => {
                        if (res.status === 200) {
                            // console.log("getfrontTableData---", res);
                            dispatch(fetchSuccess());
                            dispatch(setFrontTableData(res.data));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status == "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    // SumofTotalHours Api
    getTotalHoursOfSum: ({ currentView, queryString }) => {

        // console.log("currentView----", currentView);
        // const queryString = new URLSearchParams(filtersToApply).toString();
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('trello_cards/getTotalHours?' + `view=${currentView}${queryString ? ("&" + queryString) : ""}`)
                    .then(res => {
                        if (res.status === 200) {
                            // console.log("getTotalHours---", res.data.data);
                            dispatch(fetchSuccess());
                            dispatch(setTotalHourSum(res.data.data[0]));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                        if (error?.response?.data?.status == "403") {
                            // console.log("error 403 occured");
                            localStorage.removeItem("userInfo");
                            alert(error?.response?.data?.message);
                            window.location = './signin';
                        }
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
    // Worker & Manager dropdown api
    getWorkerManager: () => {
        return dispatch => {
            try {
                dispatch(fetchStart());
                axios
                    .get('trello_fields/powerup_get_MemberAndWorker')
                    .then(res => {
                        if (res.status === 200) {
                            dispatch(fetchSuccess());
                            // console.log('managerData: res data', res?.data?.Data);
                            dispatch(setWorkerManager(res?.data?.Data?.map((val) => {
                                return {
                                    fullName: val.member.fullName,
                                    id: val.idMember
                                }
                            })));
                            // dispatch(setWorkerManagersData(res?.data?.data?.map((val) => {
                            //     return {
                            //         value: val.trello_id,
                            //         label: val.fullName,
                            //     }
                            // }
                            // )));
                        } else {
                            dispatch(fetchError(res.data.error));
                        }
                    })
                    .catch(function (error) {
                        dispatch(fetchError(error.m));
                    });
            } catch (error) {
                dispatch(fetchError(error.message));
            }
        };
    },
};

export default TableServices;
