import React from 'react';
import { Grid, TextareaAutosize, Typography } from "@material-ui/core";
import StarBorderIcon from '@material-ui/icons/StarBorder';
import { Rating } from "@material-ui/lab";

const RatingComponent = ({ criteriaData, onChange, index }) => {
    const setValue = (key, newValue) => {
        // if (key === 'rating') {
        onChange({
            ...criteriaData,
            ...{
                [key]: newValue
            }
        })
        // }
        // if (key === 'comment') {
        //     onChange({
        //         ...criteriaData,
        //         ...{
        //             [key]: newValue
        //         }
        //     })
        // }
        // onChange(key, criteriaDataName, newValue);
    }
    return (
        <>

            <Grid key={index} style={{ marginBottom: '15px' }} container>
                <Grid item xs={12} sm={4} md={4} style={{ paddingRight: 16, margin: 'auto' }}>
                    <Typography className="label">{criteriaData.name} :</Typography>
                    {/* <Typography style={{ fontSize: '15px' }} component="legend">{criteriaData.name} :</Typography> */}
                </Grid>
                <Grid item xs={12} sm={8} md={8}>
                    <Rating
                        emptyIcon={<StarBorderIcon fontSize="inherit" />}
                        name={criteriaData.name}
                        value={criteriaData.rating || null}
                        onChange={(event, newValue) => {
                            setValue('rating', newValue);
                        }}
                    />
                    <TextareaAutosize
                        style={{ width: "-webkit-fill-available", padding: '5px', marginTop: '5px', fontFamily: 'mulish', borderRadius: '5px' }}
                        maxRows={5}
                        aria-label="maximum height"
                        placeholder="Enter Comments here."
                        value={criteriaData.comment}
                        onChange={(event) => {
                            setValue('comment', event.target.value);
                        }}
                    />
                </Grid>
            </Grid>

        </>
    )
}

export default RatingComponent;