import { STORE_TABLE_DATA,STORE_DESCRIPTION, STORE_ATTACHMENT, CLEAR_FOLDER, STORE_MANAGERS_DATA,STORE_WORKER_MANAGER,STORE_ALL_CUSTOM_FEILDS,  STORE_TRADE_CRITERIA,STORE_DROPDOWN, STORE_TITLE,STORE_FOLDERS_DATA, STORE_COMMENTS,STORE_PROJECT_TYPE_DATA, STORE_ALL_PROJECT_LABEL, STORE_ALL_STATUS,STORE_LIST_SPENT_TIME_SUMMARY, SET_CHECKBOX,SET_NEWCHECKBOX, SET_DELIVERYNOTE,STORE_CHILD_CARD_SPENT_DETAILS, CLEAR_SPENT_TIME_DATA, STORE_ALL_WORKCARD_LABEL, STORE_TRELLO_USERS_DATA, STORE_UPDATED_CUSTOM_ORDER,STORE_UPDATE_DESC, STORE_UPDATE_CUSTOM_FIELDS,STORE_UPDATED_CARD_DETAIL, STORE_UPDATED_CHAT_CONVERSATION, STORE_UPDATED_FAQ_DATA, STORE_UPDATED_ALL_CHECK, UPDATE_TABLE_ROW, UPDATE_TABLE_DATA, CLEAR_CONVERSATION_DATA, CLEAR_RATING_DATA, CLEAR_COMMENT_DATA,SET_CHECKBOX_DATA, UPDATE_THREAD_NEW_NOTIFICATION, UPDATE_FAQ_NEW_NOTIFICATION, UPDATE_THREAD_READ_NOTIFICATION, UPDATE_FAQ_READ_NOTIFICATION, STORE_REVIEW_TABLE_DATA , STORE_FRONT_TABLE_DATA, STORE_TOTAL_HOUR_SUM, } from "@jumbo/constants/ActionTypes";

export const setTableData = data => {
    return dispatch => {
      dispatch({
        type: STORE_TABLE_DATA,
        payload: data
      });
    };
  };
export const setFoldersData = data => {
    return dispatch => {
      dispatch({
        type: STORE_FOLDERS_DATA,
        payload: data
      });
    };
  };
export const setProjectTypeData = data => {
    return dispatch => {
      dispatch({
        type: STORE_PROJECT_TYPE_DATA,
        payload: data
      });
    };
  };
  export const setDescription = data => {
    return dispatch => {
      dispatch({
        type: STORE_DESCRIPTION,
        payload: data
      })
    }
  }
export const setAllprojectLabel = data => {
    return dispatch => {
      dispatch({
        type: STORE_ALL_PROJECT_LABEL,
        payload: data
      });
    };
  };
  export const setAttachment = data => {
    return dispatch => {
      dispatch({
        type: STORE_ATTACHMENT,
        payload: data
      });
    };
  }
export const setAllStatus = data => {
  return dispatch => {
    dispatch({
      type: STORE_ALL_STATUS,
      payload: data
    });
  }
}
export const setComments = data => {
  return dispatch => {
    dispatch({
      type: STORE_COMMENTS,
      payload: data
    })
  }
}
export const setListSpentTimeSummary = data => {
    return dispatch => {
      dispatch({
        type: STORE_LIST_SPENT_TIME_SUMMARY,
        payload: data
      });
    };
  };
  export const setCheckboxData = data => {
    return dispatch => {
      dispatch({
        type: SET_CHECKBOX,
        payload: data
      })
    }
  }
  export const setNewCheckboxData = data => {
    return dispatch => {
      dispatch({
        type:SET_NEWCHECKBOX,
        payload: data
      })
    }
  }
  export const setDeliveryNote = data => {
    return dispatch => {
      dispatch({
        type:SET_DELIVERYNOTE,
        payload:data
      })
    }
  }
export const setChildCardSpentDetails = data => {
    return dispatch => {
      dispatch({
        type: STORE_CHILD_CARD_SPENT_DETAILS,
        payload: data
      });
    };
  };
export const setAllworkCardLabel = data => {
    return dispatch => {
      dispatch({
        type: STORE_ALL_WORKCARD_LABEL,
        payload: data
      });
    };
  };
  export const setCustomFeilds = data => {
    return dispatch => {
      dispatch({
        type: STORE_ALL_CUSTOM_FEILDS,
        payload: data
      });
    };
  };
export const setManagersData = data => {
    return dispatch => {
      dispatch({
        type: STORE_MANAGERS_DATA,
        payload: data
      });
    };
  };
  export const setWorkerManager = data => {
    return dispatch => {
      dispatch({
        type: STORE_WORKER_MANAGER,
        payload: data
      });
    };
  };
export const setTradeCriteria = data => {
    return dispatch => {
      dispatch({
        type: STORE_TRADE_CRITERIA,
        payload: data
      });
    };
  };
export const setTrelloUsersData = data => {
    return dispatch => {
      dispatch({
        type: STORE_TRELLO_USERS_DATA,
        payload: data
      });
    };
  };
export const setUpdatedCustomOrder = data => {
    return dispatch => {
      dispatch({
        type: STORE_UPDATED_CUSTOM_ORDER,
        payload: data
      });
    };
  };
export const setUpdatedCardDetails = data => {
    return dispatch => {
      dispatch({
        type: STORE_UPDATED_CARD_DETAIL,
        payload: data
      });
    };
  };
  export const setupdateCustomFields = data => {
    return dispatch => {
      dispatch({
        type: STORE_UPDATE_CUSTOM_FIELDS,
        payload: data
      })
    }
  }
export const setupdateCardDescription = data => {
  return dispatch => {
    dispatch({
      type: STORE_UPDATE_DESC,
      payload: data
    })
  }
}
export const setupdateCardTitle = data => {
  return dispatch => {
    dispatch({
      type: STORE_TITLE,
      payload: data
    })
  }
}
export const setupdateDropdown = data => {
  return dispatch => {
    dispatch({
      type: STORE_DROPDOWN,
      payload: data
    })
  }
}
export const setUpdatedChatConversation = data => {
    return dispatch => {
      dispatch({
        type: STORE_UPDATED_CHAT_CONVERSATION,
        payload: data
      });
    };
  };
export const setUpdatedFAQData = data => {
    return dispatch => {
      dispatch({
        type: STORE_UPDATED_FAQ_DATA,
        payload: data
      });
    };
  };
export const setUpdatedAllCheck = data => {
    return dispatch => {
      dispatch({
        type: STORE_UPDATED_ALL_CHECK,
        payload: data
      });
    };
  };
  export const UpdateTableRow = rowData => {
    return dispatch => {
      dispatch({
        type: UPDATE_TABLE_ROW,
        payload: rowData
      });
    };
  };
  export const UpdateThreadNewNotification = rowData => {
    return dispatch => {
      dispatch({
        type: UPDATE_THREAD_NEW_NOTIFICATION,
        payload: rowData
      });
    };
  };
  export const UpdateFAQNewNotification = rowData => {
    return dispatch => {
      dispatch({
        type: UPDATE_FAQ_NEW_NOTIFICATION,
        payload: rowData
      });
    };
  };
  export const UpdateThreadReadNotification = rowData => {
    return dispatch => {
      dispatch({
        type: UPDATE_THREAD_READ_NOTIFICATION,
        payload: rowData
      });
    };
  };
  export const UpdateFAQReadNotification = rowData => {
    return dispatch => {
      dispatch({
        type: UPDATE_FAQ_READ_NOTIFICATION,
        payload: rowData
      });
    };
  };
  export const UpdateTableData = rowData => {
    return dispatch => {
      dispatch({
        type: UPDATE_TABLE_DATA,
        payload: rowData
      });
    };
  };
  export const clearConversationData = data => {
    return dispatch => {
        dispatch({
            type: CLEAR_CONVERSATION_DATA,
        });
    };
  };
  export const clearRatingDetail = data => {
    return dispatch => {
        dispatch({
            type: CLEAR_RATING_DATA,
        });
    };
  };
  export const clearCommentDetail = data => {
    return dispatch => {
        dispatch({
            type: CLEAR_COMMENT_DATA,
        });
    };
  };
  export const clearFolder = data => {
    return dispatch => {
      dispatch({
        type: CLEAR_FOLDER,
      })
    }
  }
  export const setCheckboxDetail = data => {
    return dispatch => {
        dispatch({
            type: SET_CHECKBOX_DATA,
        });
    };
  };
  export const clearSpentTimeData = data => {
    return dispatch => {
        dispatch({
            type: CLEAR_SPENT_TIME_DATA,
        });
    };
};
export const setReviewTableData = data => {
  return dispatch => {
    dispatch({
      type: STORE_REVIEW_TABLE_DATA,
      payload: data
    });
  };
};
export const setFrontTableData = data => {
  return dispatch => {
    dispatch({
      type: STORE_FRONT_TABLE_DATA,
      payload: data
    });
  };
};
export const setTotalHourSum = data => {
  return dispatch => {
    dispatch({
      type: STORE_TOTAL_HOUR_SUM,
      payload: data
    });
  };
};