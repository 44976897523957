import { Collapse, List, ListItem, ListItemText } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Popover from "@material-ui/core/Popover";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import QuestionMarkIcon from "@material-ui/icons/HelpOutline";
import HistoryIcon from "@material-ui/icons/History";
import { styled, withStyles } from "@material-ui/styles";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearSpentTimeData,
  setUpdatedChatConversation,
  setUpdatedFAQData,
} from "redux/actions/Table.action";
import TableServices from "services/table";
import { AppTheme } from "theme/customeTheme";
import PageLoader from "../PageComponents/PageLoader";
import CommentsDialogBox from "./Comments/CommentsDialogBox";

const StyledMenu = styled((props) => <Popover elevation={0} {...props} />)(
  ({ }) => ({
    "& .MuiPaper-root": {
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25);",
      maxHeight: "600px",
    },
  })
);
const CustomMenuItem = withStyles({
  root: {
    fontFamily: '"Poppins", sans-serif',
    color: "#4E4E4E",
    fontSize: 16,
    padding: "10px 15px",
    borderRadius: 16,
    margin: 16,
    letterSpacing: 0.2,
    minHeight: "auto",
    fontWeight: 500,
    border: "4px solid #96C0FF",
    fontStyle: "italic",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
})(MenuItem);
const CommentCustomMenuItem = withStyles({
  root: {
    fontFamily: '"Mulish", sans-serif',
    color: "#000000",
    fontSize: 16,
    padding: "10px 15px",
    // borderRadius: 5,
    // marginBottom: 4,
    letterSpacing: 0.2,
    minHeight: "auto",
    fontWeight: 600,
    "&:hover": {
      backgroundColor: AppTheme.colors.primary,
      color: "#FFFFFF",
      // borderRadius: 5,
    },
  },
})(MenuItem);
const CommentPopover = ({ projectInfo }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const [isOpenCollapse, setIsOpenCollapse] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  // const [findCheckboxFlag, setFindCheckboxFlag] = React.useState(true);
  const [threadName, setThreadName] = React.useState("");
  const [commentSection, setcommentSection] = React.useState(null);
  const { listSpentTimeSummary, childCardSpentDetails } = useSelector(
    ({ table }) => table
  );
  const { loading } = useSelector(({ common }) => common);

  const handleOpenDialog = (chatInfo) => {
    setOpenDialog(true);
    setcommentSection(chatInfo);
    setThreadName(chatInfo.thread_name);
  };
  const handleCloseDialog = () => {
    setUpdatedChatConversation({});
    setUpdatedFAQData({});
    setOpenDialog(false);
  };
  const getCardTimeByList = (cardSortId) => {
    dispatch(TableServices.getListSpentTimeSummary(cardSortId));
  };

  const handleClickTime = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleExpandable = (clickedIndex) => {
    if (isOpenCollapse === clickedIndex) {
      setIsOpenCollapse(null);
    } else {
      setIsOpenCollapse(clickedIndex);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAnchorEl2(null);
    dispatch(clearSpentTimeData());
  };
  const getGradientColor = () => {
    let color1 = "white";
    let color2 = "white";
    let color3 = "white";
    let color4 = "white";
    let color5 = "white";
    // const { threads } = projectInfo || {};
    for (let i = 0; i < projectInfo.threads.length; i++) {
      if (projectInfo.threads[i].thread_name === "All") {
        if (projectInfo.threads[i].is_all_checked === false)
          color1 = "#ff8484";
      }
      if (projectInfo.threads[i].thread_name === "PM") {
        if (projectInfo.threads[i].is_all_checked === false)
          color2 = "#cd86f9";
      }
      if (projectInfo.threads[i].thread_name === "Mechanical") {
        if (projectInfo.threads[i].is_all_checked === false)
          color3 = "#90EE90";
      }
      if (projectInfo.threads[i].thread_name === "Electrical") {
        if (projectInfo.threads[i].is_all_checked === false)
          color4 = "#f6d26f";
      }
      if (projectInfo.threads[i].thread_name === "Plumbing") {
        if (projectInfo.threads[i].is_all_checked === false)
          color5 = "#87CEEB";
      }
    }

    return {
      background: `linear-gradient(to right,  ${color1} 0%, ${color1} 20%, ${color2} 20%, ${color2} 40%, ${color3} 40%, ${color3} 60%, ${color4} 60%, ${color4} 80% ,${color5} 80%, ${color5} 100%)`,
    };
  };
 
  //   useEffect(() => {
  //     let temp = projectInfo?.threads?.every((v) => v.is_all_checked === true);
  //     setFindCheckboxFlag(temp);
  //   }, [projectInfo, projectInfo?.threads]);
  return (
    // <div style={findCheckboxFlag ? {} : { background: '#f8adb9', paddingTop: '5px', paddingBottom: '5px' }}></div>
    <div
      style={getGradientColor()}
    >
      <span onClick={handleClickTime}>
        <HistoryIcon
          style={{ fontSize: "1.9rem", cursor: "pointer" }}
          onClick={() => getCardTimeByList(projectInfo?.shortLink)}
        />
      </span>
      <img
        src="/images/comments.svg"
        alt="comments"
        onClick={handleClick}
        width="50px"
        className="pointer"
      />
      <div>
        {/* For CHAT New Message NOtification */}
        {projectInfo?.threads?.is_all_checked ===
          true && (
            <FiberManualRecordIcon
              titleAccess="Mechanical"
              style={{ color: "green", width: "0.5em", height: "0.5em" }}
            />
          )}
        {projectInfo?.userNotificationsStatuses?.[0]?.ElecNotification ===
          true && (
            <FiberManualRecordIcon
              titleAccess="Electrical"
              style={{ color: "orange", width: "0.5em", height: "0.5em" }}
            />
          )}
        {projectInfo?.userNotificationsStatuses?.[0]?.PlumNotification ===
          true && (
            <FiberManualRecordIcon
              titleAccess="Plumbing"
              style={{ color: "blue", width: "0.5em", height: "0.5em" }}
            />
          )}
        {projectInfo?.userNotificationsStatuses?.[0]?.PMNotification ===
          true && (
            <FiberManualRecordIcon
              titleAccess="PM"
              style={{ color: "#cd86f9", width: "0.5em", height: "0.5em" }}
            />
          )}
        {projectInfo?.userNotificationsStatuses?.[0]?.AllNotification ===
          true && (
            <FiberManualRecordIcon
              titleAccess="All"
              style={{ color: "red", width: "0.5em", height: "0.5em" }}
            />
          )}
        {/* For FAQ New Message NOtification */}
        {projectInfo?.userNotificationsStatuses?.[0]?.MechFAQNotification ===
          true && (
            <QuestionMarkIcon
              titleAccess="Mechanical"
              style={{ color: "green", width: "0.5em", height: "0.5em" }}
            />
          )}
        {projectInfo?.userNotificationsStatuses?.[0]?.ElecFAQNotification ===
          true && (
            <QuestionMarkIcon
              titleAccess="Electrical"
              style={{ color: "orange", width: "0.5em", height: "0.5em" }}
            />
          )}
        {projectInfo?.userNotificationsStatuses?.[0]?.PlumFAQNotification ===
          true && (
            <QuestionMarkIcon
              titleAccess="Plumbing"
              style={{ color: "blue", width: "0.5em", height: "0.5em" }}
            />
          )}
        {projectInfo?.userNotificationsStatuses?.[0]?.PMFAQNotification ===
          true && (
            <QuestionMarkIcon
              titleAccess="PM"
              style={{ color: "#cd86f9", width: "0.5em", height: "0.5em" }}
            />
          )}
        {projectInfo?.userNotificationsStatuses?.[0]?.AllFAQNotification ===
          true && (
            <QuestionMarkIcon
              titleAccess="All"
              style={{ color: "red", width: "0.5em", height: "0.5em" }}
            />
          )}
      </div>
      {/* <IconButton aria-label="arrow-dropdown" className="arrow-dropdown-icon" size="small" onClick={handleClick}>
                <ArrowDropDown />
            </IconButton> */}
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl2}
        keepMounted
        open={Boolean(anchorEl2)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="hours-spent-popover">
          <h3>List Time</h3>
          {listSpentTimeSummary.length <= 0 ? (
            <CustomMenuItem style={{ border: "4px solid #aaaa" }}>
              <>{loading === false ? "No Data" : <PageLoader />}</>
            </CustomMenuItem>
          ) : (
            <>
              {listSpentTimeSummary.map((item, i) => {
                return (
                  <CustomMenuItem style={{ border: "4px solid #aaaa" }} key={i}>
                    <>
                      {item.name} : {item.duration}
                    </>
                  </CustomMenuItem>
                );
              })}
            </>
          )}
          <div className="hours-spent-popover">
            <h3>Child Card Time</h3>

            {childCardSpentDetails?.Mechanical && (
              <>
                <CustomMenuItem
                  style={{ border: "4px solid #d6f3d9" }}
                  key={"1"}
                  onClick={() => handleExpandable("1")}
                >
                  <>
                    Mechanical :{" "}
                    {childCardSpentDetails?.Mechanical?.totalSpentInInProgress}
                    {isOpenCollapse === "1" ? <ExpandMore /> : <ExpandLess />}
                  </>
                </CustomMenuItem>
                <Collapse
                  in={isOpenCollapse === "1"}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {childCardSpentDetails?.Mechanical?.actions?.map(
                      (item, index) => {
                        return (
                          <>
                            <ListItem
                              style={{ paddingLeft: "30px" }}
                              button
                              key={index}
                            >
                              <ListItemText>
                                {item.name} : {item.duration}
                              </ListItemText>
                              {/* <ListItemText primary="HEllloo" /> */}
                            </ListItem>
                          </>
                        );
                      }
                    )}
                  </List>
                </Collapse>
              </>
            )}
            {childCardSpentDetails?.Electrical && (
              <>
                <CustomMenuItem
                  style={{ border: "4px solid #f8f0da" }}
                  key={"2"}
                  onClick={() => handleExpandable("2")}
                >
                  <>
                    Electrical :{" "}
                    {childCardSpentDetails?.Electrical?.totalSpentInInProgress}
                    {isOpenCollapse === "2" ? <ExpandMore /> : <ExpandLess />}
                  </>
                </CustomMenuItem>
                <Collapse
                  in={isOpenCollapse === "2"}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {/* {each.subMenu.map(subData => (<ListItem key={subData.id} button><ListItemText inset primary={subData.name} /></ListItem>))} */}
                    {childCardSpentDetails?.Electrical?.actions?.map(
                      (item, index) => {
                        return (
                          <>
                            <ListItem
                              style={{ paddingLeft: "30px" }}
                              button
                              key={index}
                            >
                              <ListItemText>
                                {item.name} : {item.duration}
                              </ListItemText>
                              {/* <ListItemText primary="HEllloo" /> */}
                            </ListItem>
                          </>
                        );
                      }
                    )}
                  </List>
                </Collapse>
              </>
            )}
            {childCardSpentDetails?.Plumbing && (
              <>
                <CustomMenuItem
                  style={{ border: "4px solid #d5ebf2" }}
                  key={"3"}
                  onClick={() => handleExpandable("3")}
                >
                  <>
                    Plumbing :{" "}
                    {childCardSpentDetails?.Plumbing?.totalSpentInInProgress}
                    {isOpenCollapse === "3" ? <ExpandMore /> : <ExpandLess />}
                  </>
                </CustomMenuItem>
                <Collapse
                  in={isOpenCollapse === "3"}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {/* {each.subMenu.map(subData => (
                                                        <ListItem key={subData.id} button>
                                                            <ListItemText inset primary={subData.name} />
                                                        </ListItem>
                                                    ))} */}
                    {childCardSpentDetails?.Plumbing?.actions?.map(
                      (item, index) => {
                        return (
                          <>
                            <ListItem
                              style={{ paddingLeft: "30px" }}
                              button
                              key={index}
                            >
                              <ListItemText>
                                {item.name} : {item.duration}
                              </ListItemText>
                              {/* <ListItemText primary="HEllloo" /> */}
                            </ListItem>
                          </>
                        );
                      }
                    )}
                  </List>
                </Collapse>
              </>
            )}
          </div>
        </div>
      </StyledMenu>
      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="actions-menu p-2">
          {projectInfo?.threads?.map((item, i) => {
            return (
              <CommentCustomMenuItem
                onClick={() => handleOpenDialog(item)}
                key={"CustomMenuItem" + i}
                style={
                  item.is_all_checked === false
                    ? { backgroundColor: "#f98888" }
                    : {}
                }
              >
                {item.thread_name}
              </CommentCustomMenuItem>
            );
          })}
        </div>
      </StyledMenu>
      <CommentsDialogBox
        threadName={threadName}
        commentSection={commentSection}
        projectInfo={projectInfo}
        openDialog={openDialog}
        handleOpenDialog={handleOpenDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </div>
  );
};
export default CommentPopover;
