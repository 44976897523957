import React from 'react';

const CriteriaRating = ({ criteriDetail }) => {
    return (
        <>
            {criteriDetail.map((criteria) => {
                return (
                    <div>
                         {criteria.rating}/{criteria?.name}
                    </div>
                )
            })
            }
        </>
    )
}

export default CriteriaRating;