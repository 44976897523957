import ChatFooter from '@jumbo/components/TableComponents/Comments/ChatFooter';
import { Box, Button, Grid, makeStyles, Tab, Tabs, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import React, { useEffect } from 'react';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import TableServices from 'services/table';
import { AppTheme } from 'theme/customeTheme';
import QAFooter from '../Questions/QAFooter';
import QASection from '../Questions/QASection';
import DialogTitleCustom from '../TableEditPopup/DialogTitleCustom';
import Conversation from "./Conversation";
import Sidebar from "./Sidebar";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTypography-root": {
      fontFamily: '"Poppins", sans-serif',
      color: "#000000"
    },
  },
}));
const styles = {
  TabBar: {
    border: '1px solid #006D8F',
    borderRadius: '5px',
  },
};
const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
}))(MuiDialogContent);


export default function CommentsDialogBox({ commentSection, threadName, projectInfo, label, openDialog, handleOpenDialog, handleCloseDialog }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [value, setValue] = React.useState('chat');
  const [openQ, setOpenQ] = React.useState(false);
  // console.log("commentSection--", commentSection);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const openQuestionBox = () => {
    setOpenQ(!openQ);
  };
  useEffect(
    () => {
      //After reading Chat or FAQ.
      if (openDialog == true) {
        const readThreadData = {
          projectId: projectInfo.trello_id,
          thread_name: threadName,
          type: value
        }
        dispatch(TableServices.readThreadNotification(readThreadData));
      }
    },
    [openDialog, value]
  );
  return (
    <Box>
      {openDialog && <Dialog
        ref={ref}
        onClose={handleCloseDialog}
        aria-labelledby="customized-dialog-title"
        open={openDialog || false}
        maxWidth="lg"
        className={classes.root}
        TransitionComponent={Transition}
      >
        {/* <DialogTitleCustom id="alert-dialog-slide-title" className="dialog-title" onClose={handleCloseDialog}>Comments</DialogTitleCustom> */}

        <DialogTitle id="customized-dialog-title" className="dialog-title">
          Comments
        </DialogTitle>
        <DialogContent dividers className="comments-box">
          <Grid container>
            <Grid item xs={12} sm={9} md={9} style={{ paddingRight: 16, borderRight: "2px solid rgba(0, 109, 143, 0.5)" }}>
              <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <TabList
                    TabIndicatorProps={{
                      style: {
                        display: "none",
                      },
                    }}
                    variant="fullWidth" centered className='tab-list' style={styles.TabBar} onChange={handleChange} aria-label="lab API tabs example">
                    <Tab label="General" value="chat" className='tab-heading' style={value === "chat" ? { backgroundColor: AppTheme.colors.primary, color: AppTheme.colors.white } : { color: AppTheme.colors.primary }} />
                    <Tab label="Questions" value="faq" className='tab-heading' style={value === "faq" ? { backgroundColor: AppTheme.colors.primary, color: AppTheme.colors.white } : { color: AppTheme.colors.primary }} />
                  </TabList>
                </Box>
                <TabPanel value="chat">
                  <Conversation TitleInfo={commentSection} />
                  <ChatFooter cardType={commentSection} />
                </TabPanel>
                <TabPanel value="faq">
                  <Box textAlign={"right"} mb={"42px"}>
                    <Button variant="contained" onClick={openQuestionBox} className="sidebar-button" style={{ backgroundColor: AppTheme.colors.primary }}>
                      Ask
                    </Button>
                  </Box>
                  <QASection projectId={projectInfo?._id} TitleInfo={commentSection} openQuestionBox={openQuestionBox} />
                  {
                    openQ === true ? (
                      <QAFooter cardType={commentSection} openQuestionBox={openQuestionBox} />
                    ) : (
                      <div style={{ visibility: 'hidden' }}>
                        <QAFooter cardType={commentSection} openQuestionBox={openQuestionBox} />
                      </div>
                    )}
                </TabPanel>
              </TabContext>
            </Grid>
            <Grid item xs={12} sm={3} md={3}>
              <Sidebar sideBarTitleInfo={commentSection} sideBarInfo={projectInfo} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>}
    </Box>
  );
}
