export const SHOW_MESSAGE = "show_message";
export const HIDE_MESSAGE = "hide_message";
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";

export const UPDATE_AUTH_USER = "update_auth_user";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const VERIFY_TRELLO_USER = "verify_trello_user";
export const TRELLO_USER_DETAILS = "trello_user_details";
export const UPDATE_LOAD_USER = "update_load_user";
export const SEND_FORGET_PASSWORD_EMAIL = "send_forget_password_email";
export const SIGNIN_GOOGLE_USER_SUCCESS = "signin_google_user_success";
export const SIGNIN_FACEBOOK_USER_SUCCESS = "signin_facebook_user_success";
export const SIGNIN_TWITTER_USER_SUCCESS = "signin_twitter_user_success";
export const SIGNIN_GITHUB_USER_SUCCESS = "signin_github_user_SUCCESS";
export const SIGNIN_USER_SUCCESS = "signin_user_success";
export const SIGNOUT_USER_SUCCESS = "signout_user_success";

export const SET_DASHBOARD_DATA = "set_dashboard_data";

export const SET_TASK_CURRENT_USER = "set_task_current_user";
export const SET_TASKS_DATA = "set_tasks_data";
export const SET_TASK_LIST_DATA = "set_task_list_data";
export const ADD_TASK = "add_task";
export const DELETE_TASK = "delete_task";
export const UPDATE_TASK = "update_task";
export const SET_FILTER_DATA = "set_filter_data";
export const ADD_TASK_LIST = "add_task_list";
export const UPDATE_TASK_LIST = "update_task_list";
export const DELETE_TASK_LIST = "delete_task_list";
export const SET_TASK_DETAIL = "set_task_detail";
export const SEND_MESSAGE = "send_message";
export const TOGGLE_SIDEBAR_COLLAPSED = "toggle_sidebar_collapsed";
export const GET_TASKS_COUNTS = "get_tasks_counts";

//mail app
export const GET_LABELS_LIST = "get_labels_list";
export const GET_CONNECTIONS_LIST = "get_connections_list";
export const GET_MAILS_LIST = "get_mails_list";
export const UPDATE_MAIL_FOLDER = "update_mail_folder";
export const UPDATE_MAIL_LABEL = "upade_mail_label";
export const UPDATE_FAVORITE_STATUS = "update_favorite_status";
export const UPDATE_READ_STATUS = "update_read_status";
export const UPDATE_IMPORTANT_STATUS = "update_important_status";
export const COMPOSE_MAIL = "compose_mail";
export const SET_FILTER_TYPE = "set_filter_type";
export const GET_SELECTED_MAIL = "GET_SELECTED_MAIL";
export const UPDATE_SELECTED_MAIL = "update_selected_mail";
export const NULLIFY_SELECTED_MAIL = "nullify_selected_mail";
export const REPLY_TO_MAIL = "reply_to_mail";
export const GET_MAIL_COUNTS = "get_mail_count";
export const ADD_LABEL = "add_label";
export const ADD_CONNECTION = "add_connection";
export const REMOVE_CONNECTION = "remove_connection";

export const SET_CHAT_USERS = "set_chat_users";
export const SET_CONTACT_USERS = "set_contact_users";
export const SET_CURRENT_USER = "set_current_user";
export const SET_CONVERSATION_DATA = "set_conversation_data";
export const SEND_NEW_CHAT_MESSAGE = "send_new_chat_message";
export const SEND_NEW_MEDIA_MESSAGE = "send_new_media_message";

//Contact App
export const GET_CONTACTS_LIST = "get_contacts_list";
export const SET_CURRENT_CONTACT = "set_current_contact";
export const CREATE_CONTACT = "create_contact";
export const UPDATE_STARRED_STATUS = "update_starred_status";
export const DELETE_CONTACT = "delete_contact";
export const UPDATE_CONTACT_LABEL = "update_contact_label";
export const UPDATE_CONTACT = "update_contact";
export const GET_CONTACT_COUNTS = "get_contact_counts";
export const UPDATE_LABEL_ITEM = "update_label_item";
export const DELETE_LABEL_ITEM = "delete_label_item";

export const GET_USER_DETAIL = "get_user_detail";
export const GET_FEED_POSTS = "get_feed_posts";
export const CREATE_POST = "create_post";
export const UPDATE_POST = "update_post";

// Users Module
export const GET_USERS = "GET_USERS";
export const ADD_USER = "ADD_USER";
export const SET_USER_DETAILS = "SET_USER_DETAILS";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_BULK_USERS = "DELETE_BULK_USERS";

// dashboard Action
export const STORE_DASHBOARD_DATA = "STORE_DASHBOARD_DATA";
export const STORE_NOTIFICATION_DATA = "STORE_NOTIFICATION_DATA";
export const CLEAR_NOTIFICATION_DATA = "CLEAR_NOTIFICATION_DATA";
// table Action
export const STORE_TABLE_DATA = "STORE_TABLE_DATA";
export const STORE_REVIEW_TABLE_DATA = "STORE_REVIEW_TABLE_DATA";
export const STORE_FRONT_TABLE_DATA = "STORE_FRONT_TABLE_DATA";
export const STORE_MANAGERS_DATA = "STORE_MANAGERS_DATA";
export const STORE_DESCRIPTION = "STORE_DESCRIPTION";
export const STORE_WORKER_MANAGER = "STORE_WORKER_MANAGER";
export const STORE_TRADE_CRITERIA = "STORE_TRADE_CRITERIA";
export const STORE_FOLDERS_DATA = "STORE_FOLDERS_DATA";
export const STORE_ATTACHMENT = "STORE_ATTACHMENT";
export const STORE_PROJECT_TYPE_DATA = "STORE_PROJECT_TYPE_DATA";
export const STORE_ALL_PROJECT_LABEL = "STORE_ALL_PROJECT_LABEL";
export const STORE_COMMENTS = "STORE_COMMENTS";
export const STORE_ALL_STATUS = "STORE_ALL_STATUS";
export const STORE_LIST_SPENT_TIME_SUMMARY = "STORE_LIST_SPENT_TIME_SUMMARY";
export const SET_CHECKBOX = "SET_CHECKBOX";
export const SET_NEWCHECKBOX = "SET_NEWCHECKBOX";
export const SET_DELIVERYNOTE = "SET_DELIVERYNOTE";
export const STORE_CHILD_CARD_SPENT_DETAILS = "STORE_CHILD_CARD_SPENT_DETAILS";
export const STORE_TOTAL_HOUR_SUM = "STORE_TOTAL_HOUR_SUM";
export const STORE_ALL_WORKCARD_LABEL = "STORE_ALL_WORKCARD_LABEL";
export const STORE_ALL_CUSTOM_FEILDS ="STORE_ALL_CUSTOM_FEILDS";
export const STORE_TRELLO_USERS_DATA = "STORE_TRELLO_USERS_DATA";
export const STORE_UPDATED_CUSTOM_ORDER = "STORE_UPDATED_CUSTOM_ORDER";
export const STORE_UPDATED_CARD_DETAIL = "STORE_UPDATED_CARD_DETAIL";
export const STORE_UPDATE_CUSTOM_FIELDS = "STORE_UPDATE_CUSTOM_FIELDS";
export const STORE_UPDATE_DESC = "STORE_UPDATE_DESC";
export const STORE_TITLE = "STORE_TITLE";
export const STORE_DROPDOWN = "STORE_DROPDOWN";
export const STORE_UPDATED_CHAT_CONVERSATION = "STORE_UPDATED_CHAT_CONVERSATION";
export const STORE_UPDATED_FAQ_DATA = "STORE_UPDATED_FAQ_DATA";
export const STORE_UPDATED_ALL_CHECK = "STORE_UPDATED_ALL_CHECK";
export const UPDATE_TABLE_ROW = "UPDATE_TABLE_ROW";
export const UPDATE_THREAD_NEW_NOTIFICATION = "UPDATE_THREAD_NEW_NOTIFICATION";
export const UPDATE_FAQ_NEW_NOTIFICATION = "UPDATE_FAQ_NEW_NOTIFICATION";
export const UPDATE_THREAD_READ_NOTIFICATION = "UPDATE_THREAD_READ_NOTIFICATION";
export const UPDATE_FAQ_READ_NOTIFICATION = "UPDATE_FAQ_READ_NOTIFICATION";
export const UPDATE_TABLE_DATA = "UPDATE_TABLE_DATA";
export const CLEAR_CONVERSATION_DATA = "CLEAR_CONVERSATION_DATA";
export const CLEAR_RATING_DATA = "CLEAR_RATING_DATA";
export const CLEAR_COMMENT_DATA = "CLEAR_COMMENT_DATA";
export const CLEAR_FOLDER = "CLEAR_FOLDER";
export const SET_CHECKBOX_DATA = "SET_CHECKBOX_DATA";
export const CLEAR_SPENT_TIME_DATA = "CLEAR_SPENT_TIME_DATA";