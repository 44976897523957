import { Dialog, DialogContent, Grid } from '@material-ui/core';
// import { LocalOfferOutlined } from '@material-ui/icons';
import React from 'react';
import CustomFeildPopup from './CustomFeildPopup';
import { useDispatch, useSelector } from 'react-redux';
import TableServices from 'services/table';
import DialogTitleCustom from '../TableEditPopup/DialogTitleCustom';
// const useStyles = makeStyles((theme) => ({
//     root: {
//         "& .MuiDialogContent-root ": {
//             padding: 0
//         },
//         "&.MuiDialog-paperWidthSm": {
//             maxWidth: '800px'
//         }
//     },

// }));
const CustomFeilds = ({ trelloCardId, editData }) => {
    // console.log("editData",editData);
    // const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const { allCustomFeilds } = useSelector(({ table }) => table);
    const { setDescription } = useSelector(({ table }) => table);
    const { getAttachment } = useSelector(({ table }) => table);
    const { allComments } = useSelector(({ table }) => table);
    const dispatch = useDispatch()
    const handleClickOpen = () => {
        dispatch(
            TableServices.getCustomFields({ trelloCardId })
        );
        dispatch(
            TableServices.getComments({ trelloCardId })
        )
        dispatch(
            TableServices.getDescription({ trelloCardId })
        )
        dispatch(
            TableServices.getAttachment({ trelloCardId })
        )
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <svg xmlns="http://www.w3.org/2000/svg" className="pointer trelloicon" style={{width:'13%', }} alt="CustomFeilds" onClick={handleClickOpen} viewBox="0 0 128 128" id="trello"><path fill="#23719F" d="M127 16.142c0-8.363-6.779-15.142-15.142-15.142h-95.716c-8.363 0-15.142 6.779-15.142 15.142v95.715c0 8.364 6.779 15.143 15.142 15.143h95.716c8.363 0 15.142-6.779 15.142-15.143v-95.715zm-69 83.398c0 3.807-3.286 6.46-7.093 6.46h-27.344c-3.807 0-6.563-2.653-6.563-6.46v-76.31c0-3.808 2.756-7.23 6.563-7.23h27.344c3.807 0 7.093 3.422 7.093 7.23v76.31zm55-66.456v31.886000000000003c0 3.807-2.936 7.03-6.744 7.03h-27.33c-3.808 0-6.926-3.224-6.926-7.03v-42.104c0-3.808 3.118-6.866 6.926-6.866h27.33c3.808 0 6.744 3.058 6.744 6.866v10.218z"></path></svg>
            {/* <LocalOfferOutlined className="pointer" alt="CustomFeilds" onClick={handleClickOpen} /> */}
            {open && <Dialog
                fullWidth={true}
                scroll="paper"
                open={open}
                keepMounted
                maxWidth="md"
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                // className={classes.root}
                >
                <DialogTitleCustom id="alert-dialog-slide-title" onClose={handleClose}>{editData}</DialogTitleCustom>
                <DialogContent className="px-0 py-3 dialog-content">
                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12} sm={12} md={12} style={{ padding: "16px" }}>
                                <CustomFeildPopup allCustomFeilds={allCustomFeilds} trelloCardId={trelloCardId} editData={editData} allComments={allComments} setDescription={setDescription} getAttachment={getAttachment} />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </DialogContent>
            </Dialog>}
        </>
    )
}

export default CustomFeilds;