import React, { useState } from 'react';
import ChecklistItem from './DeliveryCheckListItem';
import ChecklistCheckbox from './DeliveryCheckListCheckbox';
import DeliveryChecklistCheckbox from './DeliveryCheckListCheckbox';
import DeliveryChecklistItem from './DeliveryCheckListItem';

const DeliveryChecklist = ({ data, cardId, typeOfCard, childCardId }) => {
    const [filterType, setFilterType] = useState('All');

    const handleFilterChange = (newFilter) => {
        setFilterType(newFilter);
    };



    return (
        <div>
            <div className="comments-message-card" style={{textAlign: 'center'}}>
                <div style={{ marginTop: '20px', textAlign: 'center',marginBottom: '10px', display: 'flex', gap: '10px' }}>
                    <span
                        onClick={() => handleFilterChange('Checked')}
                        className={filterType === 'Checked' ? 'active-tab' : 'filter-tab'}
                    >
                        Checked
                    </span>
                    <span
                        onClick={() => handleFilterChange('Unchecked')}
                        className={filterType === 'Unchecked' ? 'active-tab' : 'filter-tab'}
                    >
                        Unchecked
                    </span>
                    <span
                        onClick={() => handleFilterChange('All')}
                        className={filterType === 'All' ? 'active-tab' : 'filter-tab'}
                    >
                        All
                    </span>
                </div>
                <div style={{ display: 'grid', minWidth: '500px' }}>
                    {data.map((dataEntry, dataIndex) => (
                        <div key={dataIndex}>
                            {dataEntry.checkItems.map((item, index) => {
                                {/* {console.log("checkItems",item)} */}
                                const isCompleted = item.state === "complete";
                                {/* console.log('Filter:', filterType);
                                console.log('Item:', item.name, 'Completed:', item.state); */}

                                // Check if the filter type matches the item's completion status
                                if (
                                    (filterType === 'Checked' && isCompleted) ||
                                    (filterType === 'Unchecked' && !isCompleted) ||
                                    filterType === 'All'
                                ) {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                cursor: 'pointer',
                                                marginTop: '10px',
                                            }}
                                        >
                                            <DeliveryChecklistCheckbox
                                                item={item}
                                                data={data}
                                                cardId={cardId}
                                                typeOfCard={typeOfCard}
                                                childCardId={childCardId}
                                            />
                                            <DeliveryChecklistItem
                                                item={item}
                                                itemName={item.name}
                                                data={data}
                                                cardId={cardId}
                                                typeOfCard={typeOfCard}
                                                childCardId={childCardId}
                                            />
                                        </div>
                                    );
                                }
                                return null;
                            })}
                        </div>
                    ))}
                </div>

            </div>
        </div>
    );
};

export default DeliveryChecklist;
