import { InputBase, MenuItem, Select } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';
import React from 'react';
const BootstrapInput = withStyles((theme) => ({
    input: {
        borderRadius: 4,
        border: '1px solid #ced4da',
        padding: '10px 26px 10px 12px',
    },
}))(InputBase);
const TablePagination = ({ classes, offset, pageIndex, pageSize, TotalLength, handleChange, onNumberPagesChanged }) => {
    return (
        <div className={classes.Pagination}>
            <div className="mb-2 mb-md-0">
                <span className="mr-2 pagination-summary">Displaying Result {offset + 1} - {(TotalLength < (offset + pageSize))? TotalLength : (offset + pageSize)} of {TotalLength}</span>
                <span className="mr-2">Rows per page:</span>
                <Select
                    variant="outlined"
                    value={pageSize}
                    onChange={e => {
                        onNumberPagesChanged(e.target.value)
                    }}
                    inputProps={{ 'aria-label': 'Without label' }}
                    input={<BootstrapInput />}
                    className="mr-4"
                >
                    {[15, 25, 50, 100].map((item, index) => {
                        return (
                            <MenuItem key={index} value={item}>{item}</MenuItem>
                        )
                    })}
                </Select>
            </div>
            <Pagination
                page={pageIndex + 1}
                count={Math.ceil(TotalLength / pageSize)}
                variant="outlined"
                shape="rounded"
                onChange={handleChange}
                siblingCount={0}
                boundaryCount={2}
                showFirstButton
                showLastButton
            />
        </div>
    )
}
export default TablePagination