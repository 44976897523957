import React, { useEffect } from 'react';
import { Button, FormHelperText, Grid, makeStyles, MenuItem, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AppTheme } from 'theme/customeTheme';
import * as Yup from 'yup';
import RatingComponent from './RatingComponent';
import Sidebar from './SideBar';
import TableServices from 'services/table';
import Select, { components } from "react-select";
import historyIcon from "../../../../img/Icon.webp";
import historyIconExpand from "../../../../img/Icon (2).webp";
import MyReviewReactTable from 'routes/Pages/MyReviewReactTable';

const useStyles = makeStyles(theme => ({
    editPopupRoot: {
        '& .MuiOutlinedInput-multiline': {
            padding: "0px"
        },
    }
}));
const RatingForm = ({ projectInfo, workerName, cardType, handleClose }) => {
    const [ratingError, setRatingError] = React.useState(false);
    const [showHistory, setShowHistory] = React.useState(false);
    const dispatch = useDispatch();
    const classes = useStyles();
    const { tradeCriteria, projectTypeData } = useSelector(({ table }) => table);
    useEffect(() => {
        dispatch(TableServices.getProjectTypeData())
    }, [])
    const onHistoryButtonClick = () => {
        setShowHistory(!showHistory);
    }
   
    return (
        <div className="custom-scrollbar">
            <Formik
                enableReinitialize
                initialValues={{
                    projectPhase: '',
                    tradeCriteriaArray: tradeCriteria.criteria
                }}
                validationSchema={Yup.object().shape({
                    projectPhase: Yup.object().required('Project Phase is required'),
                })}
                onSubmit={(values) => {
                    const userData = JSON.parse(localStorage.getItem("userInfo"));
                    let checkKeyPresenceInArray = key => values.tradeCriteriaArray.some(obj => Object.keys(obj).includes(key));
                    var isKeyPresent = checkKeyPresenceInArray('rating')
                    // if isKeyPresent is true then ratingError false
                    // if isKeyPresent is false then ratingError true
                    setRatingError(!isKeyPresent);
                    if (isKeyPresent === true) {
                        const ratingPayload = {
                            worker_name: workerName.fullName,
                            worker_name_ref: workerName.trello_id,
                            rating_given_by: userData.userName,
                            rating_given_by_ref: userData.trello_id,
                            project_name: projectInfo.card_title.name,
                            project_name_ref: projectInfo.trello_id,
                            trade_name: tradeCriteria.trade_name,
                            trade_name_ref: tradeCriteria.trade_id,
                            projectPhase: values.projectPhase,
                            criteria: values.tradeCriteriaArray
                        }
                        console.log("ratingPayload:", ratingPayload);
                        dispatch(TableServices.userRatingByProjectTrade(ratingPayload));
                        handleClose();
                    }

                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    // isSubmitting,
                    touched,
                    values
                }) => {
                    // console.log('current val',values?.PrelimDue ? new Date(values?.PrelimDue) : null);
                    const handleChangeRating = (key, value) => {
                        setRatingError(false)
                        switch (key) {
                            case 'tradeCriteriaArray':
                                setFieldValue('tradeCriteriaArray', value)
                                break;
                            default:
                                setFieldValue([key], value.value, true)
                                break;
                        }
                    }
                    return (
                        <Grid container>
                            <Grid item xs={12} sm={9} md={9} style={{ paddingRight: 16, borderRight: "2px solid rgba(0, 109, 143, 0.5)" }}>
                                <Grid className="rating-fileds" container>
                                    <Grid item xs={12} sm={4} md={4}>
                                        <Typography className="label">Project Phase :</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={8} md={8}>
                                        <Select
                                            // isMulti
                                            value={values?.projectPhase || []}
                                            options={projectTypeData}
                                            placeholder="Project Phase.."
                                            maxMenuHeight={200}
                                            onChange={(value) => {
                                                console.log("handleChange-check::", value);
                                                setFieldValue('projectPhase', value, true);
                                                // setFilter(xx);
                                            }}
                                            className="dropdown"
                                            error={Boolean(touched.projectPhase && errors.projectPhase)}
                                        // components={{ DropdownIndicator }}
                                        />
                                        {
                                            (touched.projectPhase && errors.projectPhase) &&
                                            <FormHelperText style={{ color: "#f44336" }}>{errors.projectPhase}</FormHelperText>
                                        }
                                    </Grid>
                                </Grid>
                                {values.tradeCriteriaArray?.map((criteria, i) => {
                                    return (
                                        <RatingComponent
                                            key={i}
                                            criteriaData={criteria}
                                            onChange={(value) => {
                                                values.tradeCriteriaArray[i] = value
                                                handleChangeRating('tradeCriteriaArray', values.tradeCriteriaArray)
                                            }}
                                        />
                                    )
                                })}
                                {ratingError === true && (
                                    <small style={{ color: "#f44336", fontSize: "0.85rem" }}>At leaset one rating required.</small>
                                )}
                                <Grid item xs={12} sm={12} md={12} style={{ textAlign: "end", marginTop: "30px" }}>
                                    <Button onClick={handleSubmit} variant="contained" className="sidebar-button" style={{ backgroundColor: AppTheme.colors.primary }}>
                                        Save changes
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3}>
                                <Sidebar workerName={workerName} sideBarTitleInfo={cardType} sideBarInfo={projectInfo} />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography  className="sidebar-button" style={{ backgroundColor: AppTheme.colors.white, color: AppTheme.colors.primary, border: '1px solid #006D8F', padding: '5px' }}>
                                    History
                                    {
                                        showHistory !== true &&
                                        <img className="pointer label-img" onClick={onHistoryButtonClick} src={historyIcon} style={{ height: '10px', paddingLeft: '5px' }} />
                                    }
                                    {
                                        showHistory === true &&
                                        <>
                                            <img className="pointer label-img" onClick={onHistoryButtonClick} src={historyIconExpand} style={{ height: '10px', paddingLeft: '5px' }} />
                                        </>
                                    }
                                </Typography>
                                {showHistory === true &&
                                    <Grid style={{ marginTop: '20px' }} item xs={12} sm={12} md={12}>
                                        <MyReviewReactTable tradeInfo={tradeCriteria} projectInfo={projectInfo} />
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    );
                }}
            </Formik>
        </div >
    )
}
export default RatingForm;