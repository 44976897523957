import React from "react";
import {Dashboard,Toc,PostAdd} from "@material-ui/icons";
import IntlMessages from "../../../utils/IntlMessages";

export const sidebarNavs = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "section",
    children: [
      {
        name: <IntlMessages id={"sidebar.dashboard"} />,
        type: "item",
        icon: <Dashboard />,
        link: "/dashboard"
      },
      {
        name: <IntlMessages id={"sidebar.report"} />,
        type: "item",
        icon: <Toc/>,
        link: "/table"
      },
      {
        name: <IntlMessages id={"sidebar.myReview"} />,
        type: "item",
        icon: <Toc/>,
        link: "/my-review"
      },
      {
        name: <IntlMessages id={"sidebar.frontApp"} />,
        type: "item",
        icon: <Toc/>,
        link: "/front-app"
      }
    ]
  }
];

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "collapse",
    children: [
      {
        name: <IntlMessages id={"pages.samplePage"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page"
      }
    ]
  }
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={"sidebar.main"} />,
    type: "collapse",
    children: [
      {
        name: <IntlMessages id={"pages.samplePage"} />,
        type: "item",
        icon: <PostAdd />,
        link: "/sample-page"
      }
    ]
  }
];
